import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
// import DialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';

// import validator from "validator";
// import emailjs from "emailjs-com";

import OButton from '../../components/OButton';

import './index.css';

import logo from '../../assets/img/logo.png';

export default class FormModal extends React.Component {

    state = {
        name: "",
        companyName: "",
        email: "",
        phone: "",
        errorField: null,
        success: true,
        formType: null
    }

    componentDidMount() {
        // emailjs.init("user_OojfIyuK5coJpces7ZM1v")
    }

    componentDidUpdate(nextProps) {
        if (nextProps.formType !== this.state.formType) {
            this.setState({
                formType: nextProps.formType,
                success: true
            });
        }
    }

    // handleChange = field => event => {
    //     this.setState({ [field]: event.target.value });
    // };

    // onSubmit = () => {
    //     if (this.state.name === '') {
    //         this.setState({ errorField: 'name' });
    //     } else if (this.state.companyName === '') {
    //         this.setState({ errorField: 'companyName' });
    //     } else if (!validator.isEmail(this.state.email)) {
    //         this.setState({ errorField: 'email' });
    //     } else if (!validator.isMobilePhone(this.state.phone, undefined, { strictMode: true })) {
    //         this.setState({ errorField: 'phone' });
    //     } else {
    //         this.setState({ success: true })

    //     }
    // };

    closeModal = () => {
        this.setState({
            name: "",
            companyName: "",
            email: "",
            phone: "",
            errorField: null,
            success: true,
        }, () => {
            this.props.closeFormModal();
        });
    }

    render() {
        return (
            <Dialog open={this.props.isFormModalOpen} onClose={this.closeModal}>
                <div className={"closeButtonContainer"} >
                    <IconButton onClick={this.closeModal}>
                        <Icon>
                            <CloseIcon />
                        </Icon>
                    </IconButton>
                </div>

                {
                    this.state.success ?
                        <Grid container className={"successContainer"}>
                            <Grid item xs={12}>
                                <img src={logo} alt="octomate" className={"formDialogLogo"} />
                            </Grid>
                            <Grid item xs={12} className={"successDialogText"}>
                                <div className={"successDialogText1"}>
                                    Thank you! <br />You are now subscribed to our latest news.
                                </div>
                                {/* <div className={"successDialogText2"}>
                                    Thank you! You are now subscribed to our latest news.
                                </div> */}
                            </Grid>
                            <div className={"submitButtonContainer"}>
                                <OButton onClick={this.closeModal} variant="outlined" color="secondary">
                                    Go Back To Homepage
                                </OButton>
                            </div>
                        </Grid>
                        : ""
                }
            </Dialog>
        );
    }
}