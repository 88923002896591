import React from 'react';
import './index.css'
import 'react-quill/dist/quill.snow.css';
import TypeArticle from '../../assets/img/type-article.png'
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { firebaseAuth, firestore, fireCount, firebaseStorage } from '../../auth/firebase';
import OButton from '../../components/OButton';
import { FaPlus } from "react-icons/fa";
import TextField from '@material-ui/core/TextField';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import SimpleDialog from "./components/change-icon-dialog"
import CollectionsArticle from './components/collectionsArticle';
import ComboBox from './components/searchBox';
import Pagination from '@material-ui/lab/Pagination';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import {
    Link as LinkRouter,
    Switch
} from 'react-router-dom';



const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%'
        },
    },
}));




export default class HelpCenter extends React.Component {
    categoryRef = firestore.collection(`category`);
    counterCategoriesRef = firestore.collection(`counters`)
    unsubscribe;
    category = {
        title: "",
        createdTime: "",
        createdBy: "",
        description: "",
        icon: ""
    }

    state = {
        user: null,
        createMode: false,
        mode: '',
        detailedMode: false,
        errorMessage: null,
        categories: [],
        open: false,
        vertical: 'top',
        horizontal: 'center',
        avatar: "",
        isUploading: false,
        progress: 0,
        avatarURL: "",
        username: "",
        path: "",
        url: "",
        sortArt: null,
        loading: true,
        src: null,
        crop: {
            unit: '%',
            width: 30,
            aspect: 1,
        },
        dialogOpen: false,
        page: 1,
        total: 0
    };

    imageRef;
    setEditorRef = (editor) => this.editor = editor

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe()
    }

    componentDidMount() {
        firebaseAuth.onAuthStateChanged(user => {
            this.setState({ user: user })
        })
        this.counterCategoriesRef.doc("categories").get().then(doc => {
            var total = doc.data().count || 0
            if (total > 0) {
                total = Math.ceil((total / 5))
            }
            this.setState({ total: total, totalDoc: doc.data().count || 0 })
        })
        this.unsubscribe = this.categoryRef.orderBy("createdTime", "desc").limit(5).onSnapshot(snapshot => {
            const categories = [];
            this.setState({ lastVisible: snapshot.docs[snapshot.docs.length - 1], firstVisible: snapshot.docs[0] })
            snapshot.forEach((doc) => {
                const { title, createdTime, createdBy, description, icon } = doc.data();
                categories.push({
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                });
                this.setState({ tmpCategories: categories, categories: categories, loading: false });
            });
        })
        document.documentElement.scrollTop = 0;
    }

    changeCreateMode = res => {
        const { createMode } = this.state
        if (res.desc === 'edit') {
            this.setState({ mode: res.desc, createMode: !createMode, avatar: "", avatarURL: "" })
            this.category = res.category
        } else {
            this.category = {
                title: "",
                createdTime: "",
                createdBy: "",
                description: "",
                icon: ""
            }
            this.setState({ mode: '', createMode: !createMode, avatar: "", avatarURL: "" })
        }
    }

    errMatcher = () => {
        if (this.category.title === "") {
            return this.setState({ errorMessage: "Please fill the title" })
        } else if (this.category.description === "") {
            return this.setState({ errorMessage: "Please fill the description" })
        } else {
            return this.setState({ errorMessage: null })
        }
    }

    createMarkup(art) {
        return { __html: art };
    }

    handleClick = res => {
        this.setState({ open: true, message: res })
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false, dialogOpen: false })
    };

    onCardClick = (res) => {
        this.setState({ article: res ? res : null })
    }

    // Upload
    handleChangeUsername = event => {
        this.setState({ username: event.target.value })
    };
    handleUploadStart = e => {
        const file = e.currentTarget.files[0]
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, avatar: file.name, dialogOpen: true })
            });
            reader.readAsDataURL(file);
        }
    };

    saveCrop = () => {
        if (this.editor) {
            this.editor.getImageScaledToCanvas().toBlob((blob) => {
                const imageKey = moment().unix()
                const uploadTask = firebaseStorage.ref(`/images/${imageKey}`).put(blob)
                uploadTask.on('state_changed',
                    (snapShot) => {

                    }, (err) => {
                        console.log(err)
                    }, () => {
                        firebaseStorage.ref('images').child(imageKey.toString()).getDownloadURL()
                            .then(url => {
                                this.setState({ avatarURL: url, dialogOpen: false })
                            })
                    })
            }, "image/png")
        }
    }

    onSearch = (event) => {
        this.setState({ sortArt: event ? event : null })
    }

    setCategory = (res) => {
        if (res) {
            this.setState({ categories: [] })

        }
    }

    pageChange = (event, value) => {
        if (value !== null && value !== undefined) {
            if (value > this.state.page) {
                const multiplier = value - this.state.page
                for (let i = 0; i < multiplier; i++) {
                    setTimeout(() => {
                        this.unsubscribe = this.getCategoriesByLastVisible(value, i, multiplier)
                    }, i * 500);
                }
            } else if (value < this.state.page) {
                const multiplier = this.state.page - value
                for (let i = 0; i < multiplier; i++) {
                    setTimeout(() => {
                        this.unsubscribe = this.getCategoriesByFirstVisible(value, i, multiplier)
                    }, i * 500);
                }
            }
        } else {
            if (event > this.state.page) {
                const multiplier = event - this.state.page
                for (let i = 0; i < multiplier; i++) {
                    setTimeout(() => {
                        this.unsubscribe = this.getCategoriesByLastVisible(event, i, multiplier)
                    }, i * 500);
                }
            } else if (event < this.state.page) {
                const multiplier = this.state.page - event
                for (let i = 0; i < multiplier; i++) {
                    setTimeout(() => {
                        this.unsubscribe = this.getCategoriesByFirstVisible(event, i, multiplier)
                    }, i * 500);
                }
            }
        }
        this.forceUpdate()
    }

    updateCounters = (res) => {
        var total = 0
        if (res === 'add') {
            this.setState({ totalDoc: (this.state.totalDoc + 1) })
            total = this.state.totalDoc
            total += 1
        }
        else {
            this.setState({ totalDoc: (this.state.totalDoc - 1) })
            total = this.state.totalDoc
            total -= 1
        }
        if (total > 0) {
            total = Math.ceil((total / 5))
        }
        this.setState({ total: total })
    }

    getCategoriesByFirstVisible(value, i, multiplier) {
        return this.categoryRef.orderBy("createdTime", "desc").endBefore(this.state.firstVisible).limitToLast(5).onSnapshot(snapshot => {
            this.setState({ lastVisible: snapshot.docs[snapshot.docs.length - 1], page: value, firstVisible: snapshot.docs[0] });
            const categories = [];
            snapshot.forEach(doc => {
                const { title, createdTime, createdBy, description, icon } = doc.data();
                categories.push({
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                });
                if (i === (multiplier - 1)) {
                    this.setState({ categories: categories, loading: false });
                }
            });
        });
    }

    getCategoriesByLastVisible(value, i, multiplier) {
        return this.categoryRef.orderBy("createdTime", "desc").startAfter(this.state.lastVisible).limit(5).onSnapshot(snapshot => {
            this.setState({ lastVisible: snapshot.docs[snapshot.docs.length - 1], page: value, firstVisible: snapshot.docs[0] });
            const categories = [];
            snapshot.forEach(doc => {
                const { title, createdTime, createdBy, description, icon } = doc.data();
                categories.push({
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                });
                if (i === (multiplier - 1)) {
                    this.setState({ categories: categories, loading: false });
                }
            });
        });
    }

    render() {

        return (
            <Grid container className={'help-center'}>
                <Helmet>
                    <title>Octomate - Help Center</title>
                    <meta name="description" content="Advice and answers from Octomate Q&A Team" />
                </Helmet>
                <Grid item className={'help-text'}>
                    <h2>Help Center</h2>
                    <ComboBox onSearch={this.onSearch} articles={this.state.categories} />
                </Grid>

                <Switch>
                    <Route exact path="/HelpCenter">
                        {
                            !this.state.createMode ?
                                <Grid item className={'help-content'}>
                                    {this.state.user ? <AddMore onClick={this.changeCreateMode} /> : null}
                                    {
                                        this.state.categories.length > 0 ?
                                            this.state.sortArt ?
                                                this.state.categories.filter(x => x === this.state.sortArt).map(category =>
                                                    <div key={category.key} className={'card'} onClick={(() => this.onCardClick(category))} >
                                                        <LinkRouter to={`/HelpCenter/${category.key}:${category.title.replace(/\s+/g, '-')}`} className={'card-avatar'} >
                                                            <img src={category.icon === "" ? TypeArticle : category.icon} width="100" alt="Article Icon Images" />
                                                        </LinkRouter>

                                                        <LinkRouter to={`/HelpCenter/${category.key}:${category.title.replace(/\s+/g, '-')}`} className={'card-content'}>
                                                            <h4>{category.title}</h4>
                                                            <p>{category.description}</p>
                                                            <br />
                                                            <div className={'card-footer'}>
                                                                <span className={'small-footer'}>Written by {category.createdBy}</span>
                                                                <span className={'small-footer'}>Created on {moment(category.createdTime).format('DD MMMM YYYY, hh:mmA')}</span>
                                                            </div>
                                                        </LinkRouter>

                                                        {this.state.user ? <EditButton totalPage={this.state.totalPage} categories={this.state.categories} snackbarOpen={this.handleClick} article={category} /> : null}
                                                    </div >
                                                )
                                                : this.state.categories.map(category =>
                                                    <div key={category.key} className={'card'} onClick={(() => this.onCardClick(category))} >
                                                        <LinkRouter to={`/HelpCenter/${category.key}:${category.title.replace(/\s+/g, '-')}`} className={'card-avatar'} >
                                                            <img src={category.icon === "" ? TypeArticle : category.icon} width="100" alt="Article Icon Images" />
                                                        </LinkRouter>

                                                        <LinkRouter to={`/HelpCenter/${category.key}:${category.title.replace(/\s+/g, '-')}`} className={'card-content'}>
                                                            <h4>{category.title}</h4>
                                                            <p>{category.description}</p>
                                                            <br />
                                                            <div className={'card-footer'}>
                                                                <span className={'small-footer'}>Written by {category.createdBy}</span>
                                                                <span className={'small-footer'}>Created on {moment(category.createdTime).format('DD MMMM YYYY, hh:mmA')}</span>
                                                            </div>
                                                        </LinkRouter>

                                                        {this.state.user ?
                                                            <EditButton
                                                                totalPage={this.state.page}
                                                                onChangePage={this.pageChange}
                                                                updateCounters={this.updateCounters}
                                                                changeCreateMode={this.changeCreateMode}
                                                                setCategory={this.setCategory}
                                                                snackbarOpen={this.handleClick}
                                                                categories={this.state.categories}
                                                                category={category} /> : null}
                                                    </div >
                                                )
                                            : (this.state.loading ? null : <div style={{ paddingTop: 30 }}><h3 style={{ color: 'gray' }}>No post at the moment, please check back again later, thank you! </h3></div>)
                                    }
                                    {this.state.total > 0 ? <Pagination count={this.state.total} page={this.state.page} onChange={this.pageChange} /> : null}
                                </Grid>
                                :

                                <CardCreation
                                    uploadState={this.state}
                                    user={this.state.user}
                                    errorMessage={this.state.errorMessage}
                                    errMatcher={this.errMatcher}
                                    categories={this.category}
                                    onCancel={this.changeCreateMode}
                                    snackbarOpen={this.handleClick}
                                    handleChangeUsername={this.handleChangeUsername}
                                    handleUploadStart={this.handleUploadStart}
                                    updateCounters={this.updateCounters}
                                />
                        }
                        <Grid className={'help-footer'}>
                            <h2>Can't find what you're looking for?</h2>
                            <Link to={"/"}>
                                <OButton
                                    variant="outlined"
                                    color="inherit"
                                >
                                    <span style={{ color: 'white' }}>Contact Us</span>
                                </OButton>
                            </Link>
                            <div className={'help-copyright'}>
                                <div className="copyright">© Octomate 2020. All rights reserved.</div>
                            </div>

                        </Grid>
                        <SimpleDialog
                            uploadState={this.state}
                            onSaveCrop={this.saveCrop}
                            open={this.state.dialogOpen}
                            onClose={this.handleClose}
                            setEditorRef={this.setEditorRef} />
                    </Route>
                    <Route path={`/HelpCenter/:collectionId`} render={props => <CollectionsArticle {...props} />} />
                </Switch>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    message={this.state.message}
                />
            </Grid>
        );
    }
}



export function EditButton(props) {
    const categoriesRef = firestore.collection(`category`);
    
    const onDelete = () => {
        const key = props.category.key
        const categoryRef = categoriesRef.doc(`/${key}`)
        const articlesRef = firestore.collection(`article`).where("categorizeKey", "==", key);

        var deleteAll = false
        if (props.categories.length === 1) {
            if (props.totalPage === 1) {
                deleteAll = true
            }
            if (props.totalPage > 1)
                props.onChangePage((props.totalPage - 1))
        }
        categoryRef.delete().then(async res => {
            const statsDoc = firestore.doc("counters/categories");
            const countDoc = {};
            countDoc["count"] = fireCount.increment(-1);
            await statsDoc.set(countDoc, { merge: true });
            props.updateCounters("delete")
            if (deleteAll) {
                props.setCategory(deleteAll)
            }
            articlesRef.get().then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    doc.ref.delete();
                });
            });
            props.snackbarOpen("Category successfully deleted! ...")
        })
            .catch(err => {
                if (err) {
                    props.snackbarOpen(err)
                    console.log(err)
                }
            })
    }

    const onEdit = () => {
        props.changeCreateMode({ category: props.category, desc: "edit" })
    }

    return (
        <div className={'icon-container'}>
            <IconButton onClick={onEdit} color="primary" aria-label="edit">
                <CreateIcon />
            </IconButton>
            <IconButton onClick={onDelete} color="secondary" aria-label="delete">
                <DeleteIcon />
            </IconButton>
        </div>
    )
}

export function AddMore(props) {
    const onAddmore = () => {
        props.onClick({ desc: "add" })
    }
    return (
        <div>
            <OButton
                onClick={onAddmore}
                variant="contained"
                color="secondary"
                startIcon={<FaPlus />}
            >  Add Categories
                            </OButton>
        </div>
    )
}

export function CardCreation(props) {
    const categoriesRef = firestore.collection(`category`);
    const onSubmit = async () => {
        if (props.uploadState.mode === 'edit') {
            props.categories.createdTime = moment().valueOf()
            props.categories.icon = props.uploadState.avatarURL
            categoriesRef.doc(props.categories.key).update(props.categories)
                .then(res => {
                    props.snackbarOpen("Category Successfully Updated!....")
                    onCancel()
                })
                .catch(err => {
                    if (err) {
                        props.snackbarOpen(err)
                        console.log(err)
                    }
                });

        } else {
            props.errMatcher()

            props.categories.createdTime = moment().valueOf()
            props.categories.icon = props.uploadState.avatarURL
            if (props.categories.title !== "" && props.categories.description !== "") {
                categoriesRef.add(props.categories)
                    .then(async res => {
                        const statsDoc = firestore.doc(`counters/categories`);
                        const countDoc = {};
                        countDoc["count"] = fireCount.increment(1);
                        await statsDoc.set(countDoc, { merge: true });
                        props.updateCounters('add')
                        props.snackbarOpen("Category Successfully Created!....")
                        onCancel()
                    })
                    .catch(err => {
                        if (err) {
                            props.snackbarOpen(err)
                            console.log(err)

                        }
                    })
            }
        }
    }

    const onCancel = () => {
        props.onCancel({ desc: "cancel" })
    }

    const handleChange = res => {
        if (res.field === "description") {
            props.categories.description = res.value
        } else if (res.field === "Title") {
            props.categories.title = res.value
        } else if (res.field === "author") {
            props.categories.createdBy = res.value
        } else {
            props.categories.content = res.value
        }
    }

    const handleChangeUsername = res => {
        props.handleChangeUsername(res)
    }
    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }


    return (
        <Grid item className={"help-content"}>
            <div className={'login-content'}>
                <form className="form-create">
                    <Form

                        uploadState={props.uploadState}
                        error={props.errorMessage}
                        categories={props.categories}
                        onSubmit={onSubmit}
                        onHandle={handleChange}
                        handleChangeUsername={handleChangeUsername}
                        handleUploadStart={handleUploadStart}
                    />
                    <div className={"button-container"}>
                        <OButton className={'btn-cancel'} onClick={onCancel} variant="outlined" color="secondary">
                            Cancel
                    </OButton>
                        <OButton className={'btn-try'} onClick={onSubmit} variant="outlined" color="primary">
                            {props.uploadState.mode === 'edit' ? 'Update' : 'Create'}
                        </OButton>
                    </div>

                </form>
            </div>

        </Grid>
    )
}

export function Form(props) {
    const classes = useStyles();
    const onHandled = field => event => {
        if (field !== "content") {
            const value = event.currentTarget.value
            props.onHandle({ field, value })
        } else {
            props.categories.content = event
        }
    }

    const handleChangeUsername = res => {
        props.handleChangeUsername(res)
    }
    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    return (
        <div className={`${classes.root}`} >
            <h3 className={'register-company'}>{props.uploadState.mode === 'edit' ? 'Category' : 'Create Category'}</h3>
            <Upload
                uploadState={props.uploadState}
                handleChangeUsername={handleChangeUsername}
                handleUploadStart={handleUploadStart}
            />
            <TextField required id="outlined-basic-title" onChange={onHandled("Title")} defaultValue={props.categories.title} name="title" label="Title" type="text" variant="outlined" />
            <TextField required id="multiline-basic" onChange={onHandled("description")} defaultValue={props.categories.description} multiline rowsMax={4} name="description" label="Description" type="text" variant="outlined" />
            <TextField required id="outlined-basic-author" onChange={onHandled("author")} defaultValue={props.categories.createdBy} name="author" label="Author" type="text" variant="outlined" />
            <span className={'error-message'}>{props.error}</span>
        </div>
    )
}

export function Upload(props) {

    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {props.uploadState.isUploading && <p>Progress: {props.uploadState.progress}</p>}
            {props.uploadState.avatarURL && <img className={'img-preview'} alt="icon" src={props.uploadState.avatarURL} />}
            <br />
            <CustomUploadButton
                accept="image/*"
                onChange={handleUploadStart}
                className={'icon-select'}
            >
                Select your Icon
            </CustomUploadButton>
        </div >
    );

}

