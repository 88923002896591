import React from 'react';
import './contactUs.css'
import logo from '../../assets/img/logo-white.png';
import back from '../../assets/img/Back.svg';
import checkmark from '../../assets/img/checkmark.png';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';


export default class ContactSuccess extends React.Component {

    state = {
        success: true,
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <Grid container className={'privacy'}>
                <Helmet>
                    <title>Octomate - Contact Us</title>
                    <meta name="description" content="The following pages allow anyone to contact us by email." />
                </Helmet>
                <img src={logo} className={'privacy-logo'} alt="octomate" />
                <div className={`contact-success-container`}>
                    <img className={"checkmark-icon"} src={checkmark} alt="checkmark icon" />
                    <h3 className={"contact-success"}>Thank You For Your Submission!</h3>
                    <div className={"box-white-bg"}>
                        <span>We will get back to you as soon as we can.</span>
                    </div>
                </div>
                <div>
                    <a href="/">
                        <button className={'back-button'}>
                            <img src={back} className={'back-icon'} alt="back icon" />
                            Back To Website
                        </button>
                    </a>
                </div>
            </Grid>
        )
    }


}