import React from 'react'
import './scheduledemo.css'
import { Grid } from '@material-ui/core';
import demo from '../../assets/Picture1.png'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import validator from "validator";
import emailjs from "emailjs-com";
import { Helmet } from 'react-helmet';
import FooterSection from '../../sections/FooterSection';
import contactus from '../../services/http/contactus.service';
import checkmark from '../../assets/img/schedule-checkmark.png';
import * as moment from 'moment';
import { withTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            width: '100%',
            color: "#5cb8b2 !important"
        },

    },
    noMargin: {
        marginBottom: '0 !important'
    }
}));

class ScheduleDemo extends React.Component {
    state = {
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        remarks: "",
        errorField: null,
        success: false,
        formType: null
    }

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;
        emailjs.init("user_OojfIyuK5coJpces7ZM1v")
        let email = window.localStorage.getItem("email")
        let timer = window.localStorage.getItem("timer")
        if (timer) {
            let parsedTimer = JSON.parse(timer)
            let today = moment()
            let diff = today.diff(parsedTimer.time, 'seconds')
            if(diff >= 50){
                window.localStorage.removeItem("timer")
                this.setState({ success: false })
            }else{
                this.setState({ success: parsedTimer.success })
            }
        }
        if (email) {
            this.setState({ email: email })
        }
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }
    // componentDidUpdate(nextProps) {
    //     if (nextProps.formType !== this.state.formType) {
    //         this.setState({
    //             formType: nextProps.formType,
    //             success: false
    //         });
    //     }
    // }

    handleChange = field => event => {
        this.setState({ [field]: event.target.value });

    };

    onSubmit = (event) => {
        event.preventDefault();
        if (this.state.firstName === '') {
            this.setState({ errorField: 'firstName' });
        }
        else if (this.state.lastName === '') {
            this.setState({ errorField: 'lastName' });
        }
        else if (this.state.companyName === '') {
            this.setState({ errorField: 'companyName' });
        }
        else if (!validator.isEmail(this.state.email)) {
            this.setState({ errorField: 'email' });
        }
        else if (this.state.phoneNumber === '') {
            this.setState({ errorField: 'phoneNumber' });
        }
        else {
            const param = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                companyName: this.state.companyName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                remarks: this.state.remarks,
                isCompanyWebsite: true
            }
            contactus.post(param)
                .then(res => {
                    let time = moment()
                    window.localStorage.setItem("timer", JSON.stringify({ time: time, success: true }))
                    this.setState({ success: true })
                })
                .catch(err => {
                    this.setState({ success: false })
                })
        }
    };

    render() {
        const { t } = this.props;
        return (
            <Grid container className={'schedule-container'}>
                <Helmet>
                    <title>Octomate - {t('GENERAL.Schedule Demo')}</title>
                    <meta name="description" content={t("SCHEDULEDEMO_PAGETry Octomate For free, Schedule a demo now")} />
                </Helmet>
                {/* <div className={'bgwhite'}></div> */}
                <Grid item xs={12} sm={6} className={'demo-text'}>

                    <img className={'demo-image'} src={demo} alt="Scheduled Demo Images" />

                </Grid>
                <Grid item xs={12} sm={6} >

                    {
                        this.state.success ?
                            <div className={`contact-success-container`}>
                                <img className={"checkmark-icon"} src={checkmark} alt="checkmark icon" />
                                <h3 className={"contact-success font-base"}>{t(`SCHEDULEDEMO_PAGE.Thank You For Your Submission!`)}</h3>
                                <div className={"box-white-bg"}>
                                    <span className={"font-gray mb-10"}>{t(`SCHEDULEDEMO_PAGE.We will get back to you as soon as we can.`)}</span>
                                </div>
                            </div>
                            :
                            <form onSubmit={this.onSubmit} className="form-demo">
                                <Form t={t} error={this.state.errorField} email={this.state.email} onHandle={this.handleChange} />
                                <button className={'btn-try-demo'}>
                                    {t(`SCHEDULEDEMO_PAGE.Schedule Demo`)}
                                </button>
                            </form>
                    }

                </Grid>
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </Grid>
        )
    }
}


export function Form(props) {
    const classes = useStyles();

    return (
        <div className={`${classes.root} form-content`} >
            <h3 className={'register-company-demo'}>{props.t(`SCHEDULEDEMO_PAGE.Schedule a Live Demo Today`)}</h3>
            <div className={'column-2 d-flex justify-space-between'}>
                <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={props.error === 'firstName' ? true : false}
                    helperText={props.error === 'firstName' ? props.t('GENERAL.Please fill in your first name') : ''}
                    onChange={props.onHandle("firstName")}
                    id="outlined-basic"
                    label={props.t("GENERAL.First Name")}
                    variant="standard"
                    placeholder="John" />
                <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={props.error === 'lastName' ? true : false}
                    helperText={props.error === 'lastName' ? props.t('GENERAL.Please fill in your last name') : ''}
                    onChange={props.onHandle("lastName")}
                    id="outlined-basic"
                    label="Last Name"
                    variant="standard"
                    placeholder="Anderson" />
            </div>
            <TextField
                required
                InputLabelProps={{ shrink: true }}
                error={props.error === 'companyName' ? true : false}
                helperText={props.error === 'companyName' ? props.t('GENERAL.Please fill in your company name') : ''}
                onChange={props.onHandle("companyName")}
                id="outlined-basic"
                label={props.t("GENERAL.Company Name")}
                variant="standard"
                placeholder="Octomate Inc" />
            <TextField
                required
                InputLabelProps={{ shrink: true }}
                error={props.error === 'email' ? true : false}
                helperText={props.error === 'email' ? props.t('GENERAL.Please fill in your valid email') : ''}
                onChange={props.onHandle("email")}
                id="outlined-basic"
                label={props.t("GENERAL.Email")}
                value={props.email}
                variant="standard"
                placeholder="example@yahoo.com" />
            <TextField
                required
                type="number"
                InputLabelProps={{ shrink: true }}
                error={props.error === 'phoneNumber' ? true : false}
                helperText={props.error === 'phoneNumber' ? props.t('GENERAL.Please fill in your phone number.') : ''}
                onChange={props.onHandle("phoneNumber")}
                id="outlined-basic"
                label={props.t("GENERAL.Phone Number")}
                variant="standard"
                placeholder="+65 xxx xxxx xxxx" />
            <TextField
                multiline
                rows={4}
                className={classes.noMargin}
                InputLabelProps={{ shrink: true }}
                helperText={props.error === 'remarks' ? props.t('GENERAL.Please fill in your remarks') : ''}
                onChange={props.onHandle("remarks")}
                id="outlined-basic"
                label={props.t("GENERAL.Remarks")}
                variant="standard"
                placeholder={props.t("SCHEDULEDEMO_PAGE.Type your remarks here")} />
            {/* <TextField required error={props.error === 'companyName' ? true : false} helperText={props.error === 'companyName' ? 'Field is invalid' : ''} onChange={props.onHandle("companyName")} id="outlined-basic" label="Company Name" variant="outlined" />
            <TextField required error={props.error === 'email' ? true : false} helperText={props.error === 'email' ? 'Field is invalid' : ''} onChange={props.onHandle("email")} id="outlined-basic" label="Email Address" variant="outlined" />
            <TextField required error={props.error === 'phone' ? true : false} helperText={props.error === 'phone' ? 'Field is invalid' : ''} onChange={props.onHandle("phone")} id="outlined-basic" label="Phone Number (with country code, e.g. +65)" variant="outlined" /> */}
        </div>
    )

}

export default withTranslation()(ScheduleDemo)