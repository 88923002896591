import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import './index.css'
import image1 from '../../assets/img/transparency-shield.png'
import image2 from '../../assets/img/security-image-1.png'
import image3 from '../../assets/img/security-image-2.png'
import image4 from '../../assets/img/security-image-3.png'
import image5 from '../../assets/img/security-image-4.png'
import image6 from '../../assets/img/microsoftAzure2.png'
import arrowRight from "../../assets/img/arrow-right.png";
import FooterSection from '../../sections/FooterSection';
import { IoIosArrowForward } from "react-icons/io";
import { IoShieldHalfSharp } from "react-icons/io5";
import { IoSearchSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

class SecurityPrivacy extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container className={'securityContainer'}>
                <Helmet>
                    <title>Octomate - {t(`SECURITY_PAGE.SECURITYPRIVACY`)}</title>
                    <meta name="description" content={t("SECURITY_PAGE.DESCMETA")} />
                </Helmet>
                
                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent'>
                    <Grid item className='securityLeftContent' sm={8} xs={12}>
                        <h1 className='securityTitle'>Octomate Data Security and <br></br> Privacy</h1>
                        <h3 className='securitySubTitle'>
                           Discover how we protect our customers and<br></br>
                           communities from a range  of data security risks and threats
                        </h3>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <img className='security-main-image' src={image1} alt='main' />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2'>
                    <Grid item sm={8} xs={8}>
                        <h2 className='securityTitle2'>Security and privacy are at the heart of <br></br> everything we do</h2>
                        <p className='securitySubTitle2'>
                        We understand the importance of secure data and how critical it is to keep your data protected and private. With this in mind, Octomate maintains a secure framework – based on industry best practices – designed to protect the confidentiality, integrity and availability of your data. 
                        </p>
                    </Grid>
                </Grid>
                
                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2'>
                    <Grid item className='securityPadding' sm={8} xs={8}>
                        <h2 className='securityTitle3'>Security & risk management</h2>
                        <p className='securitySubTitle2'>
                            Here’s how we ensure end-to-end data security:
                        </p>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2'>
                    <Grid item className='securityPadding pr-0' sm={6} xs={12}>
                        <img className='security-image security-image-right' src={image2} alt='main' />
                    </Grid>
                    <Grid item className='securityPadding' sm={6} xs={12}>
                        <h2 className='securityTitle4  w-400'>Organizational security</h2>
                        <p className='securitySubTitle3 w-70 w-400'>
                        Our commitment to security and privacy is driven across the entire company and is reflected in our Information Security Policy, processes and procedures. All our employees receive security and privacy training and, where applicable, additional training specific to their roles. Access is strictly limited to only those who require it and is reviewed on a regular basis.
                        </p>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2'>
                    <Grid item className='securityPadding pr-0' sm={6} xs={12}>
                        <div className='float-right w-70' style={{float:'right'}}>
                            <h2 className='securityTitle4'>Cloud security</h2>
                            <ul className='pl-20 font-18'>
                                <li className='w-400 mb-1'>
                                    <b> Technical and network security </b> – to prevent data from being intercepted, all traffic is secured using industry standard protocols such as SSL/TLS and HTTPS. System security is based on logical authentication and authorization mechanisms, while stateful firewall technology ensures that only legitimate data enters the service environment.
                                </li>
                                <li className='w-400 mb-1'>
                                    <b> Data security </b> – customer data in transit and at rest is protected by encryption.
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item className='securityPadding pl-0 ' sm={6} xs={12}>
                        <img className='security-image' src={image3} alt='main' />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2'>
                    <Grid item className='securityPadding pr-0' sm={6} xs={12}>
                         <img className='security-image security-image-right' src={image4} alt='main' />
                    </Grid>
                    <Grid item className='securityPadding' sm={6} xs={12}>
                        <h2 className='securityTitle4'>Application security</h2>
                        <p className='securitySubTitle3 w-70'>
                        We maintain a regular patch review policy, where high risk vulnerabilities are prioritized for updates. We maintain a vulnerability assessment and conduct regular penetration tests to ensure that our application remains secure and safe for our customers.
                        </p>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2'>
                    <Grid item className='securityPadding pr-0' sm={6} xs={12}>
                        <div className='float-right w-70'>
                            <h2 className='securityTitle4'>Backup and disaster recovery</h2>
                            <p className='securitySubTitle3'>
                            In case of unforeseen service unavailability, we are able to easily recover services in our secondary data centers. We also maintain a Business Continuity Plan (BCP) and Disaster Recovery Plan (RCP) which is tested periodically to ensure that we can meet the SLAs promised to our customers.
                            </p>
                        </div>
                    </Grid>
                    <Grid item className='securityPadding pl-0 ' sm={6} xs={12}>
                        <img className='security-image' src={image5} alt='main' />
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2 pb-0'>
                    <Grid item className='securityPadding' sm={8} xs={12}>
                        <h2 className='securityTitle3'>Data centers</h2>
                        <p className='securitySubTitle2'>
                        Octomate uses only trusted and certified data centers,<br></br>
                        which implement a comprehensive set of <br></br>
                        environmental controls to ensure the physical security <br></br>
                        and high availability.
                        </p>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2 pt-0'>
                    <Grid item className='securityPadding d-flex justify-content-center' sm={8} xs={12}>
                        <div className='security-card'>
                            <h2 className='securityTitle4 font-22 mt-0'>Microsoft Azure</h2>
                            <p className='securitySubTitle3'>
                                Microsoft Azure is committed to providing the  <br></br>
                                highest levels of trust, transparency, standards  <br></br>
                                conformance, and regulatory compliance – with  <br></br>
                                the most comprehensive set of compliance <br></br>
                                offerings of any cloud service provider. <br></br>
                            </p>
                            <img className='security-image2 mt-4' src={image6} alt='main' />
                            {/* <div className='mb-10'>
                                <a target="_blank" href="./Octomate Android Application-security-report-certificate.pdf" download={'Octomate Android Application-security-report-certificate.pdf'}>
                                    <button className='btn-hyperlink-boxes mt-30'>
                                        Security pen test certificate from Astra
                                    </button>
                                </a>
                            </div> */}
                        </div>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2 pb-0'>
                    <Grid item className='securityPadding' sm={8} xs={12}>
                        <h2 className='securityTitle3'>Privacy at Octomate</h2>
                        <p className='securitySubTitle2'>
                            Data privacy is extremely important in today’s <br></br>
                            interconnected world. To make sure that your data is <br></br>
                            always in safe hands, Octomate controls access rights, <br></br>
                            limits disclosure and complies with data privacy <br></br>
                            regulations and international laws. Our data protection <br></br>
                            initiatives include physical security and best-in-class <br></br>
                            access management.<br></br>
                        </p>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center"
                    alignItems="center" className='securityMainContent2 pt-0'>
                    <Grid item className='d-flex securityPadding justify-content-center' sm={6} xs={12}>
                        <a className='w-100 d-flex align-items-end' href="/term-of-use" > 
                            <Grid container direction="row" className='security-card-2'>
                                <Grid item sm={8} xs={12}>
                                    <h2 className='securityTitle4 font-22 mt-0'>How we protect your <br></br>data</h2>
                                    <p className='securitySubTitle3 w-70 mt-40'>
                                        Learn More <IoIosArrowForward className='security-icon' />
                                </p>
                                </Grid>
                                <Grid item sm={4} xs={12} className='security-icon-container'>
                                    <IoShieldHalfSharp />
                                </Grid> 
                                <div className='security-card-overlay'></div>
                            </Grid>
                        </a>
                    </Grid>
                    <Grid item className='d-flex securityPadding justify-content-center' sm={6} xs={12}>
                        <a className='w-100  d-flex align-items-start' href="/privacy" > 
                        <Grid container direction="row" className='security-card-2'>
                            <Grid item sm={8} xs={12}>
                                <h2 className='securityTitle4 font-22 mt-0'>How we safeguard your privacy</h2>
                                <p className='securitySubTitle3 w-70 mt-40'>
                                    Learn More <IoIosArrowForward className='security-icon' />
                               </p>
                            </Grid>
                            <Grid item sm={4} xs={12} className='security-icon-container'>
                                <IoSearchSharp />
                            </Grid> 
                            <div className='security-card-overlay'></div>
                            
                        </Grid>
                        </a>
                    </Grid>
                </Grid>

                <div className={'demo-contact-us'}>
                    <h3>
                        Want to learn more about how we safeguard your data?
                    </h3>
                    <Link to="/scheduledemo">
                        <button className={"btn-download-app mt-0 d-flex align-items-center"}>
                            Schedule a demo with us here
                            <img src={arrowRight} alt="go" className={"arr-demo"} />
                        </button>
                    </Link>
                </div>
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </Grid>
        )
    }

}


export default withTranslation()(SecurityPrivacy)