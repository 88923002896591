import React from 'react';
import './index.css'
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import image1 from '../../assets/img/jobseekersmain.jpg'

import FooterSection from '../../sections/FooterSection';
import { Link } from 'react-router-dom';
import arrowRight from "../../assets/img/arrow-right.png";
import explore1 from '../../assets/img/explore-1.png'
import explore2 from '../../assets/img/explore-2.png'
import explore3 from '../../assets/img/explore-3.png'

class JobSeekers extends React.Component {
    state = {}

    componentDidMount() {
        document.documentElement.scrollTop = 0;
    }

    scrollTo = (elementId) => {
        let el = document.getElementById(elementId);
        window.scrollTo({ top: this.getOffset(el).top - 50, left: 0, behavior: 'smooth' });
    };

    getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    render() {
        const { t } = this.props;
        return (
            <Grid id="jobSeeker" container className={"jobSeekerContainer"}>
                <Helmet>
                    <title>Octomate - {t(`GENERAL.Jobseekers`)}</title>
                    <meta name="description" content={t("JOBSEEKER_PAGE.DESCPAGE")} />
                </Helmet>
                <Grid container direction="row" justify="center"
                    alignItems="center" className='jobSeekerMainContent'>
                    <Grid item className='jobSeekerLeftContent' sm={5} xs={12}>
                        <h1 className='jobSeekerTitle'>Job Seekers</h1>
                        <h3 className='jobSeekerSubTitle'>
                            Welcome to Octomate Staffing!<br></br>
                            Your solution to finding flexi or contract job opportunities that fit your unique lifestyle and preferences.
                        </h3>
                        <button onClick={() => this.scrollTo('explore')} className='btn-basic-link'>Learn More</button>
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <img className='jobSeekers-main-image' src={image1} alt='main' />
                    </Grid>
                </Grid>
                <Grid container id="explore">
                    <Grid item xs={12}>
                        <h2 className='text-center mb-10 jobSeekers-explore-title'>Explore</h2>
                        <h3 className='text-center mt-0 jobSeekers-explore-subtitle'>How It Works</h3>
                    </Grid>
                    <Grid item className='jobSeekers-explore-content' xs={12}>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='jobSeekers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore1} alt='' />
                                </Grid>
                                <Grid className='jobSeekers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Create Your Profile</h3>
                                    <p>
                                        Sign up for our app and build your unique professional. Highlight your skills, experience and job preferences
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='jobSeekers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore2} alt='' />
                                </Grid>
                                <Grid className='jobSeekers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Explore & Apply</h3>
                                    <p>
                                        Search our extensive list of flexi and contract jobs. Apply to jobs that interest you. With our app’s Work Shift Selection feature, you can filter for jobs that suit your availability.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='jobSeekers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore2} alt='' />
                                </Grid>
                                <Grid className='jobSeekers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Get Matched</h3>
                                    <p>
                                        Our advanced matching algorithm will match your profile with relevant job opportunities, and job notifications will be sent to you.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='jobSeekers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore3} alt='' />
                                </Grid>
                                <Grid className='jobSeekers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Paid As You Work</h3>
                                    <p>
                                        Get selected and start working according to your preferred work shifts. Our payment systems ensure that you get paid promptly.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={'demo-contact-us'}>
                    <h3>{t(`EMPLOYERS_PAGE.Sign up on our app today and`)} <br />
                        {t(`EMPLOYERS_PAGE.start enjoying work again!`)}
                    </h3>
                    <Link to="/app-download">
                        <button className={"btn-download-app mt-0"}>
                            {t(`GENERAL.Download App Here`)}
                            <img src={arrowRight} alt="go" className={"arr-demo"} />
                        </button>
                    </Link>
                </div>
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </Grid>
        )
    }

}

export default withTranslation()(JobSeekers)