import React from 'react';
import './index.css'
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import FooterSection from '../../sections/FooterSection';
import { Link } from 'react-router-dom';
import arrowRight from "../../assets/img/arrow-right.png";
import explore1 from '../../assets/img/explore-1.png'
import explore2 from '../../assets/img/explore-2.png'
import explore3 from '../../assets/img/explore-3.png'
import employersMainImg from '../../assets/img/employers-main-img.png'
import employersThumb1 from '../../assets/img/employers-direct-thumb1.png'
import employersThumb2 from '../../assets/img/employers-direct-thumb2.png'
import employersThumb3 from '../../assets/img/employers-direct-thumb3.png'

class EmployersDirectStaffing extends React.Component {
    state = {}

    componentDidMount() {
        document.documentElement.scrollTop = 0;
    }

    scrollTo = (elementId) => {
        let el = document.getElementById(elementId);
        window.scrollTo({ top: this.getOffset(el).top - 50, left: 0, behavior: 'smooth' });
    };

    getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    render() {
        const { t } = this.props;
        return (
            <Grid id="employers" container className={"employersContainer"}>
                <Helmet>
                    <title>Octomate - {t(`GENERAL.Employers`)} {t(`GENERAL.DirectHire`)}</title>
                    <meta name="description" content={t("EMPLOYERS_PAGE.DESCPAGE")} />
                </Helmet>
                <Grid container direction="row" justify="center"
                    alignItems="center" className='employersMainContent'>
                    <Grid item sm={6} xs={12}>
                        <img className='employers-main-image' src={employersMainImg} alt='main' />
                    </Grid>
                    <Grid className='employersRightContent' item sm={6} xs={12}>
                        <h1 className='employersTitle'>Employers </h1>
                        <h2 className='employersTitle2'>Direct Hire</h2>
                        <h3 className='employersubTitle'>
                            Octomate simplifies the <br></br> recruitment and talent management process, <br></br> connecting employers to candidates
                        </h3>
                        <button onClick={() => this.scrollTo('feature')} className='btn-basic-link'>Learn More</button>
                    </Grid>
                </Grid>
                <Grid container id='feature' className='employers-feature-container'>
                    <Grid item xs={12}>
                        <h2 className='text-center employers-feature-title'>Our Direct Hire Feature</h2>
                    </Grid>
                    <Grid container className='employers-feature-thumb-container'>
                        <Grid className='employers-feature-thumb-item' item sm={4} xs={12}>
                            <img src={employersThumb1} alt='thumb1' />
                            <h3 className='text-center'>Easy Job Postings</h3>
                            <ul>
                                <li>
                                    Create job descriptions, specify job requirements
                                </li>
                                <li>
                                    Job postings will be broadcasted to qualified candidates on our platform
                                </li>
                            </ul>
                        </Grid>
                        <Grid className='employers-feature-thumb-item' item sm={4} xs={12}>
                            <img src={employersThumb2} alt='thumb1' />
                            <h3 className='text-center'>Unlock a Vast Talent Pool</h3>
                            <ul>
                                <li>
                                    Gain access to a vast pool of qualified candidates
                                </li>
                                <li>
                                    Connect with ideal work force for the right place at the right time
                                </li>
                            </ul>
                        </Grid>
                        <Grid className='employers-feature-thumb-item' item sm={4} xs={12}>
                            <img src={employersThumb3} alt='thumb1' />
                            <h3 className='text-center'>Candidate Management</h3>
                            <ul>
                                <li>
                                    Employers will receive applications and filter for qualified candidates
                                </li>
                                <li>
                                    Employment contracts will be administered via the platform
                                </li>
                                <li>
                                    Work shift notifications can be broadcasted to candidates
                                </li>
                                <li>
                                    On-the-go attendance tracking and approval
                                </li>
                                <li>
                                    Next Day, Weekly, Monthly Payments
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <h2 className='text-center mb-10 employers-explore-title'>Explore</h2>
                        <h3 className='text-center mt-0 employers-explore-subtitle'>How It Works</h3>
                    </Grid>
                    <Grid item className='employers-explore-content' xs={12}>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='employers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore1} alt='' />
                                </Grid>
                                <Grid className='employers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Create Your Account</h3>
                                    <p>
                                        Sign up as an employer on our Direct Hire Platform and gain access to our innovative recruitment system.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='employers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore2} alt='' />
                                </Grid>
                                <Grid className='employers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Post Your Jobs</h3>
                                    <p>
                                        Create job postings, specify job descriptions, qualifications, requirements, work shifts and other essential details that are required.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='employers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore2} alt='' />
                                </Grid>
                                <Grid className='employers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Receive and Screen Candidate Applications</h3>
                                    <p>
                                        Receive applications from qualified and skilled candidates who match the requirements of the job postings.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='employers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore3} alt='' />
                                </Grid>
                                <Grid className='employers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Hire Qualified Candidates</h3>
                                    <p>
                                        Directly hire qualified candidates who meet your desired skillset and qualifications.
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row'>
                                <Grid className='employers-explore-icon' item sm={5} xs={12}>
                                    <img src={explore3} alt='' />
                                </Grid>
                                <Grid className='employers-explore-item pb-24' item sm={7} xs={12}>
                                    <h3 className='mt-0'>Manage Qualified Workforce</h3>
                                    <p>
                                        Manage your workforce operations on-the-go with our onboarding administration, attendance, benefits and scheduling features
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={'demo-contact-us'}>
                    <h3>{t(`EMPLOYERS_PAGE.Sign up on our app today and`)} <br />
                        {t(`EMPLOYERS_PAGE.start enjoying work again!`)}
                    </h3>
                    <Link to="/app-download">
                        <button className={"btn-download-app mt-0"}>
                            {t(`GENERAL.Download App Here`)}
                            <img src={arrowRight} alt="go" className={"arr-demo"} />
                        </button>
                    </Link>
                </div>
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </Grid>
        )
    }

}

export default withTranslation()(EmployersDirectStaffing)