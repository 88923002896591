import React from 'react';
import './privacy.css'
import logo from '../../assets/img/octomate-logo-white.svg';
import back from '../../assets/img/Back.svg';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
class Privacy extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container className={'privacy'}>
                <Helmet>
                    <title>Octomate - {t(`PRIVACY_PAGE.PRIVACY NOTICE`)}</title>
                    <meta name="description" content={t(`PRIVACY_PAGE.The following paragraphs explain how your personal data will be treated. This policy may be updated from time to time.`)} />
                </Helmet>
                <img src={logo} className={'privacy-logo'} alt="octomate" />
                <div className={'privacy-content'}>
                    <div className={'privacy-content-scroll'}>
                        <h3 className={'privacy-header'}>{t(`PRIVACY_PAGE.Privacy Notice`)}</h3>
                        <span>{t(`PRIVACY_PAGE.The following paragraphs explain how your personal data will be treated. This policy may be updated from time to time.`)} </span>
                        <ol>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.We may collect, use or disclose the Personal Data which you provide to us for the Purpose described in the Octomate Data Protection Policy, including the provision of Services, storage, analytical or dispute resolution purposes, as well as, to comply with applicable laws, regulations and Octomate’s internal policies. Capitalized terms used in this form and not expressly defined shall have the same meaning as set out in the Octomate Data Protection Policy.`)}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.POINT2`)}
                                </p>
                                <ul>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT2SUB1`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT2SUB2`)}</li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.POINT3`)}
                                </p>
                                <ul>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB1`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB2`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB3`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB4`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB5`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB6`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB7`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB8`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB9`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB10`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT3SUB11`)}</li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.POINT4`)}
                                </p>
                                <ul>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT4SUB1`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT4SUB2`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT4SUB3`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT4SUB4`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT4SUB5`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT4SUB6`)}</li>
                                    <li className='mb-10'> {t(`PRIVACY_PAGE.POINT4SUB7`)}</li>

                                </ul>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.We may also transfer your Personal Data to Octomate’s employees, officers, directors, clients (and its agents), suppliers (including but not limited to IT providers, financial and legal advisors), authorities and to other Octomate affiliates, based locally or abroad, in order to reasonably achieve the Purpose. In any case, transfers to third parties will strictly be on a need to know basis, in order to comply with contractual or legal obligations and provided that such third parties have data protection standards comparable to Octomate or the requirement under the applicable Data Protection Laws.`)}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.We will securely handle, maintain and store - for a limited period of time - your Personal Data in compliance with Octomate Data Protection Policy and the applicable laws and regulations.`)}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.We rely on your Personal Data to achieve the Purpose; hence, you shall ensure that the Personal Data you provide is accurate, correct and complete. If you wish to correct, update or delete your Personal data, you may contact the Octomate Data Protection Officer.`)}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.You are entitled to withdraw this consent at any time by giving notice to the Octomate Data Protection Officer. You acknowledge that such withdrawal shall apply prospectively and only affect our future use or disclosure of your Personal Data.`)}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.We may use third party cookies on this website. By accessing our website, you agree that we can place cookies on your device. Our website may allow third parties to download cookies to your device. You can learn about cookies and what they do at www.allaboutcookies.org.`)}
                                </p>
                            </li>
                            <li><p>
                                {t(`PRIVACY_PAGE.When you visit our website, our server will record your IP address together with the date, time and duration of your visit. We use this information to compile statistical data on the use of our website to track how users navigate through our site. We do this to evaluate and improve our site.`)}
                            </p>
                            </li>
                            <li>
                                <p>
                                    {t(`PRIVACY_PAGE.If you have any queries regarding Octomate’s treatment of your Personal Data, or would like to request for your personal data to be updated or corrected; or withdraw consent to use or disclose the personal data we hold about you, you may contact Octomate Data Protection Officer at hello@octomate.us. Please allow us reasonable time to respond and effect any change.`)}
                                </p>
                            </li>
                        </ol >
                    </div>
                </div>
                <div className={"my-auto"}>
                    <a href="/">
                        <button className={'back-button'}>
                            <img src={back} className={'back-icon'} alt="back icon" />
                            {t(`PRIVACY_PAGE.Back To Website`)}
                        </button>
                    </a>
                </div>
            </Grid>
        )
    }
}

export default withTranslation()(Privacy)