import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';


import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import logo from '../../assets/img/logo.png';
import logoLigth from '../../assets/img/octomate-logo-white.svg';
import './index.css';
import { Link } from 'react-router-dom';
import { Link as Link2 } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { firebaseAuth } from '../../auth/firebase'
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const ITEM_HEIGHT = 48;
class NavigationBar extends React.Component {

    state = {
        activeLink: 'landing',
        right: false,
        scrollY: 0,
        homeY: 0,
        featuresY: 0,
        usecasesY: 0,
        anchorEl: null,
        anchorElS: null,
        anchorElLag: null,
        open: false,
        openLanguage: false,
        openServices: false,
        openResources: false,
        location: window.location.pathname,
        hide: false,
        ligthMode: false,
        page: "",
        language: "en",
        drawerServiceOpen: false
    };

    scrollTo = (elementId) => {
        this.setState({ right: false, ligthMode: false, location: window.location.pathname, activeLink:elementId });
        let el = document.getElementById(elementId);
        window.scrollTo({ top: this.getOffset(el).top - 50, left: 0, behavior: 'smooth' });
    };

    getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    componentDidMount() {
        const translation = window.localStorage.getItem("translation")
        if (translation)
            this.changeLanguage(translation)
        window.localStorage.removeItem("email")
        firebaseAuth.onAuthStateChanged(user => {
            this.setState({ user: user })
        })
        // this.setState({
        //     homeY: this.getOffset(document.getElementById('landing')).top,
        //     featuresY: this.getOffset(document.getElementById('features')).top,
        //     usecasesY: this.getOffset(document.getElementById('usecases')).top,
        //     page: window.location.pathname,
        // });
        if (this.state.location === "/privacy" ||
            this.state.location === "/term-of-use" ||
            this.state.location === '/dataprotection' ||
            this.state.location === '/contactus' ||
            this.state.location === '/admin'
        ) {
            this.setState({ hide: true })
        }
        this.setTextColor();
    
        window.addEventListener('scroll', this.handleScroll);
    }

    setTextColor() {
        if (window.location.pathname === "/app-download" || 
        window.location.pathname === "/employers-direct-staffing" || 
        window.location.pathname === "/employers-managed-services" || 
        window.location.pathname === "/Modules" ||
        window.location.pathname === '/Blog' || 
        window.location.pathname === '/Guides' || 
        window.location.pathname.includes("/Blog") || 
        window.location.pathname.includes("/Case")) {
            this.setState({ ligthMode: true, activeLink:window.location.pathname });
        } else {
            this.setState({ ligthMode: false, activeLink:window.location.pathname });
        }
    }

    changeLanguage(value) {
        this.setState({ language: value })
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleDrawerServices() {
        this.setState({ drawerServiceOpen: !this.state.drawerServiceOpen })
    }

    handleLigthMode(isLightModeOn, url) {
        setTimeout(() => {
            this.setState({
                ligthMode: isLightModeOn,
                page: '/' + window.location.pathname.split("/")[1],
                location: window.location.pathname,
                activeLink:url
            })
        }, 100);

    }

    handleScroll = (event) => {
        let scrollY = window.scrollY;
        this.setTextColor()
        this.handleLigthMode(this.state.ligthMode, '/' + window.location.pathname.split("/")[1])
        this.setState({
            scrollY,
        });
        // if (scrollY + 200 > this.state.usecasesY) {
        //     this.setState({ activeLink: 'usecases' });
        // } else if (scrollY + 200 > this.state.featuresY) {
        //     this.setState({ activeLink: 'features' });
        // } else {
        //     this.setState({ activeLink: 'landing' });
        // }
    };

    handleServicesClick = (event) => {
        this.setState({ anchorElS: event.currentTarget, openServices: true })
    }

    handleResourcesClick = (event) => {
        this.setState({ anchorElS: event.currentTarget, openResources: true })
    }

    handleServicesClose = () => {
        this.setState({ anchor: null, openServices: false });
    }
    
    handleResourcesClose = () => {
        this.setState({ anchor: null, openResources: false });
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget, open: true })
    };

    handleClose = () => {
        this.setState({ anchor: null, open: false });
    };


    toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ [side]: open });
    };

    logout = () => {
        firebaseAuth.signOut()
    }

    handleClickLanguage = (event) => {
        this.setState({ anchorElLag: event.currentTarget, openLanguage: true })
    };

    handleCloseLanguage = () => {
        this.setState({ anchor: null, openLanguage: false });
    };

    // replaced activeNavLink with navlink atm

    render() {
        const { t } = this.props;
        return (
            <AppBar
                position="fixed"
                color='default'
                elevation={this.state.scrollY > 10 ? 4 : 0}
                className={`${this.state.scrollY > 10 ? 'stickyNavBar' : window.location.pathname === "/employers-direct-staffing" || window.location.pathname === "/employers-managed-services" ? 'navbar' : 'transparent'} ${this.state.hide ? 'hide' : ''} ${this.state.ligthMode && this.state.scrollY > 10 ? 'greenStickyNavBar' : ''}`}
            >
                <Toolbar className={`navBarContainer`}>
                    <div>
                        <Link to="/" onClick={() => this.scrollTo('/Home')}>
                            <img src={logo} className={`${this.state.ligthMode ? 'hide' : ''} navBarLogo`} alt="octomate" />
                            <img src={logoLigth} className={`${this.state.ligthMode ? '' : 'hide'} navBarLogo`} alt="octomate" />
                        </Link>
                    </div>

                    <div className={'navbarLinksContainer'}>
                        <Link to="/Home">
                            <button className={`${this.state.activeLink === '/Home'? 'activeNavLink' : 'navLink'} ${this.state.ligthMode ? 'ligth-mode' : ''}`}
                                onClick={() => this.scrollTo('/Home')}>
                                {t('NAVIGATION.Home')}
                            </button>
                        </Link>
                        <Link to="/Modules">
                            <button className={`${this.state.activeLink === 'features' ? ' navLink' : 'navLink'} ${this.state.ligthMode ? 'ligth-mode' : ''} ${this.state.page === "/Modules" ? 'fullop' : ''}`}
                                onClick={() => this.handleLigthMode(true, "/Modules")}>
                                {t('GENERAL.Modules')}
                            </button>
                        </Link>
                        <button className={`${this.state.activeLink === '/jobSeekers' ? 'activeNavLink' : 'navLink'} ${this.state.ligthMode ? 'ligth-mode' : ''} ${this.state.page === "/employers-direct-staffing" || this.state.page === "/employers-managed-services" ? 'fullop' : ''} mr-50`}
                            onClick={event => this.handleServicesClick(event)} aria-controls="long-menu-services">
                            {t('NAVIGATION.Services')}
                        </button>
                        <Menu
                            id="long-menu-services"
                            anchorEl={this.state.anchorElS}
                            keepMounted
                            open={this.state.openServices}
                            onClose={this.handleServicesClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '33ch',
                                },
                            }}
                        >
                            <Link className={'navLink'} onClick={() => this.handleLigthMode(false, "/jobSeekers")} to="/jobSeekers"><MenuItem >{t('NAVIGATION.ForJobSeekers')} </MenuItem> </Link>
                            <Link className={'navLink'} onClick={() => this.handleLigthMode(true, "/employers-direct-staffing")} to="/employers-direct-staffing"><MenuItem >{t('NAVIGATION.ForEmployers')} ({t('NAVIGATION.DirectHire')}) </MenuItem> </Link>
                            <Link className={'navLink'} onClick={() => this.handleLigthMode(true, "/employers-managed-services")} to="/employers-managed-services"> <MenuItem >{t('NAVIGATION.ForEmployers')} ({t('NAVIGATION.ManagedServices')}) </MenuItem> </Link>
                        </Menu>
                        <Link to="/Blog">
                            <button className={`${this.state.activeLink === 'helpcenter' ? 'navLink' : 'navLink'} ${this.state.ligthMode ? 'ligth-mode' : ''} ${this.state.page === "/Blog" ? 'fullop' : ''}`}
                                onClick={() => this.handleLigthMode(true, "/Blog")}>
                                {t('NAVIGATION.Blog')}
                            </button>
                        </Link>
                         {/* <button className={`${this.state.activeLink === '/Blog'||this.state.activeLink === '/Guides' ? 'activeNavLink' : 'navLink'} ${this.state.ligthMode ? 'ligth-mode' : ''} ${this.state.page === "/Blog" || this.state.page === "/Guides" ? 'fullop' : ''} mr-50`}
                            onClick={event => this.handleResourcesClick(event)} aria-controls="long-menu-resources">
                            {t('NAVIGATION.Resources')}
                        </button> */}
                         <Menu
                            id="long-menu-resources"
                            anchorEl={this.state.anchorElS}
                            keepMounted
                            open={this.state.openResources}
                            onClose={this.handleResourcesClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '33ch',
                                },
                            }}
                        >
                            <Link className={'navLink'} onClick={() => this.handleLigthMode(true, "/Blog")} to="/Blog"><MenuItem >{t('NAVIGATION.Blog')} </MenuItem> </Link>
                            <Link className={'navLink'} onClick={() => this.handleLigthMode(true, "/Guides")} to="/Guides"><MenuItem >{t('NAVIGATION.GUIDES')} </MenuItem> </Link>
                        </Menu>
                        <Link to="/SecurityPrivacy">
                            <button className={`${this.state.activeLink === '/SecurityPrivacy' ? 'activeNavLink' : 'navLink'} ${this.state.ligthMode ? 'ligth-mode' : ''} ${this.state.page === "/SecurityPrivacy" ? 'fullop' : ''}`}
                                onClick={() => this.handleLigthMode(false, "/SecurityPrivacy")}>
                                {t('NAVIGATION.Security')} & {t('NAVIGATION.Privacy')} 
                            </button>
                        </Link>
                        {/* <Link to="/CaseStudies">
                            <button className={`${this.state.activeLink === 'helpcenter' ? 'navLink' : 'navLink'} ${this.state.ligthMode ? 'ligth-mode' : ''} ${this.state.page === "/CaseStudies" ? 'fullop' : ''}`}
                                onClick={() => this.handleLigthMode(true, "/CaseStudies")}>
                                {t('NAVIGATION.CaseStudies')}
                            </button>
                        </Link> */}
                    </div>

                    <div className="buttonContainer">
                        <div className="navButton">
                            <Link2 href="/contactus">
                                <button onClick={() => this.handleLigthMode(false)} className={this.state.ligthMode ? "btn-schedule-demo-light" : "btn-schedule-demo"}>
                                    {t('GENERAL.Contact Us')}
                                </button>
                            </Link2>
                        </div>
                    </div>

                    <div className={'app-download-links'}>
                        <Link to="/app-download">
                            <button className={`${this.state.activeLink === 'app-download' ? 'navLink' : 'navLink'} ${this.state.ligthMode ? 'ligth-mode' : ''} ${this.state.page === "/app-download" ? 'fullop' : ''}`}
                                onClick={() => this.handleLigthMode(true, "/app-download")}>
                                {t('NAVIGATION.APPDOWNLOAD')}
                            </button>
                        </Link>
                    </div>




                    {this.state.user ? <div className={'admin-user'}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={this.handleClick}
                        >
                            <AccountCircleIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={this.state.open}
                            onClose={this.handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >
                            <MenuItem onClick={this.logout}>Logout</MenuItem>
                        </Menu>
                    </div> : null}

                    <Button
                        className={this.state.ligthMode ? "ligth-mode languageButton" : "languageButton"}
                        aria-label="more"
                        aria-controls="language"
                        aria-haspopup="true"
                        onClick={this.handleClickLanguage}
                    >
                        {window.innerWidth <= 475 ? '' : ''} ({this.state.language === 'en' ? "EN" : this.state.language === 'jap' ? '日本' : this.state.language === 'CN' ? '简体中文' : this.state.language === 'TW' ? '繁體中文' : this.state.language}) <ArrowDropDownIcon />
                    </Button>
                    <Menu
                        id="language"
                        anchorEl={this.state.anchorElLag}
                        keepMounted
                        open={this.state.openLanguage}
                        onClose={this.handleCloseLanguage}

                        PaperProps={{
                            style: {
                                width: 185,
                            },
                        }}
                    >
                        <MenuItem className={'language-menu'} onClick={() => this.changeLanguage("en")}>English</MenuItem>
                        <MenuItem className={'language-menu'} onClick={() => this.changeLanguage("jap")}>日本</MenuItem>
                        <MenuItem className={'language-menu'} onClick={() => this.changeLanguage("CN")}>简体中文</MenuItem>
                        <MenuItem className={'language-menu'} onClick={() => this.changeLanguage("TW")}>繁體中文</MenuItem>
                    </Menu>

                    <div className={'menuButton'}>
                        <IconButton onClick={this.toggleDrawer('right', true)} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                    </div>

                    <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                        <List className={'menuList'}>
                            <ListItem button>
                                <ListItemText
                                    className={'navLink'}
                                    primary={'Home'}
                                    onClick={() => this.scrollTo('landing')}
                                />
                            </ListItem>
                            <ListItem button>
                                <Link to="/Modules">
                                    <ListItemText
                                        className="navLink"
                                        primary={'Modules'}
                                        onClick={() => this.handleLigthMode(true, "/Modules")}
                                    />
                                </Link>
                            </ListItem>
                            <ListItem button>
                                <ListItemText
                                    className="navLink"
                                    primary={'Services'}
                                    onClick={() => this.handleDrawerServices()}
                                />{this.state.drawerServiceOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.drawerServiceOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button style={{marginLeft:'10px'}} sx={{ pl: 4 }}>
                                        <Link to="/jobSeekers">
                                            <ListItemText
                                                className="navLink"
                                                primary={'For Job Seekers'}
                                                onClick={() => this.handleLigthMode(false, "/jobSeekers")}
                                            />
                                        </Link>
                                    </ListItem >
                                    <ListItem button style={{marginLeft:'10px'}} sx={{ pl: 4 }}>
                                        <Link to="/employers-direct-staffing">
                                            <ListItemText
                                                className="navLink"
                                                primary={'For Employers (Direct Hire)'}
                                                onClick={() => this.handleLigthMode(true, "/employers-direct-staffing")}
                                            />
                                        </Link>
                                    </ListItem >
                                    <ListItem button style={{marginLeft:'10px'}} sx={{ pl: 4 }}>
                                        <Link to="/employers-managed-services">
                                            <ListItemText
                                                className="navLink"
                                                primary={'For Employers (Managed Services)'}
                                                onClick={() => this.handleLigthMode(true, "/employers-managed-services")}
                                            />
                                        </Link>
                                    </ListItem >
                                </List>
                            </Collapse>
                            <ListItem button>
                                <Link to="/Blog">
                                    <ListItemText
                                        className="navLink"
                                        primary={'Blog'}
                                        onClick={() => this.handleLigthMode(true, "/Blog")}
                                    />
                                </Link>
                            </ListItem>
                            {/* <ListItem button>
                                <Link to="/CaseStudies">
                                    <ListItemText
                                        className="navLink"
                                        primary={'Case Studies'}
                                        onClick={() => this.handleLigthMode(true, "/CaseStudies")}
                                    />
                                </Link>
                            </ListItem> */}
                            <ListItem button>
                                <Link to="/SecurityPrivacy">
                                    <ListItemText
                                        className="navLink"
                                        primary={'Security Privacy'}
                                        onClick={() => this.handleLigthMode(true, "/SecurityPrivacy")}
                                    />
                                </Link>
                            </ListItem>
                            <ListItem button>
                                <Link2 href="/contactus">
                                    <ListItemText
                                        className="navLink"
                                        primary={'Contact Us'}
                                        onClick={() => this.handleLigthMode(false)}
                                    />
                                </Link2>
                            </ListItem>
                            {/* <ListItem button>
                                <Link to="/HelpCenter">
                                    <ListItemText
                                        className="navLink"
                                        primary={'Help Center'}
                                    />
                                </Link>
                            </ListItem> */}
                            <ListItem button>
                                <Link to="/app-download">
                                    <ListItemText
                                        className="navLink"
                                        primary={'App Download'}
                                    />
                                </Link>
                            </ListItem>
                            {this.state.user ?
                                <ListItem button>
                                    <Link to="/admin">
                                        <ListItemText
                                            className="navLink"
                                            primary={'Logout'}
                                            onClick={this.logout}
                                        />
                                    </Link>
                                </ListItem> : null}
                        </List>
                    </Drawer>

                    {/* <FormControl>
                        <Select
                            labelId="language-option"
                            id="language-select"
                            value={this.state.language}
                        >
                            <MenuItem onClick={() => this.changeLanguage("en")} value='en' > EN </MenuItem>
                            <MenuItem onClick={() => this.changeLanguage("jap")} value='jap'> 日本 </MenuItem>
                            <MenuItem onClick={() => this.changeLanguage("CN")} value='CN' > 简体中文 </MenuItem>
                            <MenuItem onClick={() => this.changeLanguage("TW")} value='TW' > 繁體中文</MenuItem>
                        </Select>
                    </FormControl> */}

                </Toolbar>
            </AppBar>
        );
    }
}

export default withTranslation()(NavigationBar)