import React from 'react';
import './index.css'
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import OButton from '../../components/OButton';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import EditorToolbar, { modules, formats } from "../../shared/EditorToolbar";
import ReactQuill from 'react-quill';
import { FaPlus } from "react-icons/fa";
import * as moment from 'moment'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { firebaseAuth, firestore, fireCount, firebaseStorage } from '../../auth/firebase';
import SimpleDialog from "./components/change-icon-dialog"
import { Link, Route, Switch } from 'react-router-dom';
import Bloggy from '../../assets/img/Bloggy.jpg'
import searchIcon from '../../assets/img/Search.svg'
import ListItem from '@material-ui/core/ListItem';
import FooterSection from '../FooterSection';
import { withTranslation } from 'react-i18next';
import DetailedCase from './components/detailCase';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import arrowRight from "../../assets/img/arrow-right.png";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    inputRoot: {
        '&[data-focus="true"]': {
            backgroundColor: '#fff !important',
            borderColor: 'transparent !important',
        },
        '&[aria-selected="true"]': {
            backgroundColor: '#fff !important',
            borderColor: 'transparent !important',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        }
    },
    clearIndicator: {
        marginRight: "150px"
    },
    formControl: {
        minWidth: '100%',
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    }
}));

class CaseStudies extends React.Component {
    baseRef = firestore.collection(`case`);
    counterCasesRef = firestore.collection(`counters`)
    unsubscribe;
    state = {
        user: null,
        errorMessage: "",
        message: "",
        open: false,
        dialogOpen: false,
        deleteDialogOpen: false,
        cases: [],
        allCases: [],
        featuredCase: {
            title: "",
            createdTime: "",
            createdBy: "",
            description: "",
            icon: "",
            content: "",
            tag: []
        },
        limitedCases: [],
        loading: true,
        isViewAll: false,
        currentPage: 0,
        previousPage: 0,
        pageSize: [],
        tags: [
            'Retail FMCG',
            'Leading Fast Moving Clothing Company',
            'Beauty & Fragrance',
            'Industry',
            'Engineering & Construction',
            'Health & Wellness',
            'Non-Profit',
            'Professional Services',
            'Research & Education',
            'Retail & Products',
            'Technology & Investment',
            'Travel & Tourism']
    }

    case = {
        title: "",
        createdTime: "",
        createdBy: "",
        description: "",
        icon: "",
        content: "",
        tag: []
    };
    setEditorRef = (editor) => this.editor = editor

    scrollTo = (elementId) => {
        // this.setState({ right: false, ligthMode: false, location:window.location.pathname });
        let el = document.getElementById(elementId);
        window.scrollTo({ top: this.getOffset(el).top - 50, left: 0, behavior: 'smooth' });
    };

    getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    componentDidMount() {
        firebaseAuth.onAuthStateChanged(user => {
            this.setState({ user: user })
        })
        this.getCases();
        document.documentElement.scrollTop = 0;
    }

    getCases() {
        this.unsubscribe = this.baseRef.orderBy("createdTime", "desc").onSnapshot(snapshot => {
            if (snapshot.docs.length === 0) {
                this.setState({ loading: false });
            }
            var i = 0;
            const cases = [];
            this.setState({ lastVisible: snapshot.docs[snapshot.docs.length - 1], firstVisible: snapshot.docs[0] });
            snapshot.forEach((doc) => {
                i += 1;
                const { title, createdTime, createdBy, description, tag, icon, content } = doc.data();
                cases.push({
                    index: i,
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    tag,
                    icon,
                    content
                });
                this.setState({ tmpCases: cases, allCases: cases, loading: false });
            });
            window.localStorage.setItem("tmp", JSON.stringify(this.state.allCases));
            let limitedCase = this.state.allCases.slice(0, 3);
            let cases2 = this.state.allCases.slice(0, 9);
            let calculatePage = Math.ceil(this.state.allCases.length / 9);
            this.setState({ limitedCase: limitedCase, cases: cases2, pageSize: Array(calculatePage).fill(0, 0, calculatePage), featuredCase: cases2[0] });
        });
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    changeCreateMode = res => {
        const { createMode } = this.state
        if (res.desc === 'edit') {
            this.setState({ mode: res.desc, createMode: !createMode, avatar: "", avatarURL: "" })
            this.case = res.case
        } else {
            this.case = {
                title: "",
                createdTime: "",
                createdBy: "",
                description: "",
                icon: "",
                tag: [],
                content: ""
            }
            this.setState({ mode: '', createMode: !createMode, avatar: "", avatarURL: "" })
        }
    }

    caseDelete = (res) => {
        var deleteAll = false
        if (this.state.cases.length === 1) {
            deleteAll = true
        }
        this.baseRef.doc(res).delete().then(res => {
            if (deleteAll) {
                this.setState({ cases: [] })
            }
            this.handleClick("Case successfully deleted! ...")
            this.handleDialogClose()
        }).catch(err => {
            if (err) {
                this.handleClick(err)
                console.log(err)
            }
        })
    }

    onEdit = (res) => {
        this.changeCreateMode({ case: res, desc: 'edit' })
        document.documentElement.scrollTop = 200;
    }

    handleClick = res => {
        this.setState({ open: true, message: res })
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false, dialogOpen: false })
    };

    handleDialogClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ deleteDialogOpen: false })
    };

    handleDialogOpen = () => {
        this.setState({ deleteDialogOpen: true })
    }

    handleChangeUsername = event => {
        this.setState({ username: event.target.value })
    };
    handleUploadStart = e => {
        const file = e.currentTarget.files[0]
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, avatar: file.name, dialogOpen: true })
            });
            reader.readAsDataURL(file);
        }
    };

    saveCrop = () => {
        if (this.editor) {
            this.editor.getImageScaledToCanvas().toBlob((blob) => {
                const imageKey = moment().unix()
                const uploadTask = firebaseStorage.ref(`/images/${imageKey}`).put(blob)
                uploadTask.on('state_changed',
                    (snapShot) => {

                    }, (err) => {
                        console.log(err)
                    }, () => {
                        firebaseStorage.ref('images').child(imageKey.toString()).getDownloadURL()
                            .then(url => {
                                this.setState({ avatarURL: url, dialogOpen: false })
                            })
                    })
            }, "image/png")
        }
    }

    updateCounters = (res) => {
        var total = 0
        if (res === 'add') {
            this.setState({ totalDoc: (this.state.totalDoc + 1) })
            total = this.state.totalDoc
            total += 1
        }
        else {
            this.setState({ totalDoc: (this.state.totalDoc - 1) })
            total = this.state.totalDoc
            total -= 1
        }
        if (total > 0) {
            total = Math.ceil((total / 5))
        }
        this.setState({ total: total })
    }

    errMatcher = () => {
        if (this.case.title === "") {
            return this.setState({ errorMessage: "Please fill the title" })
        } else if (this.case.description === "") {
            return this.setState({ errorMessage: "Please fill the description" })
        } else {
            return this.setState({ errorMessage: null })
        }
    }

    onSearch = (value) => {
        if (!value) {
            this.setState({ cases: this.state.allCases })
            return this.forceUpdate()
        }
        this.setState({ cases: this.state.cases.filter(x => x.tag?.filter(y => y === value).length > 0).sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)) })
        this.forceUpdate()
    }

    handleViewAll = () => {
        let isViewAll = !this.state.isViewAll
        this.setState({ isViewAll: isViewAll })
    }

    handlePage = (index) => {
        let nextCases = this.state.allCases.slice((index * 9), ((index + 1) * 9))
        this.setState({ currentPage: index, cases: nextCases })

    }

    handleNextPage = () => {
        if ((this.state.currentPage + 1) !== this.state.pageSize.length) {
            let nextPage = this.state.currentPage + 1
            let nextCases = this.state.allCases.slice((nextPage * 9), ((nextPage + 1) * 9))
            this.setState({ currentPage: nextPage, cases: nextCases })
        }
    }

    handlePreviousPage = () => {
        if (this.state.currentPage !== 0) {
            let nextPage = this.state.currentPage - 1
            let nextCases = this.state.allCases.slice((nextPage * 9), ((nextPage + 1) * 9))
            this.setState({ currentPage: nextPage, cases: nextCases })
        }
    }

    tagChanged = (event) => {
    };

    render() {
        const { t } = this.props;
        return (
            <Grid container className={"case-container"}>
                <Helmet>
                    <title>Octomate - {t(`CASESTUDIES_PAGE.Case Studies`)}</title>
                    <meta name="description" content={t(`CASESTUDIES_PAGE.Octomate Case Studies`)} />
                </Helmet>
                <Switch>
                    <Route exact path="/CaseStudies">
                        {!this.state.createMode ?
                            <Grid container className={'case-text'} justify='center'>
                                <Grid item sm={6} xs={12}>
                                    <h1>{t(`CASESTUDIES_PAGE.Case Studies`)}</h1>
                                    <h2>{this.state.featuredCase.title}</h2>
                                    <p className='mr-60'>{this.state.featuredCase.description}</p>
                                    {this.state.featuredCase.title !== '' ?
                                        <Link to={`/CaseStudies/${this.state.featuredCase.key}:${this.state.featuredCase.title.replace(/\s+/g, '-')}`}>
                                            <button className='btn-case-hyperlink'>Read More</button>
                                        </Link> :
                                        null
                                    }
                                </Grid>
                                <Grid item sm={6} xs={12} container justify='center'>
                                    <img className={"case-first-image"} src={this.state.featuredCase.icon === '' ? Bloggy : this.state.featuredCase.icon} alt="case-img" />
                                </Grid>
                                <Grid>
                                    <button onClick={() => this.scrollTo('cases')} className='btn-case-below'>
                                        Read More Below
                                        <ExpandMoreIcon></ExpandMoreIcon>
                                    </button>
                                </Grid>
                            </Grid>
                            :
                            ''
                        }
                        {
                            !this.state.createMode ?
                                <div id='cases' style={{ backgroundColor: "#00504C", width: '100%' }}>
                                    <Grid container className={"case-card-contents"} spacing={2}>
                                        {this.state.user && !this.state.createMode ?
                                            <AddMore t={t} onClick={this.changeCreateMode} /> : null
                                        }
                                        <Grid container alignItems="center" justify="center" className='mb-80'>
                                            <Search t={t} cases={this.state.tags} onSearch={this.onSearch} />
                                            <img src={searchIcon} alt="search-icon" className={'search-case-icon'} />
                                        </Grid>
                                        {this.state.cases.length > 0 ? this.state.cases.map((cases, index) =>
                                            <Grid key={index} item lg={4} md={4} sm={4} xs={12} className={`case-card-content`} >
                                                <Card className={`case-card`}>
                                                    <Grid container spacing={1} className='case-container-overlay'>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            {/* <CardMedia
                                                                className={'case-media'}
                                                                image={cases.icon === "" ? Bloggy : cases.icon}
                                                                title={cases.title}
                                                            /> */}
                                                            <div className='case-media-container'>
                                                                <Link to={`/CaseStudies/${cases.key}:${cases.title.replace(/\s+/g, '-')}`}>
                                                                    <img className={'case-media'} src={cases.icon === "" ? Bloggy : cases.icon} alt='case-media'></img>
                                                                </Link>
                                                            </div>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} className='case-overlay-title'>
                                                            <Typography variant="h5" component="h2" className={"case-card-title"}>
                                                                {cases.tag?.length > 0 ?cases.tag[0]:cases.title}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} className='case-overlay'>
                                                            <CardContent className={'p-0'}>
                                                                <Link to={`/CaseStudies/${cases.key}:${cases.title.replace(/\s+/g, '-')}`}>
                                                                    <Typography variant="h5" component="h2" className={"case-card-title"}>
                                                                        {cases.title}
                                                                    </Typography>
                                                                </Link>
                                                                <br></br>
                                                                {/* <Typography className={"case-card-date"} variant="subtitle2" component="small">
                                                                    {t(`CASESTUDIES_PAGE.By`)} {cases.createdBy}, {moment(cases.createdTime).format('MMM DD, YYYY')}
                                                                </Typography> */}
                                                                <Typography className={"case-card-desc"} variant="body2" color="textSecondary" component="p">
                                                                “{cases.description}”
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions className={"case-card-actions"}>
                                                                <Link to={`/CaseStudies/${cases.key}:${cases.title.replace(/\s+/g, '-')}`}>
                                                                    <button className='btn-hyperlink-boxes-small'>
                                                                        {t(`CASESTUDIES_PAGE.Read More`)}
                                                                        <ArrowRightAltIcon></ArrowRightAltIcon>
                                                                    </button>
                                                                </Link>
                                                                {this.state.user ?
                                                                    <div>
                                                                        <IconButton onClick={() => this.onEdit(cases)} color="primary" aria-label="edit">
                                                                            <CreateIcon />
                                                                        </IconButton>
                                                                        <IconButton onClick={() => this.handleDialogOpen(cases.key)} color="secondary" aria-label="delete">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </ div> : null
                                                                }
                                                            </CardActions>
                                                            <CardActions style={{ flexWrap: 'wrap' }}>
                                                                {cases.tag?.length > 0 ? cases.tag.map((x, index) =>
                                                                    <Chip key={index} style={{ marginBottom: '5px' }} size="small" label={x} />
                                                                )
                                                                    :
                                                                    ''}
                                                            </CardActions>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        ) : (this.state.loading ? null :
                                            <div className={'no-cases'}><h3 style={{ color: 'white' }}>{t(`CASESTUDIES_PAGE.No case at the moment, please check back again later, thank you!`)} </h3></div>
                                        )}
                                    </Grid>
                                </div> :
                                <div className='w-100'>
                                    <Grid container className={'blog-text'} alignItems="center" justify="center">
                                        <h1>Case Studies</h1>
                                    </Grid>
                                    <CardCreation
                                        t={t}
                                        uploadState={this.state}
                                        user={this.state.user}
                                        avatarURL={this.state.avatarURL}
                                        errorMessage={this.state.errorMessage}
                                        errMatcher={this.errMatcher}
                                        cases={this.case}
                                        onCancel={this.changeCreateMode}
                                        snackbarOpen={this.handleClick}
                                        handleChangeUsername={this.handleChangeUsername}
                                        handleUploadStart={this.handleUploadStart}
                                        updateCounters={this.updateCounters}
                                        tags={this.state.tags}
                                    />
                                </div>

                        }
                        <SimpleDialog
                            uploadState={this.state}
                            onSaveCrop={this.saveCrop}
                            open={this.state.dialogOpen}
                            onClose={this.handleClose}
                            setEditorRef={this.setEditorRef} />

                        <Dialog
                            open={this.state.deleteDialogOpen}
                            onClose={this.dialogClose}
                        >
                            <DialogTitle>
                                Delete Case Study
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete this case?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => this.handleDialogClose()} color="primary">
                                    No
                                </Button>
                                <Button onClick={() => this.caseDelete(this.state.deletedKey)} color="primary">
                                    Yes, I'm sure
                                </Button>
                            </DialogActions>
                        </Dialog>


                    </Route>

                    <Route path={`/CaseStudies/:caseId`} render={props =>
                        <DetailedCase {...props} />
                    } />
                </Switch>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    message={this.state.message}
                />
                <div className={'modules-contact-us'}>
                    <h3>{t(`CASESTUDIES_PAGE.Want to try out yourself?`)} <br />
                        {t(`CASESTUDIES_PAGE.Get in touch with us today`)}
                    </h3>
                    <Link to="/scheduledemo">
                        <button className={"btn-schedule-demo-action mt-0"}>
                            {t(`CASESTUDIES_PAGE.Schedule Demo`)}
                            <img src={arrowRight} alt="go" className={"arr-demo"} style={{ left: '1.5625vw !important' }} />
                        </button>
                    </Link>
                </div>
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </Grid >
        )
    }
}

export function AddMore(props) {
    const onAddmore = () => {
        props.onClick({ desc: "add" })
    }
    return (
        <div className={'case-addmore-container'}>
            <OButton
                onClick={onAddmore}
                variant="contained"
                color="secondary"
                startIcon={<FaPlus />}
            >  {props.t(`CASESTUDIES_PAGE.Add Case`)}
            </OButton>
        </div>
    )
}

export function CardCreation(props) {
    const baseRef = firestore.collection(`case`);
    const onSubmit = async () => {
        props.cases.createdTime = moment().valueOf()
        props.errMatcher()
        if (props.avatarURL !== "" && props.avatarURL !== null) {
            props.cases.icon = props.avatarURL
        }
        if (props.uploadState.mode === 'edit') {
            baseRef.doc(props.cases.key).update(props.cases)
                .then(res => {
                    props.snackbarOpen("Case Successfully Updated!....")
                    onCancel()
                })
                .catch(err => {
                    if (err) {
                        props.snackbarOpen(err)
                        console.log(err)
                    }
                });
        } else {
            if (props.cases.title !== "" && props.cases.description !== "") {
                baseRef.add(props.cases)
                    .then(async res => {
                        const statsDoc = firestore.doc(`counters/cases`);
                        const countDoc = {};
                        countDoc["count"] = fireCount.increment(1);
                        await statsDoc.set(countDoc, { merge: true });
                        props.updateCounters('add')
                        props.snackbarOpen("Case Successfully Created!....")
                        onCancel()
                    })
                    .catch(err => {
                        if (err) {
                            props.snackbarOpen(err)
                            console.log(err)

                        }
                    })
            }
        }
        document.documentElement.scrollTop = 0;
    }

    const onCancel = () => {
        props.onCancel({ desc: "cancel" })
        document.documentElement.scrollTop = 0;
    }

    const handleChange = res => {
        if (res.field === "description") {
            props.cases.description = res.value
        } else if (res.field === "Title") {
            props.cases.title = res.value
        } else if (res.field === "author") {
            props.cases.createdBy = res.value
        } else if (res.field === "tag") {
            props.cases.tag = res.value
        } else {
            props.cases.content = res.value
        }
    }

    const handleChangeUsername = res => {
        props.handleChangeUsername(res)
    }
    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }


    return (
        <Grid item className={"help-content"}>
            <div className={'login-content'}>
                <form className="form-create-post">
                    <Form
                        t={props.t}
                        uploadState={props.uploadState}
                        error={props.errorMessage}
                        cases={props.cases}
                        onSubmit={onSubmit}
                        onHandle={handleChange}
                        handleChangeUsername={handleChangeUsername}
                        handleUploadStart={handleUploadStart}
                        tags={props.tags}
                    />
                    <div className={"button-container"}>
                        <OButton className={'btn-cancel'} onClick={onCancel} variant="outlined" color="secondary">
                            {props.t(`CASESTUDIES_PAGE.Cancel`)}
                        </OButton>
                        <OButton className={'btn-try'} onClick={onSubmit} variant="outlined" color="primary">
                            {props.uploadState.mode === 'edit' ? 'Update' : 'Create'}
                        </OButton>
                    </div>

                </form>
            </div>

        </Grid>
    )
}

export function Search(props) {
    const classes = useStyles();
    return (
        <Autocomplete
            freeSolo
            id="search"
            classes={classes}
            onChange={(event, newValue) => {
                props.onSearch(newValue)
            }}
            className={"search-case box-shadow-none"}
            options={props.cases}
            getOptionLabel={option => option}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        className={"search-case"}
                        variant="outlined"
                        placeholder={props.t("CASESTUDIES_PAGE.Search case by tag")}
                    />
                );
            }}
        />
    )
}

export function Form(props) {
    const classes = useStyles();

    const [personName, setPersonName] = React.useState(props.cases.tag ? props.cases.tag : []);

    const handleChange = (event) => {
        setPersonName(event.target.value);
        const value = event.target.value
        var field = 'tag'
        props.onHandle({ field, value })
    };

    const onHandled = field => event => {
        if (field === 'tag') {
            const value = event.target.value
            props.onHandle({ field, value })
        } else if (field !== "content") {
            const value = event.currentTarget.value
            props.onHandle({ field, value })
        } else {
            props.cases.content = event
        }
    }

    const handleChangeUsername = res => {
        props.handleChangeUsername(res)
    }

    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    // const names = [
    //     'Industry',
    //     'Engineering & Construction',
    //     'Health & Wellness',
    //     'Non-Profit',
    //     'Professional Services',
    //     'Research & Education',
    //     'Retail & Products',
    //     'Technology & Investment',
    //     'Travel & Tourism'
    // ];

    return (
        <div className={`${classes.root}`} >
            <h3 className={'register-company'}>{props.uploadState.mode === 'edit' ? 'Case' : 'Create Case'}</h3>
            <Upload
                t={props.t}
                uploadState={props.uploadState}
                handleChangeUsername={handleChangeUsername}
                handleUploadStart={handleUploadStart}
            />
            <TextField style={{ width: '100%' }} required id="outlined-basic-title" onChange={onHandled("Title")} defaultValue={props.cases.title} name="title" label="Title" type="text" variant="outlined" /><br></br><br></br>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Tag</InputLabel>
                <Select
                    labelId="mutiple-chip-label"
                    id="mutiple-chip"
                    label="Tag"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                >
                    {props.tags.map((name) => (
                        <MenuItem key={name} value={name}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl><br></br><br></br>
            <TextField inputProps={{ maxLength: 200 }} style={{ width: '100%' }} required id="multiline-basic" onChange={onHandled("description")} defaultValue={props.cases.description} multiline rowsMax={4} name="description" label="Description" type="text" variant="outlined" /><br></br><br></br>
            <TextField style={{ width: '100%' }} required id="outlined-basic-author" onChange={onHandled("author")} defaultValue={props.cases.createdBy} name="author" label="Author" type="text" variant="outlined" /><br></br><br></br>
            <div>
                <EditorToolbar />
                <ReactQuill
                    theme="snow"
                    value={props.cases.content}
                    onChange={onHandled("content")}
                    modules={modules}
                    formats={formats}
                />
                <small>{props.t(`CASESTUDIES_PAGE.Note! :Please only use image tool to upload images, image that exceed more than 10mb will not be uploaded`)}</small>
            </div>
            <span className={'error-message'}>{props.error}</span>
        </div>
    )
}

export function Upload(props) {

    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {props.uploadState.isUploading && <p>{props.t(`CASESTUDIES_PAGE.Progress`)}: {props.uploadState.progress}</p>}
            {props.uploadState.avatarURL && <img alt="icon" src={props.uploadState.avatarURL} />}
            <br />
            <CustomUploadButton
                accept="image/*"
                onChange={handleUploadStart}
                className={'icon-select'}
                maxHeight={640}
                maxWidth={580}
                style={{ backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4 }}
            >
                {props.t(`CASESTUDIES_PAGE.Select your Icon`)}
            </CustomUploadButton>
        </div >
    );

}

export function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default withTranslation()(CaseStudies)