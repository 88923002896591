class Access {
    apiUrlDev = process.env.REACT_APP_APIURLTEMP
    apiUrl = process.env.REACT_APP_APIURL

    async post(url, param) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(param)
        };
        let res = await fetch(this.apiUrl + url, requestOptions)
            .then(response => response.json())
            .then(data => { return data });
        return res
    }

    async get(url, param) {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        let res = await fetch(this.apiUrlDev + url, requestOptions)
            .then(response => response.json())
            .then(data => { return data });
        return res
    }
}

const access = new Access();
export default access