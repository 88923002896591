import React from 'react';
import './index.css'
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import FooterSection from '../../sections/FooterSection';
import { Link } from 'react-router-dom';
import arrowRight from "../../assets/img/arrow-right.png";
import employersMainImg2 from '../../assets/img/employers-main-img2.png'
import employersThumb1 from '../../assets/img/employers-managed-thumb1.png'
import employersThumb2 from '../../assets/img/employers-managed-thumb2.png'
import employersThumb3 from '../../assets/img/employers-managed-thumb3.png'

class EmployersManagedServices extends React.Component {
    state = {}

    componentDidMount() {
        document.documentElement.scrollTop = 0;
    }

    scrollTo = (elementId) => {
        let el = document.getElementById(elementId);
        window.scrollTo({ top: this.getOffset(el).top - 50, left: 0, behavior: 'smooth' });
    };

    getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    render() {
        const { t } = this.props;
        return (
            <Grid id="employers" container className={"employersContainer"}>
                <Helmet>
                    <title>Octomate - {t(`GENERAL.Employers`)} {t(`GENERAL.ManagedServices`)}</title>
                    <meta name="description" content={t("EMPLOYERS_PAGE.DESCPAGE2")} />
                </Helmet>
                <Grid container direction="row" justify="center"
                    alignItems="center" className='employersMainContent'>
                    <Grid className='employersLeftContent' item sm={6} xs={12}>
                        <div>
                            <h1 className='employersTitle'>Employers </h1>
                            <h2 className='employers-m-Title2'> Managed Services </h2>
                            <h3 className='employersubTitle'>
                                Our Talent Management team will fulfil your  recruitment and outsourcing needs, whilst you focus on your core business
                            </h3>
                            <button onClick={() => this.scrollTo('managed')} className='btn-basic-link'>Learn More</button>
                        </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <img className='employers-main-image2' src={employersMainImg2} alt='main' />
                    </Grid>

                </Grid>
                <Grid container id='managed' className='employers-feature-container'>
                    <Grid item xs={12}>
                        <h2 className='text-center employers-feature-title'>Our Managed Service </h2>
                    </Grid>
                    <Grid container className='employers-feature-thumb-container'>
                        <Grid className='employers-feature-thumb-item' item sm={4} xs={12}>
                            <img src={employersThumb1} alt='thumb1' />
                            <h3 className='text-center'>Temporary Project Management​</h3>
                            <ul>
                                <li>
                                    Guaranteed headcount fulfilment
                                </li>
                                <li>
                                    Same day replacement service
                                </li>
                                <li>
                                    24 Hours Hotline Support
                                </li>
                            </ul>
                        </Grid>
                        <Grid className='employers-feature-thumb-item' item sm={4} xs={12}>
                            <img src={employersThumb2} alt='thumb1' />
                            <h3 className='text-center'>Qualified Contractor Outsourcing</h3>
                            <ul>
                                <li>
                                    Expert sourcing & screening
                                </li>
                                <li>
                                    Interview scheduling
                                </li>
                                <li>
                                    Same day onboarding
                                </li>
                            </ul>
                        </Grid>
                        <Grid className='employers-feature-thumb-item' item sm={4} xs={12}>
                            <img src={employersThumb3} alt='thumb1' />
                            <h3 className='text-center'>Professional Placements​</h3>
                            <ul>
                                <li>
                                    Expert sourcing & screening
                                </li>
                                <li>
                                    Professional Candidate profile report
                                </li>
                                <li>
                                    Interview scheduling
                                </li>
                                <li>
                                    Candidate background check
                                </li>
                                <li>
                                    Offer management
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={'modules-contact-us'}>
                    <h3>{t(`MODULE_PAGE.Still have questions? Get in`)} <br />
                        {t(`MODULE_PAGE.touch and share your concerns`)}
                    </h3>
                    <Link to="/contactus">
                        <button className={"btn-schedule-demo-action mt-0"}>
                            {t(`MODULE_PAGE.Contact Us`)}
                            <img src={arrowRight} alt="go" className={"arr-demo"} />
                        </button>
                    </Link>
                </div>
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </Grid>
        )
    }

}

export default withTranslation()(EmployersManagedServices)