import access from './access'

class Download {
    urlJob = "/download/octomateJob"
    urlHR = "/download/octomateHr"
    downloadJob(param) {
        return access.get(this.urlJob, param)
    }

    downloadHR(param) {
        return access.get(this.urlHR, param)
    }
}

const download = new Download()
export default download