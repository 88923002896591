import React from 'react';
import Grid from '@material-ui/core/Grid';

import AccessReadyImage from "../../assets/img/illustration0.png";
import digitalHrImage from "../../assets/img/illustration1.png";
import transactionsImage from "../../assets/img/illustration3.png";
import instantPaymentsImage from "../../assets/img/illustration4.png";
import { withTranslation } from 'react-i18next';
import "./index.css";

class FeaturesSection extends React.Component {

    componentDidMount() {

    }

    render() {
        const { t } = this.props;
        return (
            <Grid id="features" container className={"featuresContainer"}>
                <Grid item xs={12}>
                    <div className={'Feature-Header'}>
                        {t(`HOME_PAGE.About Octomate`)}
                    </div>
                    <div className={'Feature-sub-header'}>
                        {t(`HOME_PAGE.Your Workforce Management Solution`)}
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={"featureItemContainer"}>
                        <div className={"iconAndTitleContainer featureHeaderContainer"}>
                            <img src={AccessReadyImage} alt="digital hr" className={"featuresIcon"} />
                            <div className={"featureHeader"}>
                                <p>{t(`HOME_PAGE.Access a`)} <span className="font-base">{t(`HOME_PAGE.Ready`)}</span> <br />  {t(`HOME_PAGE.and`)} <span className="font-base">{t(`HOME_PAGE.Qualified`)}</span> {t(`HOME_PAGE.Workforce`)}</p>
                            </div>
                        </div>
                        <div className={"featureText"}>
                            <p>{t(`HOME_PAGE.FEATUREDESC1`)}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={"featureItemContainer"}>
                        <div className={"iconAndTitleContainer featureHeaderContainer"}>
                            <img src={digitalHrImage} alt="digital hr" className={"featuresIcon"} />
                            <div className={"featureHeader"}>
                                <p>{t(`HOME_PAGE.Digital HR,`)} <span className="font-base">{t(`HOME_PAGE.Anytime`)}</span> <br />  {t(`HOME_PAGE.and`)} <span className="font-base">{t(`HOME_PAGE.Anywhere`)}</span></p>
                            </div>
                        </div>
                        <div className={"featureText"}>
                            <p>{t(`HOME_PAGE.Workers get access to an intuitive mobile application to clock in or out, submit leave and claims on the go.`)}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={"featureItemContainer"}>
                        <div className={"iconAndTitleContainer featureHeaderContainer"}>
                            <img src={transactionsImage} alt="secure transactions" className={"featuresIcon2"} />
                            <div className={"featureHeader f-2"}>
                                <p><span className="font-base">{t(`HOME_PAGE.Secure`)}</span> {t(`HOME_PAGE.and`)} <br /> <span className="font-base">{t(`HOME_PAGE.Trackable`)}</span> {t(`HOME_PAGE.Transactions`)}</p>
                            </div>
                        </div>
                        <div className={"featureText"}>
                            <p>{t(`HOME_PAGE.All work transactions are stored securely on a blockchain, and can be retrieved and checked real-time.`)}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={"featureItemContainer"}>
                        <div className={"iconAndTitleContainer featureHeaderContainer"}>
                            <img src={instantPaymentsImage} alt="instant payments" className={"featuresIcon3"} />
                            <div className={"featureHeader f-3"}>
                                <p><span className="font-base">{t(`HOME_PAGE.Instant Payments`)}</span> <br />24/7</p>
                            </div>
                        </div>

                        <div className={"featureText"}>
                            <p>{t(`HOME_PAGE.Companies have the option for their workers to access their e-wallet and cash out instantly upon work completion.`)}</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(FeaturesSection)
