import React from 'react';
import { Grid } from '@material-ui/core';
import './index.css'
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';



class RedirectJob extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return window.location.replace("https://play.google.com/store/apps/details?id=com.octux.jobs&hl=en&gl=US")
        }
    
        if (/android/i.test(userAgent)) {
            return window.location.replace("https://play.google.com/store/apps/details?id=com.octux.jobs&hl=en&gl=US")
        }
    
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return window.location.replace("https://apps.apple.com/sg/app/octomate-jobs/id6475540062")
        }
        
        return window.location.replace("https://octomate.us/app-download")
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    render() {
        // const { t } = this.props;
        return (
            <Grid container>
                <Grid container direction="row" justify="center" alignItems="center">
                <div className={'redirect-content'}>
                    <h3 className={'privacy-header'}>Redirecting to Store ...</h3>
                </div>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(RedirectJob)