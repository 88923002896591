import React from 'react';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import upQuote from '../../assets/img/Quote_Left.png'
import downQuote from '../../assets/img/Quote_Right.png'

import people from '../../assets/img/Image@2x.png'
import people2 from '../../assets/img/Image_2@2x.png'
import people3 from '../../assets/img/Image_3@2x.png'
import people4 from '../../assets/img/Image_4@2x.png'
import people5 from '../../assets/img/Image_5@2x.png'
import people6 from '../../assets/img/Image_6@2x.png'
import people7 from '../../assets/img/Image_7@2x.png'


import "./index.css";
import AOS from 'aos';
import { withTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { firestore } from '../../auth/firebase';
import * as moment from 'moment'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class UserReviewSection extends React.Component {
    baseRef = firestore.collection(`case`);
    unsubscribe;
    case = {
        title: "",
        createdTime: "",
        createdBy: "",
        description: "",
        icon: "",
        content: ""
    };
    state = {
        activeStep: 0,
        cases: [],
    }
    componentDidMount() {
        AOS.init({
            duration: 1500,
            mirror: true
        });
        this.getCases(0);
    }

    getCases(i) {
        this.unsubscribe = this.baseRef.orderBy("createdTime", "desc").onSnapshot(snapshot => {
            if (snapshot.docs.length === 0) {
                this.setState({ loading: false });
            }
            i = 3;
            const cases = [];
            this.setState({ lastVisible: snapshot.docs[snapshot.docs.length - 1], firstVisible: snapshot.docs[0] });
            snapshot.forEach((doc) => {
                i += 1;
                const { title, createdTime, createdBy, description, icon, content } = doc.data();
                cases.push({
                    index: i,
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                    content
                });
                this.setState({ tmpCases: cases, allCases: cases, loading: false });
            });
            window.localStorage.setItem("tmp", JSON.stringify(this.state.allCases));
            let limitedCase = this.state.allCases.slice(0, 3);
            let cases2 = this.state.allCases.slice(0, 3);
            let calculatePage = Math.ceil(this.state.allCases.length / 9);
            this.setState({ limitedCase: limitedCase, cases: cases2, pageSize: Array(calculatePage).fill(0, 0, calculatePage) });
        });
        return i;
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    // componentWillReceiveProps() {
    //     AOS.refresh();
    // }

    handleNext = () => {
        if (this.state.activeStep < this.state.cases.length + 2) {
            this.setState({
                activeStep: this.state.activeStep + 1
            })
        }
    }

    handleBack = () => {
        if (this.state.activeStep > 0) {
            this.setState({
                activeStep: this.state.activeStep - 1
            })
        }
    }

    handleStepChange = step => {
        this.setState({
            activeStep: step
        })
    }

    render() {
        const { t } = this.props;
        return (
            // <Grid data-aos="zoom-in" data-aos-duration="1500" container className={"userReviewsContainer"}>
            <Grid container className={"userReviewsContainer"}>
                <Grid item xs={12}>
                    <div data-aos="fade-right" className={"userReviewsHeader"}>
                        <p>{t(`HOME_PAGE.Hear from our clients directly`)}</p>
                    </div>
                    <div className={"people-container"}>
                        <img src={people7} alt="quote" className={'people-small-icon'} />
                        <img src={this.state.activeStep < 3 ? people4 : this.state.cases[this.state.activeStep - 3]?.icon === "" ? people4 : this.state.cases[this.state.activeStep - 3]?.icon} alt="quote" className={'people-medium-mid-icon'} />
                        <img src={people5} alt="quote" className={'people-small-icon'} />
                    </div>
                </Grid>
                <Grid item xs={1} sm={3} className="quoteUpContainer">
                    <div className={'quote-mid'}>
                        <img src={people2} alt="quote" className={'people-very-small-icon'} />
                        <div className={'space-people-mid-left'} />
                    </div>
                    <div className={'quote-bottom'}>
                        <img src={people} alt="quote" className={'people-small-icon'} />
                    </div>
                </Grid>
                <Grid item xs={10} sm={6}>
                    <img src={upQuote} alt="quote" className={'quoteUp'} />
                    <img src={downQuote} alt="quote" className={'quoteDown'} />
                    <AutoPlaySwipeableViews interval={10000} enableMouseEvents index={this.state.activeStep} onChangeIndex={this.handleStepChange}>
                        <div className={"userReviewContainer"}>
                            <div className={"userReviewText"}>
                                <p>{t(`HOME_PAGE.Instant pay is what our workers want. With this solution, we are confident that we will see improved engagement levels together with reduced turnover rates.`)}</p>
                            </div>
                            <div className={"userReviewHeader"}>
                                <p>{t(`HOME_PAGE.Country CEO`)}</p>
                            </div>
                            <div className={"userReviewSubHeader"}>
                                <p>{t(`HOME_PAGE.Fortune 500 Company`)}</p>
                            </div>
                            {/* <div>
                                <Link to="/CaseStudies">
                                    <button className='btn-hyperlink-boxes mt-30'>
                                        {t('HOME_PAGE.Read more about how Octomate has helped!')}
                                    </button>
                                </Link>
                            </div> */}
                        </div>

                        <div className={"userReviewContainer"}>
                            <div className={"userReviewText"}>
                                <p>{t(`HOME_PAGE.The future of cash is digital. By keeping all payroll-related transactions recorded on the blockchain, our ledgers will always be in sync and we keep reconciliation efforts to a minimum.`)}</p>
                            </div>
                            <div className={"userReviewHeader"}>
                                <p>{t(`HOME_PAGE.Head`)}</p>
                            </div>
                            <div className={"userReviewSubHeader"}>
                                {t(`HOME_PAGE.Leading US Bank`)}
                            </div>
                            {/* <div className='mb-10'>
                                <Link to="/CaseStudies">
                                    <button className='btn-hyperlink-boxes mt-30'>
                                        {t('HOME_PAGE.Read more about how Octomate has helped!')}
                                    </button>
                                </Link>
                            </div> */}
                        </div>

                        <div className={"userReviewContainer"}>
                            <div className={"userReviewText"}>
                                <p>{t(`HOME_PAGE.Our processes have been traditionally paper-heavy and error prone. This digital solution is easy to use for our diverse workers and allows us to save a lot of time that were originally spent on manual processing of claims and leave.`)}</p>
                            </div>
                            <div className={"userReviewHeader"}>
                                <p>{t(`HOME_PAGE.HR Manager`)}</p>
                            </div>
                            <div className={"userReviewSubHeader"}>
                                <p>{t(`HOME_PAGE.Local Firm`)}</p>
                            </div>
                            {/* <div>
                                <Link to="/CaseStudies">
                                    <button className='btn-hyperlink-boxes mt-30'>
                                        {t('HOME_PAGE.Read more about how Octomate has helped!')}
                                    </button>
                                </Link>
                            </div> */}
                        </div>

                        {this.state.cases.length > 0 ? this.state.cases.map((cases, index) =>
                            <div key={index} className={"userReviewContainer"}>
                                <div className={"userReviewText"}>
                                    <p>{t(cases.description)}</p>
                                </div>
                                <div className={"userReviewHeader"}>
                                    <p>{t(cases.createdBy)}</p>
                                </div>
                                <div className={"userReviewSubHeader"}>
                                    <p>{t(moment(cases.createdTime).format('MMM DD, YYYY'))}</p>
                                </div>
                                {/* <div>
                                    <Link to={`/CaseStudies/${cases.key}:${cases.title.replace(/\s+/g, '-')}`}>
                                        <button className='btn-hyperlink-boxes mt-30'>
                                            {t('HOME_PAGE.Read more about how Octomate has helped!')}
                                        </button>
                                    </Link>
                                </div> */}
                            </div>
                        ) : <div className={"userReviewContainer"}></div>
                        }

                    </AutoPlaySwipeableViews>
                </Grid>
                <Grid item xs={1} sm={3} className="quoteDownContainer">
                    <div className={'quoteDown-mid'}>
                        <div className={'space-people-mid-rigth'} />
                        <img src={people6} alt="quote" className={'people-very-small-icon'} />
                    </div>
                    <div className={'quoteDown-bottom'}>
                        <img src={people3} alt="quote" className={'people-small-icon'} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={"stepperContainer"}>
                        <MobileStepper
                            variant="dots"
                            steps={this.state.cases.length + 3}
                            position="static"
                            activeStep={this.state.activeStep}
                            nextButton={
                                <div className={this.state.activeStep === this.state.cases.length + 3 ? "disabledStepperButtonRight" : "stepperButtonRight"} onClick={this.handleNext}>
                                    <KeyboardArrowRight />
                                </div>
                            }
                            backButton={
                                <div className={this.state.activeStep === 0 ? "disabledStepperButtonLeft" : "stepperButtonLeft"} onClick={this.handleBack}>
                                    <KeyboardArrowLeft />
                                </div>
                            }
                        />
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(UserReviewSection)
