import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './searchBox.css';


export default function ComboBox(props) {
    return (
        <Autocomplete
            id="article-search"
            freeSolo
            style={{ width: 300 }}
            onChange={(event, newValue) => {
                props.onSearch(newValue)
            }}
            options={props.articles}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
                <TextField {...params} label="Search Articles" margin="normal" variant="outlined" />
            )}
        />
    );
}