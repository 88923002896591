import React from 'react';
import Grid from '@material-ui/core/Grid';

import step1 from '../../assets/img/Use Case 1.gif';
import step2 from '../../assets/img/Use Case 2.gif';
import step3 from '../../assets/img/Use Case 3.gif';
import step4 from '../../assets/img/Use Case 4.gif';
import step5 from '../../assets/img/Use Case 5.gif';

import basicHr from '../../assets/img/basic-hr-light.svg';
import claim from '../../assets/img/claims-light.svg';
import leave from '../../assets/img/leave-light.svg';
import announcement from '../../assets/img/announcement-light.svg';
import roster from '../../assets/img/roster-light.svg';
import timesheet from '../../assets/img/timesheet-light.svg';
import training from '../../assets/img/training-light.svg';
import payroll from '../../assets/img/payroll-light.svg';
import others from '../../assets/img/others-light.svg';

import { withTranslation } from 'react-i18next';
import AOS from 'aos';
import './index.css';
import { Link } from 'react-router-dom';

class FeaturesSection extends React.Component {

    state = {
        step: 1
    };

    componentDidMount() {
        AOS.init({
            duration: 1500,
            mirror: true
        });

    }

    changeStepOnHover = (step) => {
        this.setState({ step });
    };

    render() {
        const { t } = this.props;
        return (
            <Grid id="usecases" container className={'demoContainer'}>
                <Grid container>
                    <Grid item xs={12}>
                        <h2 className={'demoHeaderTitle'}>
                            {t(`HOME_PAGE.How We Can Help`)}
                        </h2>
                        <h2 className={'demoHeaderTitle-2'}>
                            {t(`HOME_PAGE.Our Comprehensive Suite of Modules`)}
                        </h2>
                    </Grid>
                    <div className={'demo-module-container'}>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={basicHr} alt="Basic HR Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Basic HR`)}</p>
                        </Link>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={claim} alt="Claims Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Claims`)}</p>
                        </Link>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={leave} alt="Leave Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Leave`)}</p>
                        </Link>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={announcement} alt="Announcements Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Announcements`)}</p>
                        </Link>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={roster} alt="Roster Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Roster`)}</p>
                        </Link>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={timesheet} alt="Timesheet Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Timesheet`)}</p>
                        </Link>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={training} alt="Training Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Training`)}</p>
                        </Link>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={payroll} alt="Payroll Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Payroll`)}</p>
                        </Link>
                        <Link to={"/Modules"} target="_blank" className={'demo-module-box'}>
                            <img src={others} alt="Others Logo" className={'demo-module-logo'} />
                            <p>{t(`HOME_PAGE.Others`)}</p>
                        </Link>
                    </div>
                </Grid>

                <Grid container className='demoVideoContainer'>
                    <Grid item xs={12}>
                        <h2 className={'demoVideoHeaderTitle'}>
                            {t(`HOME_PAGE.Introducing Our Latest Feature - Instant Claims!`)}
                        </h2>
                    </Grid>
                </Grid>

                <Grid container justify="center" spacing={4}>
                    <Grid item xs={12} sm={6} xl={4} className='responsive-container'>
                    {/* <video
                        className='demoVideo'
                        src='https://ik.imagekit.io/ikmedia/example_video.mp4'
                        poster='https://ik.imagekit.io/ikmedia/example_video.mp4/ik-thumbnail.jpg?tr=w-360,h-360'
                        width='562'
                        height='360'
                        controls
                    /> */}
                    <iframe 
                    className='demoVideo' 
                    width="562"
                    height="360" 
                    src="https://www.youtube.com/embed/linK6SjctPg" 
                    title="Introducing Instant Claims | Octomate" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen="allowfullscreen">

                    </iframe>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <Grid container>
                            <Grid item xs={12} className='responsive-container'>
                                <div className='demoVideoDesc'>
                                    Claims taking weeks? Not anymore! Instant Claims processes them in just 3 seconds. <span role='img' aria-label='emojies'>🕒⚡</span> 
                                    <br></br>
                                    Don’t believe us? Hear from Delia, Cozette, Eugene, and Adeline on how this game-changing feature has transformed their work lives.
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={'demobg'} >
                    <Grid item xs={12}>
                        <h3 className={'demoHeader'}>{t(`HOME_PAGE.Use Case`)}</h3>
                    </Grid>
                    <Grid item xs={12} sm={6} className={'steps'}>
                        <div className={'stepsContainer'}>
                            <div className={this.state.step === 1 ? 'stepsContentActive' : 'stepsContent'}
                                onMouseEnter={() => {
                                    this.changeStepOnHover(1);
                                }}
                                onClick={() => {
                                    this.changeStepOnHover(1);
                                }} >
                                <div className={'stepsNumber'}><p>1</p></div>
                                <div className={'stepsDesc'}><p>{t(`HOME_PAGE.Worker submits work transactions`)}</p></div>
                            </div>
                            <div className={this.state.step === 2 ? 'stepsContentActive' : 'stepsContent'}
                                onMouseEnter={() => {
                                    this.changeStepOnHover(2);
                                }}
                                onClick={() => {
                                    this.changeStepOnHover(2);
                                }} >
                                <div className={'stepsNumber'}><p>2</p></div>
                                <div className={'stepsDesc'}><p>{t(`HOME_PAGE.Manager validates work transaction`)}</p></div>
                            </div>
                            <div className={this.state.step === 3 ? 'stepsContentActive' : 'stepsContent'}
                                onMouseEnter={() => {
                                    this.changeStepOnHover(3);
                                }}
                                onClick={() => {
                                    this.changeStepOnHover(3);
                                }} >
                                <div className={'stepsNumber'}><p>3</p></div>
                                <div className={'stepsDesc'}><p>{t(`HOME_PAGE.Records get stored real-time on the blockchain`)}</p></div>
                            </div>
                            <div className={this.state.step === 4 ? 'stepsContentActive' : 'stepsContent'}
                                onMouseEnter={() => {
                                    this.changeStepOnHover(4);
                                }}
                                onClick={() => {
                                    this.changeStepOnHover(4);
                                }} >
                                <div className={'stepsNumber'}><p>4</p></div>
                                <div className={'stepsDesc'}><p>{t(`HOME_PAGE.Smart contracts compute and triggers payment to e-wallet`)}</p></div>
                            </div>
                            <div className={this.state.step === 5 ? 'stepsContentActive' : 'stepsContent'}
                                onMouseEnter={() => {
                                    this.changeStepOnHover(5);
                                }}
                                onClick={() => {
                                    this.changeStepOnHover(5);
                                }} >
                                <div className={'stepsNumber'}><p>5</p></div>
                                <div className={'stepsDesc'}><p>{t(`HOME_PAGE.Worker withdraws pay instantly`)}</p></div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={'gifContainer'}>
                            {
                                this.state.step === 1 &&
                                <div className="gifDemo">
                                    <img src={step1} alt="step 1" />
                                </div>
                            }
                            {
                                this.state.step === 2 &&
                                <div>
                                    <div className="gifDemo">
                                        <img src={step2} alt="step 2" className={'gifDemo'} />
                                    </div>
                                </div>
                            }
                            {
                                this.state.step === 3 &&
                                <div>
                                    <div className="gifDemo">
                                        <img src={step3} alt="step 3" className={'gifDemo'} />
                                    </div>
                                </div>
                            }
                            {
                                this.state.step === 4 &&
                                <div>
                                    <div className="gifDemo">
                                        <img src={step4} alt="step 4" className={'gifDemo'} />
                                    </div>
                                </div>
                            }
                            {
                                this.state.step === 5 &&
                                <div>
                                    <div className="gifDemo">
                                        <img src={step5} alt="step 5" className={'gifDemo'} />
                                    </div>
                                </div>
                            }
                        </div>
                    </Grid>
                </Grid>

            </Grid>
        );
    }
}

export default withTranslation()(FeaturesSection)