import React from "react";
import { Router } from 'react-router-dom';
import Routes from './App.router'
import history from './services/history'

export const AuthContext = React.createContext(null);

function App() {
  const script = document.createElement("script");

  script.src = "//code.tidio.co/srveexdvmnj2io0av9zminc0k2xfcugp.js";
  script.async = true;

  document.body.appendChild(script);

  return (
    <Router history={history}>
      <Routes />
    </Router>
  )
}

export default App;