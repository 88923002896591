import React from 'react';
import Button from '@material-ui/core/Button';
import './OButton.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const buttonTheme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(75,174,170)',
            contrastText: 'white',
        },
        secondary: {
            main: 'rgb(92,184,178)',
            contrastText: 'white' //button text white instead of black
        },
    }
});

export default class OButton extends React.Component {
    render() {
        return (
            <ThemeProvider theme={buttonTheme}>
                <Button {...this.props} classes={{ root: 'oButton', label: 'oButtonLabel' }} />
            </ThemeProvider>
        );
    }
}