import React from 'react';
import './collectionsArticle.css';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { firebaseAuth, firestore, firebaseStorage } from '../../../auth/firebase';
import TextField from '@material-ui/core/TextField';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import OButton from '../../../components/OButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from "../../../shared/EditorToolbar";
import * as moment from 'moment';
import TypeArticle from '../../../assets/img/type-article.png'
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FaPlus } from "react-icons/fa";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import {
    Link as LinkRouter,
    Switch,
    Redirect
} from 'react-router-dom';
import { Route } from 'react-router';
import DetailArticles from './detailArticles';
import SimpleDialog from "./change-icon-dialog"

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%'
        },
    },
}));

export default class CollectionsArticle extends React.Component {
    post = {
        title: "",
        createdTime: "",
        createdBy: "",
        description: "",
        icon: "",
        content: "",
        categorizeType: "",
        categorizeKey: ""
    }

    state = {
        user: null,
        createMode: false,
        detailedMode: false,
        errorMessage: null,
        open: false,
        vertical: 'top',
        horizontal: 'center',
        avatar: "",
        isUploading: false,
        progress: 0,
        avatarURL: "",
        username: "",
        path: "",
        url: "",
        sortArt: null,
        posts: [],
        post: '',
        category: { title: "" },
        topics: "",
        anchor: false,
        loading: true,
        src: '',
        topicsChange: true,
        dialogOpen:false
    };

    pathnames;
    unsubscribe2;
    unsubscribe;
    unsubscribe3;
    articleRef;
    categoryRef;
    activeTopics;
    setEditorRef = (editor) => this.editor = editor

    componentDidMount() {
        firebaseAuth.onAuthStateChanged(user => {
            this.setState({ user: user });
        })
        const paramKey = this.props.match.params.collectionId.split(":")[0]
        this.articleRef = firestore.collection(`article`);
        this.categoryRef = firestore.collection(`category`);
        this.unsubscribe3 = this.categoryRef.orderBy("createdTime", "desc").limit(25).onSnapshot(snapshot => {
            const topics = []
            snapshot.forEach(doc => {
                const { title, createdTime, createdBy, description, icon } = doc.data();
                topics.push({
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                });
                this.setState({ topics: topics })
            });
        })
        this.unsubscribe2 = this.categoryRef.doc(paramKey).onSnapshot(doc => {
            const docData = doc.data()
            this.setState({ category: doc.data() })
            this.post.categorizeKey = paramKey
            this.post.categorizeType = docData.title
        })

        this.unsubscribe = this.articleRef.where("categorizeKey", "==", paramKey).onSnapshot(snapshot => {
            const articles = [];

            snapshot.forEach((doc) => {
                const { title, createdTime, createdBy, description, icon, content, categorizeKey, categorizeType } = doc.data();
                articles.push({
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                    content,
                    categorizeKey,
                    categorizeType
                });
                this.setState({ posts: articles, loading: false })

            });
            this.setState({ loading: false })
        })
    }

    componentWillUnmount() {
        this.unsubscribe()
        this.unsubscribe2()
        this.unsubscribe3()
    }

    onReadMore = () => {
        // props.onReadMore()
    }

    changeCreateMode = (res) => {
        const { createMode } = this.state
        if (res.desc === 'edit') {
            this.setState({ mode: res.desc, createMode: !createMode, avatar: "", avatarURL: "" })
            this.post = res.post
        } else {
            this.post = {
                title: "",
                createdTime: "",
                createdBy: "",
                description: "",
                icon: "",
                content: "",
                categorizeKey: this.post.categorizeKey,
                categorizeType: this.post.categorizeType
            }
            this.setState({ mode: '', createMode: !createMode, avatar: "", avatarURL: "" })
        }
    }

    breadCrumbsClick = (event) => {
        if (event === "Home")
            return (<Redirect to={"/HelpCenter"} />)
        else
            return
    }

    menuClick = (event) => {
        this.setState({ anchor: event.currentTarget })
    }

    menuClose = () => {
        this.setState({ anchor: null })
    };

    // upload
    handleUploadStart = e => {
        const file = e.currentTarget.files[0]
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, avatar: file.name, dialogOpen: true })
            });
            reader.readAsDataURL(file);
        }
    };

    errMatcher = () => {
        if (this.post.title === "") {
            return this.setState({ errorMessage: "Please fill the title" })
        } else if (this.post.description === "") {
            return this.setState({ errorMessage: "Please fill the description" })
        } else {
            return this.setState(null)
        }
    }

    handleClick = res => {
        this.setState({ open: true, message: res })
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false, dialogOpen: false })
    };

    setArticle = (res) => {
        this.setState({ post: res })
    }

    articleDelete = (res) => {
        var deleteAll = false
        if (this.state.posts.length === 1) {
            deleteAll = true
        }
        this.articleRef.doc(res).delete().then(res => {
            if (deleteAll) {
                this.setState({ posts: [] })
            }
            this.handleClick("Post successfully deleted! ...")
        }).catch(err => {
            if (err) {
                this.handleClick(err)
                console.log(err)
            }
        })
    }

    onEdit = (res) => {
        this.changeCreateMode({ post: res, desc: 'edit' })
    }

    saveCrop = () => {
        if (this.editor) {
            this.editor.getImageScaledToCanvas().toBlob((blob) => {
                const imageKey = moment().unix()
                const uploadTask = firebaseStorage.ref(`/images/${imageKey}`).put(blob)
                uploadTask.on('state_changed',
                    (snapShot) => {

                    }, (err) => {
                        console.log(err)
                    }, () => {
                        firebaseStorage.ref('images').child(imageKey.toString()).getDownloadURL()
                            .then(url => {
                                this.setState({ avatarURL: url, dialogOpen: false })
                            })
                    })
            }, "image/png")
        }
    }

    reload = res => {
        if (res === this.activeTopics) {
            return
        }
        this.setState({ posts: [] })
        this.unsubscribe = this.articleRef.where("categorizeKey", "==", res).onSnapshot(snapshot => {
            const articles = [];
            snapshot.forEach((doc) => {
                const { title, createdTime, createdBy, description, icon, content, categorizeKey, categorizeType } = doc.data();
                articles.push({
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                    content,
                    categorizeKey,
                    categorizeType
                });
                this.setState({ posts: articles, loading: false })
            });
        })
    }

    render() {
        return (
            <Grid className={'w-100'}>
                <Grid item className={'collection-content'} >
                    <div className={'breadcrumbs-container'}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <LinkRouter className={'breadcumb-link'} to={`/HelpCenter`} onClick={() => this.breadCrumbsClick("Home")}>
                                Help Center
                        </LinkRouter>
                            {this.pathnames = window.location.pathname.split('/').filter((x) => x).slice(1)}
                            {
                                this.pathnames.map((value, index) => {
                                    const paths = window.location.pathname.split('/').filter((x) => x)
                                    const lastPath = paths.slice(paths.length - 1)
                                    this.activeTopics = lastPath[0].split(":")[0]
                                    const last = index === this.pathnames.length - 1;
                                    const to = `/${this.pathnames.slice(0, index + 1).join('/')}`;
                                    return last ? (
                                        <LinkRouter to={+to} color="textPrimary" key={to}>
                                            {lastPath[0].split(":")[1] ? lastPath[0].split(":")[1] : lastPath[0].split(":")[0]}
                                        </LinkRouter>
                                    ) : (
                                            <LinkRouter key={"test"} className={'breadcumb-link'} to={`/HelpCenter${to}`} onClick={() => this.breadCrumbsClick()}>
                                                {this.state.category.title}
                                            </LinkRouter>
                                        );
                                })}
                        </Breadcrumbs>
                    </div>
                    <Switch>
                        <Route exact path={`/HelpCenter/:collectionId`}>
                            {this.state.user && !this.state.createMode ? <div className={"add-more"}>
                                <OButton
                                    onClick={() => this.changeCreateMode({ desc: 'add' })}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<FaPlus />}
                                >  Add Posts
                            </OButton>
                            </div> : null}
                            <div className={'help-container'}>
                                <div className={'help-side'}>
                                    <h2>Topics</h2>
                                    <List dense={true}>
                                        {this.state.topics.length > 0 ? this.state.topics.map(topic =>
                                            <ListItem key={topic.key}>
                                                <LinkRouter to={`/HelpCenter/${topic.key}:${topic.title.replace(/\s+/g, '-')}`}>
                                                    <ListItemText onClick={() => this.reload(topic.key)}
                                                        primary={this.activeTopics === topic.key ? topic.title : null}
                                                        secondary={this.activeTopics !== topic.key ? topic.title : null}
                                                    />
                                                </LinkRouter>
                                            </ListItem>
                                        )
                                            : <ListItem>
                                                No Topics Available
                                        </ListItem>}
                                    </List>
                                </div>
                                {!this.state.createMode ?
                                    <div className={'collection-card-container'}>
                                        {this.state.posts.length > 0 ?
                                            this.state.posts.sort((a, b) => {
                                                let c = moment(a.createdTime)
                                                let d = moment(b.createdTime)
                                                return d - c
                                            }).map(post =>
                                                <div key={post.key} className={'card'} onClick={(() => this.setArticle(post))} >
                                                    <LinkRouter to={`/HelpCenter/${this.pathnames}/${post.key}:${post.title.replace(/\s+/g, '-')}`} className={'card-avatar'} >
                                                        <img src={post.icon === "" ? TypeArticle : post.icon} width="100" alt="Article Icon Images" />
                                                    </LinkRouter>

                                                    <LinkRouter to={`/HelpCenter/${this.pathnames}/${post.key}:${post.title.replace(/\s+/g, '-')}`} className={'card-content-article'}>
                                                        <h4>{post.title}</h4>
                                                        <p>{post.description}</p>
                                                    </LinkRouter>
                                                    <LinkRouter to={`/HelpCenter/${this.pathnames}/${post.key}:${post.title.replace(/\s+/g, '-')}`} className={'card-footer-article'}>
                                                        <span> {moment(post.createdTime).format('MMMM DD, YYYY')}</span>
                                                        <span className={'small-footer'}>Written by {post.createdBy}</span>
                                                    </LinkRouter>
                                                    {this.state.user ? <div className={'icon-container-article'}>
                                                        <IconButton onClick={() => this.onEdit(post)} color="primary" aria-label="edit">
                                                            <CreateIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => this.articleDelete(post.key)} color="secondary" aria-label="delete">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div> : null}
                                                </div >
                                            )
                                            : (this.state.loading ? <LinearProgress /> : (!this.state.user ? <div className={'no-post'}><h3 style={{ color: 'gray' }}>No post at the moment, please check back again later, thank you! </h3></div> : null))
                                        }
                                    </div> : <AddPosts
                                        articleRef={this.articleRef}
                                        errMatcher={this.errMatcher}
                                        post={this.post}
                                        mode={this.state.mode}
                                        onCancel={() => this.changeCreateMode({ desc: "cancel" })}
                                        avatarURL={this.state.avatarURL}
                                        isUploading={this.state.isUploading}
                                        progress={this.state.progress}
                                        user={this.state.user}
                                        errorMessage={this.state.errorMessage}
                                        snackbarOpen={this.handleClick}
                                        handleUploadStart={this.handleUploadStart}
                                    />
                                }
                            </div>

                            <SimpleDialog
                                uploadState={this.state}
                                onSaveCrop={this.saveCrop}
                                open={this.state.dialogOpen}
                                onClose={this.handleClose}
                                setEditorRef={this.setEditorRef} />
                        </Route>
                        <Route path={`/HelpCenter/:collectionId/:articleId`} render={props =>
                            <DetailArticles {...props} />
                        } />
                    </Switch>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={4500}
                        onClose={this.handleClose}
                        message={this.state.message}
                    />
                </Grid>
                <Grid className={'help-footer'}>
                    <h2>Can't find what you're looking for?</h2>
                    <Link to={"/"}>
                        <OButton
                            variant="outlined"
                            color="inherit"
                        >
                            <span style={{ color: 'white' }}>Contact Us</span>
                        </OButton>
                    </Link>
                    <div className={'help-copyright'}>
                        <div className="copyright">© Octomate 2020. All rights reserved.</div>
                    </div>

                </Grid>
            </Grid >
        )
    }

}

export function AddPosts(props) {
    const onSubmit = async () => {
        props.errMatcher()
        props.post.createdTime = moment().valueOf()
        if (props.avatarURL !== "" && props.avatarURL !== null) {
            props.post.icon = props.avatarURL
        }
        if (props.post.title !== "" && props.post.description !== "") {
            if (props.mode === 'edit') {
                props.articleRef.doc(props.post.key).update(props.post)
                    .then(res => {
                        props.snackbarOpen("Posts Successfully Updated!....")
                        props.post.content = ""
                        props.onCancel()

                    })
                    .catch(err => {
                        if (err) {
                            props.snackbarOpen("the content size exceed 1mb, please use image tool to upload those copied images")
                            console.log(err)

                        }
                    })
            } else {
                props.articleRef.add(props.post)
                    .then(res => {
                        props.snackbarOpen("Posts Successfully Created!....")
                        props.post.content = ""
                        props.onCancel()

                    })
                    .catch(err => {
                        if (err) {
                            props.snackbarOpen("the content size exceed 1mb, please use image tool to upload those copied images")
                            console.log(err)

                        }
                    })
            }
        }
    }

    const onCancel = () => {
        props.onCancel()
    }

    const handleChange = res => {
        if (res.field === "description") {
            props.post.description = res.value
        } else if (res.field === "Title") {
            props.post.title = res.value
        } else if (res.field === "author") {
            props.post.createdBy = res.value
        } else {
            props.post.content = res.value
        }

    }

    const handleChangeUsername = res => {
        props.handleChangeUsername(res)
    }
    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    return (
        <Grid item className={"help-content-collection"}>
            <div>
                <form className="form-create-post">
                    <Form
                        mode={props.mode}
                        post={props.post}
                        avatarURL={props.avatarURL}
                        isUploading={props.isUploading}
                        progress={props.progress}
                        error={props.errorMessage}
                        categories={props.categories}
                        onSubmit={onSubmit}
                        onHandle={handleChange}
                        handleChangeUsername={handleChangeUsername}
                        handleUploadStart={handleUploadStart}
                    />
                    <div className={"button-container"}>
                        <OButton className={'btn-cancel'} onClick={onCancel} variant="outlined" color="secondary">
                            Cancel
                        </OButton>
                        <OButton className={'btn-try'} onClick={onSubmit} variant="outlined" color="primary">
                            {props.mode === 'edit' ? 'Update' : 'Create'}
                        </OButton>
                    </div>

                </form>
            </div>

        </Grid>
    )
}

export function Form(props) {
    const classes = useStyles();
    const onHandled = field => event => {
        if (field !== "content") {
            const value = event.currentTarget.value
            props.onHandle({ field, value })
        } else {
            props.post.content = event
        }
    }

    const handleChangeUsername = res => {
        props.handleChangeUsername(res)
    }
    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    return (
        <div className={`${classes.root}`} >
            <h3 className={'register-company'}>{props.mode === 'edit' ? 'Posts' : 'Create Posts'}</h3>
            <Upload
                avatarURL={props.avatarURL}
                isUploading={props.isUploading}
                progress={props.progress}
                handleChangeUsername={handleChangeUsername}
                handleUploadStart={handleUploadStart}
            />
            <TextField style={{ maxWidth: 430 }} required id="outlined-basic" onChange={onHandled("Title")} defaultValue={props.post.title} name="title" label="Title" type="text" variant="outlined" />
            <TextField style={{ maxWidth: 430 }} required id="multiline-basic" onChange={onHandled("description")} defaultValue={props.post.description} multiline rowsMax={4} name="description" label="Description" type="text" variant="outlined" />
            <TextField style={{ maxWidth: 430 }} required id="outlined-basic-author" onChange={onHandled("author")} defaultValue={props.post.createdBy} name="author" label="Author" type="text" variant="outlined" />
            <div>
                <EditorToolbar />
                <ReactQuill
                    theme="snow"
                    value={props.post.content}
                    onChange={onHandled("content")}
                    modules={modules}
                    formats={formats}
                />
                <small>Note! :Please only use image tool to upload images, image that exceed more than 10mb will not be uploaded</small>
            </div>
            <span className={'error-message'}>{props.error}</span>
        </div>
    )
}

export function Upload(props) {

    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {props.isUploading && <p>Progress: {props.progress}</p>}
            {props.avatarURL && <img className={'img-preview'} alt="icon" src={props.avatarURL} />}
            <br />
            <CustomUploadButton
                accept="image/*"
                storageRef={firebaseStorage.ref('images')}
                onChange={handleUploadStart}
                className={'icon-select'}
            >
                Select your Icon
            </CustomUploadButton>
        </div >
    );
}