import * as firebase from 'firebase';

var firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASECONFIG);

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const firebaseAuth = firebaseApp.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();
export const fireCount = firebase.firestore.FieldValue;
export const firebaseStorage = firebase.storage();