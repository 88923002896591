import access from './access'

class ContactUs {
    url = "/contactus"
    subscriber = "/contactus/subscribe"
    post(param) {
        return access.post(this.url, param)
    }

    subscribe(param) {
        return access.post(this.subscriber, param)
    }
}

const contactus = new ContactUs()
export default contactus