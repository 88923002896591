import React from 'react';
import './pdp.css'
import logo from '../../assets/img/logo-white.png';
import back from '../../assets/img/Back.svg';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
class PersonalDataProtection extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container className={'privacy'}>
                <Helmet>
                    <title>Octomate - {t(`PERSONALDATA_PAGE.Personal Data Protection`)}</title>
                    <meta name="description" content={t("PERSONALDATA_PAGE.The following paragraphs explain how your personal data will be treated. This policy may be updated from time to time.")} />
                </Helmet>
                <img src={logo} className={'privacy-logo'} alt="octomate" />
                <div className={'privacy-content'}>
                    <div className={'privacy-content-scroll'}>
                        <h3 className={'privacy-header'}>{t(`PERSONALDATA_PAGE.Personal Data Protection`)}</h3>
                        <h4 className={'text-center'}><a href="/privacy" className={'privacy-sub-header'}>{t(`PERSONALDATA_PAGE.Our Policy`)}</a></h4>
                        <p className={'text-center'}>{t(`PERSONALDATA_PAGE.Octomate Pte Ltd Data Protection Complaints Procedure`)}</p> <br />
                        <p className={'text-center'}> {t(`PERSONALDATA_PAGE.Octomate Pte Ltd and its subsidiaries/affliates (“Octomate”) is committed to handle personaldata in compliance with the Personal Data Protection Act 2012 (Act 26 of 2012) (“PDPA”) standards. We aim to deal promptly and efficiently with any concerns relating to Octomate’scollection, use, disclosure and process of personal data and/or non-compliance with the Octomate’s data protection complaints procedure.`)}</p> <br />
                        <p className={'text-center'}>
                           {t(`PERSONALDATA_PAGE.If you have any questions about your data protection rights, get in touch with us at`)} <a className={'blueish'} href="mailto: hello@octomate.us">hello@octomate.us</a>
                        </p><br />
                        <ol>
                            <li>
                                <h4>{t(`PERSONALDATA_PAGE.Types of Complaints Considered`)}</h4>
                                <p>
                                    {t(`PERSONALDATA_PAGE.All complaints about Octomate’s collection, use, disclose and processing of personal data will be handled in accordance with the procedure as set out below. Some examples of the types of concerns likely to be raised include (without limitation to) :`)}
                                </p>
                                <br />
                                <ul>
                                    <li>{t(`PERSONALDATA_PAGE.Misuse of your personal data`)}</li>
                                    <li>{t(`PERSONALDATA_PAGE.Unauthorised access to your personal data`)}</li>
                                    <li>{t(`PERSONALDATA_PAGE.Loss of your personal data`)}</li>
                                </ul>
                                <p>{t(`PERSONALDATA_PAGE.If the complaint falls outside of the scope of this complaints procedure, you will be notified ofthe most appropriate process to be followed.`)}

                                </p>
                                <p>{t(`PERSONALDATA_PAGE.A request to have access to information held about you will be handled in accordance with the Privacy Statement.`)}

                                </p>
                            </li>
                            <li className="font-bold">
                                <p className="font-bold"> {t(`PERSONALDATA_PAGE.Your Rights under the Personal Data Protection Act`)}</p>
                            </li>
                            <p>{t(`PERSONALDATA_PAGE.The PDPA gives individuals the right to know what information is held about them and how the information is being used; the right to have personal information rectified if it has been recorded incorrectly or the information held is no longer up to date; or the right to request that the information is destroyed and no longer processed.`)}

                            </p>
                            <br />
                            <li className="font-bold">
                                <p className="font-bold">  {t(`PERSONALDATA_PAGE.Lodging a Complaint`)}</p>
                            </li>
                            <p>{t(`PERSONALDATA_PAGE.Our approach is to engage positively and resolve your complaint satisfactorily without you having to refer your complaint to an external regulator or court.`)}

                            </p>
                            <p>{t(`PERSONALDATA_PAGE.If you have any concerns or problems with the way in which your personal data has been processed, you should not hesitate to raise your concern to the Data Protection Officer`)}

                            </p>
                            <p>at <a className="blueish" href="mailto: hello@octomate.us">hello@octomate.us</a> {t(`PERSONALDATA_PAGE.To help us to deal with your complaint, please provide a full written explanation of your concerns.`)}
                            </p>
                            <br />
                            <li className="font-bold"><p className="font-bold"> {t(`PERSONALDATA_PAGE.Our Complaints Procedure`)}</p></li>
                            <p>{t(`PERSONALDATA_PAGE.At the time of receiving your complaint, the Data Protection Officer will confirm that your complaint will be investigated and will give you an estimate of how long you should expect to wait to receive a full response. Response times will likely to vary depending on the natureof the complaint, although we endeavour to respond as promptly as possible.`)}

                            </p>
                            <p>{t(`PERSONALDATA_PAGE.The Data Protection Officer will liaise with the relevant departments to investigate your complaint and will confirm the outcome to you in writing, reminding you of your right of appeal where your complaint has been rejected.`)}

                            </p>
                            <p>{t(`PERSONALDATA_PAGE.Alternatively, the response letter will set out the action(s) to be taken where your complaint has been upheld.`)}</p>
                            <br />
                            <li className="font-bold"><p className="font-bold">  {t(`PERSONALDATA_PAGE.Appeal Process`)} </p></li>
                            <p>{t(`PERSONALDATA_PAGE.If you are in the opinion that your complaint has not been resolved satisfactorily, you may appeal to the General Manager who will review your complaint and reach a decision based on its findings upon receipt of your notification of appeal.`)}

                            </p>
                            <p>{t(`PERSONALDATA_PAGE.If this complaints procedure does not resolve your complaint to your satisfaction, you may then seek further recourse by contacting the Personal Data Protection Commission of Singapore.`)}

                            </p>
                            <br />

                            <li className="font-bold"><p className="font-bold">  {t(`PERSONALDATA_PAGE.Data Deletion Request`)} </p></li>
                            <p>
                                {t(`PERSONALDATA_PAGE.If you would like to delete your personal data from our system, please fill up the form `)}
                                <a className="blueish" target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/15nPnKIpTOe-jVJDj3pGh8ni3phv_3TEVUi7GKq2p0kM/edit">here </a>
                                {t(`PERSONALDATA_PAGE.and we will process your request within 5 working days.`)}
                            </p>
                        </ol>
                    </div>
                </div>
                <div className={"my-auto"}>
                    <a href="/">
                        <button className={'back-button'}>
                            <img src={back} className={'back-icon'} alt="back icon" />
                            {t(`PERSONALDATA_PAGE.Back To Website`)}
                        </button>
                    </a>
                </div>
            </Grid>
        )
    }
}

export default withTranslation()(PersonalDataProtection)