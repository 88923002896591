import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import img1 from '../../assets/img/Group.png';
import TextField from '@material-ui/core/TextField';
import validator from "validator";
import { motion } from 'framer-motion';
import './index.css';
import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import contactus from '../../services/http/contactus.service';
import * as moment from 'moment';
import { withTranslation } from 'react-i18next';



class LandingSection extends React.Component {

    state = {
        email: "",
        name: "",
        errorField: null,
        errorFieldName: null,
        success: false,
        formType: null,
        openDialog: false,
    }


    componentDidMount() {
        window.localStorage.removeItem("email")
        AOS.init({
            duration: 1500,
            mirror: true
        });
    }

    handleChange = field => event => {
        this.setState({ [field]: event.target.value });
    };

    handleOpen = () => {
        this.setState({ openDialog: true });
    };

    handleClose = () => {
        this.setState({ openDialog: false });
    };

    onSubmit = () => {
        if (this.state.name === '')
            this.setState({ errorFieldName: 'name' });
        if (!validator.isEmail(this.state.email)) {
            this.setState({ errorField: 'email' });
        }
        else {
            this.setState({ errorFieldName: null });
            this.setState({ errorField: null });
            const param = {
                firstName: this.state.name,
                email: this.state.email,
                remarks: 'Request for trial',
                isCompanyWebsite: true
            }
            let timer = window.localStorage.getItem("trial")
            if (timer) {
                let parsedTimer = JSON.parse(timer)
                let today = moment()
                let diff = today.diff(parsedTimer.time, 'seconds')
                if (diff >= 50) {
                    window.localStorage.removeItem("trial")
                    this.submit(param);
                }
            } else {
                this.submit(param);
            }
        }
    };


    submit(param) {
        contactus.post(param)
            .then(res => {
                let time = moment();
                window.localStorage.setItem("trial", JSON.stringify({ time: time, success: true }));
                this.handleOpen();
            })
            .catch(err => {
                this.setState({ success: false });
            });
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container id="landing" className="landingSectionContainer">
                <Grid item xs={12} sm={6} md={6} className={'landingPageImageSmallContainer'}>
                    <div className={'landingPageImageSmall'}>
                        <LandingPageMainImage />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="landingPageTextContainer">
                    <h1 className="landingPageHeading">{t('HOME_PAGE.Manage Your')}
                        <br></br>
                        <span className="font-base">{t('HOME_PAGE.Desk')}</span> {t(`HOME_PAGE.And`)} <span className="font-base">{t(`HOME_PAGE.Desk-less`)}</span>
                        <br></br> {t(`HOME_PAGE.Workforce Anywhere`)}
                    </h1>
                    <div className="landingPageBodyContainer">
                        <div className="landingPageBody">
                            {t(`HOME_PAGE.LANDINGDESCRIPTION`)}
                        </div>
                        <div className="d-flex flex-wrap">
                            <FormName t={t} onSubmit={this.onSubmit} error={this.state.errorFieldName} onHandle={this.handleChange} />
                            <Form t={t} onSubmit={this.onSubmit} error={this.state.errorField} onHandle={this.handleChange} />
                            <button onClick={() => this.onSubmit()} className={"btn-get-started responsive-trial"}>
                                {t(`HOME_PAGE.Start Free Trial`)}
                            </button>
                            <Modal
                                open={this.state.openDialog}
                                onClose={this.handleClose}
                                className={"dialog-container"}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <div className={"dialog-content"}>
                                    <h2 className={'dialog-title'}>{t(`Successfully registered`)}</h2>
                                    <p className={'dialog-description'}>
                                        {t(`MULTIUSED_POPUP.SUCCESSMESSAGEFORREGISTERING`)}
                                    </p>
                                    <Button onClick={() => this.handleClose()} variant="contained" size="medium" className={'dialog-button'} >
                                        {t(`GENERAL.OK`)}
                                    </Button>
                                </div>

                            </Modal>
                        </div>
                        {/* <p className='m-0 trial-desc'>
                            {t(`HOME_PAGE.Try Octomate for 30 days - free! No credit card required.`)}
                        </p> */}
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} className={'landingPageImageLargeContainer'}>
                    <div className={'landingPageImageLarge'}>
                        <LandingPageMainImage />
                    </div>
                </Grid>
            </Grid>
        );

    }

}


export function Form(props) {
    return (
        <TextField type='email' className={'subcribe email-field mr-15 border-5'} required error={props.error === 'email' ? true : false} onChange={props.onHandle("email")} id="outlined-basic" placeholder={props.t("GENERAL.EMAILADDRESS")} variant="outlined" />
    )
}

export function FormName(props) {
    return (
        <TextField type='text' className={'subcribe name-field mr-15 border-5'} required error={props.error === 'name' ? true : false} onChange={props.onHandle("name")} id="outlined-basic" placeholder={props.t("GENERAL.NAME")} variant="outlined" />
    )
}


const LandingPageMainImage = () => {
    return (
        <div>
            <div className={"landingPageAnimationImage"}>
                <motion.img
                    key={img1}
                    src={img1}
                    alt="Clock Images"
                    initial={{ opacity: 1, y: -125, x: -100 }}
                    animate={{ opacity: 1, y: -125, x: -100 }}
                    transition={{ duration: 0.5 }}
                    className={"mainLandingImage"}
                />
            </div>
        </div>
    );
};

export function LandingPageOtherImage(props) {
    const [isToggled, setToggle] = useState(false);
    const [isInitial, setInitial] = useState(false);

    const toggleAnimation = () => {
        setTimeout(() => {
            if (isInitial) {
                setInitial(!isInitial);
            }
            setToggle(!isToggled);
        }, 10);
    };

    const variants = {
        initial: {
            opacity: 1,
            y: props.y,
            x: props.x
        },
        active: {
            y: props.y,
            opacity: 1,
            transition: { duration: 0.5 }
        },
        inActive: {
            y: props.y,
            opacity: 1,
            transition: { duration: 0.5 + Math.random() * 0.5 }
        }
    };
    return <div data-depth="0" className={'landingPageAnimationImage'}>
        <motion.img
            key={props.img}
            src={props.img}
            alt={props.alt}
            initial={{ opacity: 1, y: props.y, x: props.x }}
            animate={!isInitial ? (isToggled ? 'active' : 'inActive') : 'initial'}
            onAnimationComplete={toggleAnimation}
            transition={{ duration: props.duration }}
            style={{ width: props.w }}
            variants={variants}
        />

    </div>;
}

export default withTranslation()(LandingSection)