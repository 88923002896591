import React from 'react';
import './contactUs.css'
import logo from '../../assets/img/logo-white.png';
import back from '../../assets/img/Back.svg';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import validator from "validator";
import checkmark from '../../assets/img/checkmark.png';
import * as moment from 'moment';
import contactus from '../../services/http/contactus.service';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            width: '100%',
            color: "#5cb8b2 !important"
        },

    },
    noMargin: {
        marginBottom: '0 !important'
    }
}));

class ContactUs extends React.Component {

    state = {
        firstName: "",
        lastName: "",
        email: "",
        remarks: "",
        errorField: null,
        success: false,
    }

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;
        let timer = window.localStorage.getItem("timer")
        if (timer) {
            let parsedTimer = JSON.parse(timer)
            let today = moment()
            let diff = today.diff(parsedTimer.time, 'seconds')
            if (diff >= 50) {
                window.localStorage.removeItem("timer")
                this.setState({ success: false })
            } else {
                this.setState({ success: parsedTimer.success })
            }
        }
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    handleChange = field => event => {
        this.setState({ [field]: event.target.value });

    };

    onSendMessage = () => {
        if (this.state.firstName === '') {
            this.setState({ errorField: 'firstName' });
        }
        else if (this.state.lastName === '') {
            this.setState({ errorField: 'lastName' });
        }
        else if (!validator.isEmail(this.state.email)) {
            this.setState({ errorField: 'email' });
        }
        else if (this.state.remarks === '') {
            this.setState({ errorField: 'remarks' });
        } else {
            const param = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                remarks: this.state.remarks,
                isCompanyWebsite: true
            }
            contactus.post(param)
                .then(res => {
                    let time = moment()
                    window.localStorage.setItem("timer", JSON.stringify({ time: time, success: true }))
                    this.setState({ success: true })
                })
                .catch(err => {
                    this.setState({ success: false })
                })
        }
    };

    render() {
        const { t } = this.props;
        return (
            <Grid container className={'privacy'}>
                <Helmet>
                    <title>Octomate - {t(`CONTACTUS_PAGE.Contact Us`)}</title>
                    <meta name="description" content={t(`CONTACTUS_PAGE.The following pages allow anyone to contact us by email.`)} />
                </Helmet>
                <img src={logo} className={'privacy-logo'} alt="octomate" />
                {
                    this.state.success ?
                        <div className={`contact-success-container`}>
                            <img className={"checkmark-icon"} src={checkmark} alt="checkmark icon" />
                            <h3 className={"contact-success"}>{t(`CONTACTUS_PAGE.Thank You For Your Submission!`)}</h3>
                            <div className={"box-white-bg"}>
                                <span>{t(`CONTACTUS_PAGE.We will get back to you as soon as we can.`)}</span>
                            </div>
                        </div>
                        :
                        <div className={`form-demo mt-60 form-contactus`}>
                            <Form t={t} error={this.state.errorField} email={this.state.email} onHandle={this.handleChange} />
                            <button className={'btn-try-demo'} onClick={() => this.onSendMessage()} >
                                {t(`CONTACTUS_PAGE.Send Message`)}
                            </button>
                        </div>
                }


                <div className={"my-auto"}>
                    <a href="/">
                        <button className={'back-button'}>
                            <img src={back} className={'back-icon'} alt="back icon" />
                            {t(`CONTACTUS_PAGE.Back To Website`)}
                        </button>
                    </a>
                </div>
            </Grid>
        )
    }


}

export function Form(props) {
    const classes = useStyles();

    return (
        <div className={`${classes.root} form-content`} >
            <h3 className={'register-company-demo'}>{props.t(`CONTACTUS_PAGE.Contact Us`)}</h3>
            <div className={'column-2 d-flex justify-space-between'}>
                <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={props.error === 'firstName' ? true : false}
                    helperText={props.error === 'firstName' ? props.t('GENERAL.Please fill in your first name') : ''}
                    onChange={props.onHandle("firstName")}
                    id="outlined-basic"
                    label={props.t("GENERAL.First Name")}
                    variant="standard"
                    placeholder="John" />
                <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={props.error === 'lastName' ? true : false}
                    helperText={props.error === 'lastName' ? props.t('GENERAL.Please fill in your last name') : ''}
                    onChange={props.onHandle("lastName")}
                    id="outlined-basic"
                    label={props.t("GENERAL.Last Name")}
                    variant="standard"
                    placeholder="Anderson" />
            </div>

            <TextField
                required
                InputLabelProps={{ shrink: true }}
                error={props.error === 'email' ? true : false}
                helperText={props.error === 'email' ? props.t('GENERAL.Please fill in your valid email') : ''}
                onChange={props.onHandle("email")}
                id="outlined-basic"
                label={props.t("GENERAL.Email")}
                value={props.email}
                type="email"
                variant="standard"
                placeholder="example@yahoo.com" />
            <TextField
                multiline
                rows={4}
                className={classes.noMargin}
                InputLabelProps={{ shrink: true }}
                helperText={props.error === 'remarks' ? props.t('GENERAL.Please fill in your message') : ''}
                onChange={props.onHandle("remarks")}
                id="outlined-basic"
                label={props.t("GENERAL.Message")}
                variant="standard"
                placeholder={props.t(`CONTACTUS_PAGE.Type your message here`)} />
        </div>
    )
}

export default withTranslation()(ContactUs)