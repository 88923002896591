import React from 'react';
import './detailCase.css';
import { Grid } from '@material-ui/core';
import { firestore, } from '../../../auth/firebase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import searchIcon from '../../../assets/img/Search.svg';
// import Typography from '@material-ui/core/Typography';
import breadcrumbIcon from '../../../assets/img/breadcrumb-icon.png'
import { Link } from 'react-router-dom';
// import * as moment from 'moment'
import history from "../../../services/history";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    inputRoot: {
        '&[data-focus="true"]': {
            backgroundColor: '#fff !important',
            borderColor: 'transparent !important',
        },
        '&[aria-selected="true"]': {
            backgroundColor: '#fff !important',
            borderColor: 'transparent !important',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        }
    },
    clearIndicator: {
        marginRight: "150px"
    },
}));

export default class DetailedCase extends React.Component {
    baseRef;
    unsubscribe
    unsubscribe2
    cases;
    activeIndex;
    nextCase;
    previousCase;
    state = {
        cases: [],
        case: "",
        posts: [],
        topics: []
    }

    createMarkup = (res) => {
        return { __html: res };
    }

    componentDidMount() {
        this.getCase("init");
    }

    getCase = (type) => {
        this.setState({ case: "" })
        let paramKey = window.location.pathname.split('/')[2].split(":")[0]
        // if (this.previousCase !== null && type === "previous")
        //     paramKey = this.previousCase.key
        // if (this.nextCase !== null && type === "next")
        //     paramKey = this.nextCase.key
        // this.nextCase = null
        // this.previousCase = null
        document.documentElement.scrollTop = 0;
        this.baseRef = firestore.collection(`case`)
        this.unsubscribe2 = this.baseRef.doc(paramKey).onSnapshot(doc => {
            if (this.state.case === "") {
                this.cases = JSON.parse(window.localStorage.getItem("tmp"))
                // this.activeIndex = (this.cases.filter(x => x.key === paramKey)[0]?.index)
                // if (this.activeIndex === undefined) return window.location.href = '/CaseStudies'
                // if ((this.activeIndex) !== this.cases.length) {
                //     this.nextCase = this.cases.filter(x => x.index === (this.activeIndex + 1))[0]
                // }
                // if (this.activeIndex !== 1) {
                //     this.previousCase = this.cases.filter(x => x.index === (this.activeIndex - 1))[0]
                // }

                return this.setState({ case: doc.data() });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe2()
    }

    handleClick = () => {
        this.setState({ case: "" })
        const paramKey = window.location.pathname.split('/')[3].split(":")[0]
        this.unsubscribe2 = this.baseRef.doc(paramKey).onSnapshot(doc => {
            if (this.state.case === "") {
                return this.setState({ case: doc.data() });
            }
        });
    }

    onSearch = (value) => {
        history.push(`/CaseStudies/${value.key}:${value.title}`)
        this.getCase("init")
    }

    render() {
        return (
            <Grid container className={"mb-150"}>
                 <Grid container className={'blog-text'} alignItems="center" justify="center">
                    <h1>{this.state.case?.title}</h1>
                </Grid>
                <Grid container alignItems="center" justify="center">
                    <Search cases={this.cases} onSearch={this.onSearch} />
                    <img src={searchIcon} alt="search-icon" className={'detailed-search-icon'} />
                </Grid>
                <Grid container className={"py-case-170 justify-space-between mt-50"} direction="row">
                    <Grid item md={12} sm={12} xs={12} className={'detailed-case-container'}>
                        <div className={"detailed-case-breadcrum-container"}>
                            <Link className={"detailed-case-breadcrum-links"} to={"/CaseStudies"}>Case Studies</Link>
                            <img src={breadcrumbIcon} width="16" alt="breadcrumb" />
                            <span className={"detailed-case-active-breadcrum"}>{this.state.case?.title}</span>
                        </div>
                        <div key={this.state.case?.key} className={'detailed-case ql-editor'} >
                            <div className={'detailed-case-content'} >
                                <h2>{this.state.case?.title}</h2>
                                {/* <Typography className={"detailed-case-card-date"} variant="subtitle2" component="small">
                                    By {this.state.case?.createdBy} {moment(this.state.case?.createdTime).format('MMM DD, YYYY')}
                                </Typography> */}
                                <div className={'detailed-case-detailed-content mt-30'} dangerouslySetInnerHTML={this.createMarkup(this.state.case?.content)}></div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export function Search(props) {
    const classes = useStyles();
    return (
        <Autocomplete
            freeSolo
            id="search"
            classes={classes}
            onChange={(event, newValue) => {
                props.onSearch(newValue)
            }}
            className={"detailed-search-case box-shadow-none"}
            options={props.cases}
            disableClearable
            getOptionLabel={option => option.title}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        className={"detailed-search-case"}
                        variant="outlined"
                        placeholder="Search Case"
                    />
                );
            }}
        />
    )
}