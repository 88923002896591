import React from 'react';

import arrowRight from "../../assets/img/arrow-right.png";
import './index.css';

import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class CallToActionSection extends React.Component {

    componentDidMount() {

    }

    render() {
        const { t } = this.props;
        return (
            <Grid container className="callToActionSection">
                <Grid  item  className="callToActionContainer">
                    <div className="callToActionChild heading font"><p>{t(`HOME_PAGE.Still not convinced?`)}<br /> {t(`HOME_PAGE.Book a demo with us today.`)}</p></div>
                </Grid>
                <Grid item>
                    <div className="callToActionChild">
                        <Link to="/scheduledemo">
                            {/* <OButton
                                variant="outlined"
                                color="inherit"
                                onClick={() => this.props.openFormModal('try')}
                            >
                                <span style={{ color: 'white' }}>Schedule Demo</span>
                            </OButton> */}
                            <button className={"btn-schedule-demo-action"}>
                                {t(`GENERAL.Schedule Demo`)} 
                                <img src={arrowRight} alt="go" className={"arr-demo"} />
                            </button>
                        </Link>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation()(CallToActionSection)
