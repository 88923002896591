import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import './modules.css'
import basicHr from '../../assets/img/basic-hr-light.svg';
import claim from '../../assets/img/claims-light.svg';
import leave from '../../assets/img/leave-light.svg';
import announcement from '../../assets/img/announcement-light.svg';
import roster from '../../assets/img/roster-light.svg';
import timesheet from '../../assets/img/timesheet-light.svg';
import training from '../../assets/img/training-light.svg';
import payroll from '../../assets/img/payroll-light.svg';
import others from '../../assets/img/others-light.svg';
import overview from '../../assets/img/overview-light.svg';
import userDesktopLogin from '../../assets/img/UserDesktopLogin.png';
import userMobileLogin from '../../assets/img/UserMobileLogin.png';
import managerMobileView from '../../assets/img/ManagerMobileView.png';

import ComprehensiveEmployeeData from '../../assets/img/ComprehensiveEmployeeData.png';
import MultipleAssignmentsPerUser from '../../assets/img/MultipleAssignmentsPerUser.png';
import AutomatedSystemReminders from '../../assets/img/AutomatedSystemReminders.png';

import SeamlessClaimSubmissionviaOCR from '../../assets/img/ModuleClaims/1.png';
import EasyEntitlementTracking from '../../assets/img/ModuleClaims/2.png';
import DuplicateClaimFlag from '../../assets/img/ModuleClaims/3.png';

import LeaveSubmissionwithPresetRequirements from '../../assets/img/ModuleLeave/1.png';
import SharedLeaveCalendar from '../../assets/img/ModuleLeave/2.png';
import IntegratedwithRosterTimesheet from '../../assets/img/ModuleLeave/3.png';

import OrganizedFoldersforEasyReading from '../../assets/img/ModuleAnnouncement/1.png';
import WithAttachmentsandAcknowledgements from '../../assets/img/ModuleAnnouncement/2.png';
import RealTimeStatisticsTracking from '../../assets/img/ModuleAnnouncement/3.png';

import IntuitiveDigitalRoster from '../../assets/img/ModuleRoster/1.png';
import EasyAvailabilitySubmission from '../../assets/img/ModuleRoster/2.png';
import SelfServiceRosterFunction from '../../assets/img/ModuleRoster/3.png';

import AccurateGPSCapturewithSelfie from '../../assets/img/ModuleTimesheet/1.png';
import DigitalTimesheetSubmission from '../../assets/img/ModuleTimesheet/2.png';
import AutomaticOvertimeComputation from '../../assets/img/ModuleTimesheet/3.png';

import TrainingCourseOverview from '../../assets/img/ModuleTraining/1.png';
import RefresherQuizzesontheGo from '../../assets/img/ModuleTraining/2.png';
import FullyConfigurableFields from '../../assets/img/ModuleTraining/3.png';

import InstantPayTransactions from '../../assets/img/ModulePayroll/1.png';
import EasyAccesstoEncryptedPayslip from '../../assets/img/ModulePayroll/2.png';
import MassEPayslipDissemination from '../../assets/img/ModulePayroll/3.png';

import InAppSupport from '../../assets/img/ModuleOthers/1.png';
import StepbyStepFAQGuide from '../../assets/img/ModuleOthers/2.png';
import PresetConfigurationWorkbooksUATScripts from '../../assets/img/ModuleOthers/3.png';

import arrowRight from "../../assets/img/arrow-right.png";
import FooterSection from '../../sections/FooterSection';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Modules extends React.Component {

    overview =
        {
            contents: [
                {
                    title: 'User Friendly',
                    desc: 'Know exactly where to click even without a user guide – that’s how we designed the solution – with clean and intuitive interface that users can start using without any training.'
                },
                {
                    title: 'Comprehensive Suite of Modules',
                    desc: 'Use a seamless solution that provide efficiency throughout the employee lifecycle – from hiring to onboarding to lifecycle management.'
                },
                {
                    title: 'Advanced Security',
                    desc: 'Stay protected with secured SSL encryption, automatic backups, proactive malware preventions, and security penetration testing from established providers.'
                },
                {
                    title: 'Accessible Anytime, Anywhere',
                    desc: 'Use the cloud-based solution from your web or mobile devices, with fully native Android and IOS applications and features such as Fingerprint / Face ID Login.'
                },
            ],
            thumbnails: [
                {
                    images: userDesktopLogin,
                    desc: 'User Desktop Login',
                    backgroundColor: "#e9f0fb"
                },
                {
                    images: userMobileLogin,
                    desc: 'User Mobile Login',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: managerMobileView,
                    desc: 'Manager Mobile View',
                    backgroundColor: "#e9f0fb"
                }
            ]
        }

    basichr =
        {
            contents: [
                {
                    title: 'Centralized Employee Data',
                    desc: 'Store all your employee data in one place, with easy access to reports and analytics on key statistics e.g. headcount cost center, demographics and ratios.'
                },
                {
                    title: 'Flexible Data Hierarchy',
                    desc: 'Set up employees easily, with ability to have them work on multiple assignments across different sites, with various work permutations.'
                },
                {
                    title: 'Access Control',
                    desc: 'Determine access rights based on roles and permissions to prevent any leak of confidential data.'
                },
                {
                    title: 'Automated Reminders',
                    desc: 'Receive automatic notifications or reminders upon trigger of specific events e.g. contract expiry, birthday, leave submission.'
                },
            ],
            thumbnails: [
                {
                    images: ComprehensiveEmployeeData,
                    desc: 'Comprehensive Employee Data',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: MultipleAssignmentsPerUser,
                    desc: 'Multiple Assignments Per User',
                    backgroundColor: "#e9f0fb"
                },
                {
                    images: AutomatedSystemReminders,
                    desc: 'Automated System Reminders',
                    backgroundColor: "#e6f4f3"
                }
            ]
        }

    claims =
        {
            contents: [
                {
                    title: 'Flexible Claims',
                    desc: 'Configure any permutation of claim type, cap, amount, sub-claim type with automatic real-time conversion to any currency.'
                },
                {
                    title: 'Optical Character Recognition (OCR) with Duplicate Notification',
                    desc: 'Snap any receipt picture and have your expense claim automatically fill up with receipt date and amount for your instant submission. Potential claim duplicates are immediately flagged out to managers.'
                },
                {
                    title: 'Approval Workflow',
                    desc: 'Designate several levels of approval with seamless routing to approving managers / HR / finance based on claim type or amount.'
                },
                {
                    title: 'Chart of Accounts (COA) Mapping',
                    desc: 'Export easy claims reports mapped to Chart of Accounts by cost center and claim type, with receipt attachments neatly organized.'
                },
            ],
            thumbnails: [
                {
                    images: SeamlessClaimSubmissionviaOCR,
                    desc: 'Seamless Claim Submission via OCR',
                    backgroundColor: "#e8effa"
                },
                {
                    images: EasyEntitlementTracking,
                    desc: 'Easy Entitlement Tracking',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: DuplicateClaimFlag,
                    desc: 'Duplicate Claim Flag',
                    backgroundColor: "#e8effa"
                }
            ]
        }

    leave =
        {
            contents: [
                {
                    title: 'Flexible Leave',
                    desc: 'Configure any permutation of leave type, entitlement with expiry dates, or option to encash, forfeit or carry forward.'
                },
                {
                    title: 'Statutory Compliance',
                    desc: 'Assign preset statutory leaves easily (e.g. childcare leave) with defined attachments (e.g. Government Childcare Form).'
                },
                {
                    title: 'Leave Calendar',
                    desc: 'Get a team calendar view of upcoming leave, pending applications to plan work schedule easily.'
                },
                {
                    title: 'Integration to Roster and Timesheet',
                    desc: 'Prevent conflicts between work schedule and leave schedule with integration to other time management modules.'
                },
            ],
            thumbnails: [
                {
                    images: LeaveSubmissionwithPresetRequirements,
                    desc: 'Leave Submission with Preset Requirements',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: SharedLeaveCalendar,
                    desc: 'Shared Leave Calendar',
                    backgroundColor: "#e8effa"
                },
                {
                    images: IntegratedwithRosterTimesheet,
                    desc: 'Integrated with Roster / Timesheet',
                    backgroundColor: "e6f4f3"
                }
            ]
        }

    announcement =
        {
            contents: [
                {
                    title: 'Intranet on the Go',
                    desc: 'Facilitate workforce engagement by publishing announcements with attachments, to employees and even deskless users without emails.'
                },
                {
                    title: 'Selective Publishing',
                    desc: 'Choose to publish to all, or a subset of employees, based on content of messages.'
                },
                {
                    title: 'Real-Time Tracking',
                    desc: 'Track real-time on who has viewed or acknowledged the announcement, to ensure important information are communicated across.'
                },
                {
                    title: 'Scheduled Announcements',
                    desc: 'Set publish and expiry time in advance for announcements to be published or removed respectively.'
                },
            ],
            thumbnails: [
                {
                    images: OrganizedFoldersforEasyReading,
                    desc: 'Organized Folders for Easy Reading',
                    backgroundColor: "#e8effa"
                },
                {
                    images: WithAttachmentsandAcknowledgements,
                    desc: 'With Attachments and Acknowledgements',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: RealTimeStatisticsTracking,
                    desc: 'Real-Time Statistics Tracking',
                    backgroundColor: "#e8effa"
                }
            ]
        }


    roster =
        {
            contents: [
                {
                    title: 'Farewell to Excel or Paper Rosters',
                    desc: 'Roster staff on digital rosters with two views – by staff or by shift, with ability to mass roster and generate reports easily.'
                },
                {
                    title: 'Algorithm-Based Scheduling',
                    desc: 'Preset algorithms (e.g. shift pattern preference or skill sets) to automate your roster with just one click.'
                },
                {
                    title: 'Real Time Availabilities',
                    desc: 'Receive and see staff availability and preferences directly from scheduler to facilitate rostering.'
                },
                {
                    title: 'Real Time Status Updates',
                    desc: 'Get notifications to action upon once there are any drop/swap shifts by staff.'
                },
            ],
            thumbnails: [
                {
                    images: IntuitiveDigitalRoster,
                    desc: 'Intuitive Digital Roster',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: EasyAvailabilitySubmission,
                    desc: 'Easy Availability Submission',
                    backgroundColor: "#e8effa"
                },
                {
                    images: SelfServiceRosterFunction,
                    desc: 'Self Service Roster Function',
                    backgroundColor: "#e6f4f3"
                }
            ]
        }

    timesheet =
        {
            contents: [
                {
                    title: 'Location Based Tracking',
                    desc: 'Track employees’ work hours with real-time GPS and selfie/QR code functions to ensure visibility across different job sites.'
                },
                {
                    title: 'Mass Timesheet Submission',
                    desc: 'Upload timesheets in mass, as required, for easy and integrated payroll calculation.'
                },
                {
                    title: 'Statutory Compliance and Logic',
                    desc: 'Automate processing of statutory overtime, shift allowance, rounding and other incentives for payroll.'
                },
                {
                    title: 'Reports',
                    desc: 'Generate timesheet reports for easy analysis of key statistics e.g. work hours, overtime, punctuality and attendance rates.'
                },
            ],
            thumbnails: [
                {
                    images: AccurateGPSCapturewithSelfie,
                    desc: 'Accurate GPS Capture with Selfie',
                    backgroundColor: "#e8effa"
                },
                {
                    images: DigitalTimesheetSubmission,
                    desc: 'Digital Timesheet Submission',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: AutomaticOvertimeComputation,
                    desc: 'Automatic Overtime Computation',
                    backgroundColor: "#e8effa"
                }
            ]
        }

    training =
        {
            contents: [
                {
                    title: 'Learn Anywhere',
                    desc: 'Accessible quality learning content and refresher course at your fingertips, anywhere.'
                },
                {
                    title: 'Quiz-Based Learning',
                    desc: 'Set quizzes in the form of short answer or multiple-choice questions with time countdown or hints to maximize retention.'
                },
                {
                    title: 'Flexible Curriculum Design',
                    desc: 'Upload all types of content (e.g. images, videos, multimedia materials) in different permutations.'
                },
                {
                    title: 'Real-time Reports',
                    desc: 'Track learning progress and passing rates, and send automated reminders as necessary.'
                },
            ],
            thumbnails: [
                {
                    images: TrainingCourseOverview,
                    desc: 'Training Course Overview',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: RefresherQuizzesontheGo,
                    desc: 'Refresher Quizzes on the Go',
                    backgroundColor: "#e8effa"
                },
                {
                    images: FullyConfigurableFields,
                    desc: 'Fully Configurable Fields',
                    backgroundColor: "#e6f4f3"
                }
            ]
        }

    payroll =
        {
            contents: [
                {
                    title: 'Instant Pay',
                    desc: 'Enable option to pay workers once they clock out, with automatic month-end reconciliation logic that accounts for statutory contributions.'
                },
                {
                    title: 'Payroll Process Streamline',
                    desc: 'Add new paycodes or group payroll runs via self service to customize payroll policies to your own company.'
                },
                {
                    title: 'Statutory Compliance',
                    desc: 'Auto generate files in required format for statutory and tax submissions, with continuous system upgrades to ensure latest compliance.'
                },
                {
                    title: 'E-Payslip',
                    desc: 'Disseminate e-payslips to users, encrypted with unique password format, with the ability to download to phone or send to email.'
                },
            ],
            thumbnails: [
                {
                    images: InstantPayTransactions,
                    desc: 'Instant Pay Transactions',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: EasyAccesstoEncryptedPayslip,
                    desc: 'Easy Access to Encrypted Payslip',
                    backgroundColor: "#e8effa"
                },
                {
                    images: MassEPayslipDissemination,
                    desc: 'Mass E-Payslip Dissemination',
                    backgroundColor: "#e6f4f3"
                }
            ]
        }

    others =
        {
            contents: [
                {
                    title: 'Consulting Services',
                    desc: 'Discuss on digital transformation strategy for your company, with quick wins and implementation plan.'
                },
                {
                    title: 'Implementation Services',
                    desc: 'Provide blueprint, configuration with preset templates, data migration, training, change management support for all new customers to accelerate system adoption.'
                },
                {
                    title: 'Project Management Support',
                    desc: 'Augment end to end project lifecycle management support including project kick off, weekly update, customizations and change request management.'
                },
                {
                    title: 'Support & Maintenance',
                    desc: 'Troubleshoot and support both remote/on-site with continuous upgrades and patches as part of ongoing product roadmap.'
                },
            ],
            thumbnails: [
                {
                    images: InAppSupport,
                    desc: '24/7 In-App Support',
                    backgroundColor: "#e8effa"
                },
                {
                    images: StepbyStepFAQGuide,
                    desc: 'Step by Step FAQ Guide',
                    backgroundColor: "#e6f4f3"
                },
                {
                    images: PresetConfigurationWorkbooksUATScripts,
                    desc: 'Preset Configuration Workbooks & UAT Scripts',
                    backgroundColor: "#e8effa"
                }
            ]
        }


    state = {
        activeBox: 'Overview',
        activeContent: this.overview.contents,
        activeThumbnails: this.overview.thumbnails,
        activeThumbnail: this.overview.thumbnails[0],
        activeIndex: 0
    };

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    handleActiveBox(type) {
        switch (type) {
            case 'Overview':
                this.setState({
                    activeBox: type,
                    activeContent: this.overview.contents,
                    activeThumbnails: this.overview.thumbnails,
                    activeThumbnail: this.overview.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Basic HR':
                this.setState({
                    activeBox: type,
                    activeContent: this.basichr.contents,
                    activeThumbnails: this.basichr.thumbnails,
                    activeThumbnail: this.basichr.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Claims':
                this.setState({
                    activeBox: type,
                    activeContent: this.claims.contents,
                    activeThumbnails: this.claims.thumbnails,
                    activeThumbnail: this.claims.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Leave':
                this.setState({
                    activeBox: type,
                    activeContent: this.leave.contents,
                    activeThumbnails: this.leave.thumbnails,
                    activeThumbnail: this.leave.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Announcements':
                this.setState({
                    activeBox: type,
                    activeContent: this.announcement.contents,
                    activeThumbnails: this.announcement.thumbnails,
                    activeThumbnail: this.announcement.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Roster':
                this.setState({
                    activeBox: type,
                    activeContent: this.roster.contents,
                    activeThumbnails: this.roster.thumbnails,
                    activeThumbnail: this.roster.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Timesheet':
                this.setState({
                    activeBox: type,
                    activeContent: this.timesheet.contents,
                    activeThumbnails: this.timesheet.thumbnails,
                    activeThumbnail: this.timesheet.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Training':
                this.setState({
                    activeBox: type,
                    activeContent: this.training.contents,
                    activeThumbnails: this.training.thumbnails,
                    activeThumbnail: this.training.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Payroll':
                this.setState({
                    activeBox: type,
                    activeContent: this.payroll.contents,
                    activeThumbnails: this.payroll.thumbnails,
                    activeThumbnail: this.payroll.thumbnails[0],
                    activeIndex: 0
                })
                break;
            case 'Others':
                this.setState({
                    activeBox: type,
                    activeContent: this.others.contents,
                    activeThumbnails: this.others.thumbnails,
                    activeThumbnail: this.others.thumbnails[0],
                    activeIndex: 0
                })
                break;

            default:
                break;
        }
    }
    handleActiveThumbnail(thumbnail) {
        this.setState({ activeThumbnail: thumbnail, })
    }
    handleActiveThumbnailbyArrow(type) {
        let newIndex = 0
        switch (type) {
            case 'next':
                newIndex = this.state.activeIndex + 1
                if (this.state.activeIndex === 2) {
                    this.setState({ activeIndex: 0, activeThumbnail: this.state.activeThumbnails[0] })
                } else {
                    this.setState({ activeIndex: newIndex, activeThumbnail: this.state.activeThumbnails[newIndex] })
                }
                break;
            case 'previous':
                newIndex = this.state.activeIndex - 1
                if (this.state.activeIndex === 0) {
                    this.setState({ activeIndex: 2, activeThumbnail: this.state.activeThumbnails[2] })
                } else {
                    this.setState({ activeIndex: newIndex, activeThumbnail: this.state.activeThumbnails[newIndex] })
                }
                break;
            default:
                break;
        }

    }
    render() {
        const { t } = this.props;
        return (
            <Grid container className={'modules-container'}>
                <Helmet>
                    <title>Octomate - {t(`GENERAL.Modules`)}</title>
                    <meta name="description" content={t("MODULE_PAGE.The following page explain all about our modules. This modules may be updated from time to time.")} />
                </Helmet>
                <div className={'modules-header'}>
                    <h1 className={'modules-title'}>{t(`GENERAL.Modules`)}</h1>
                </div>
                <div className={'modules-content'}>
                    <h2 className={'modules-content-title'}>{t(`MODULE_PAGE.Explore`)}</h2>
                    <h4 className={'modules-content-subtitle'}>{t(`MODULE_PAGE.Select a Module to Discover More`)}</h4>
                    <div className={'modules-box-container'}>
                        <div onClick={() => this.handleActiveBox('Overview')} className={`demo-module-box override-box ${this.state.activeBox === 'Overview' ? 'activated' : 'notActivated'}`}>
                            {/* <div className={this.state.activeBox === 'overview' ? 'overlay' : 'overlay-disabled'}></div> */}
                            <img src={overview} alt="Overview Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Overview' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Overview`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Basic HR')} className={`demo-module-box override-box ${this.state.activeBox === 'Basic HR' ? 'activated' : 'notActivated'}`}>
                            <img src={basicHr} alt="Basic HR Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Basic HR' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Basic HR`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Claims')} className={`demo-module-box override-box ${this.state.activeBox === 'Claims' ? 'activated' : 'notActivated'}`}>
                            <img src={claim} alt="Claims Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Claims' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Claims`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Leave')} className={`demo-module-box override-box ${this.state.activeBox === 'Leave' ? 'activated' : 'notActivated'}`}>
                            <img src={leave} alt="Leave Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Leave' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Leave`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Announcements')} className={`demo-module-box override-box ${this.state.activeBox === 'Announcements' ? 'activated' : 'notActivated'}`}>
                            <img src={announcement} alt="Announcements Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Announcements' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Announcements`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Roster')} className={`demo-module-box override-box ${this.state.activeBox === 'Roster' ? 'activated' : 'notActivated'}`}>
                            <img src={roster} alt="Roster Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Roster' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Roster`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Timesheet')} className={`demo-module-box override-box ${this.state.activeBox === 'Timesheet' ? 'activated' : 'notActivated'}`}>
                            <img src={timesheet} alt="Timesheet Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Timesheet' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Timesheet`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Training')} className={`demo-module-box override-box ${this.state.activeBox === 'Training' ? 'activated' : 'notActivated'}`}>
                            <img src={training} alt="Training Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Training' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Training`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Payroll')} className={`demo-module-box override-box ${this.state.activeBox === 'Payroll' ? 'activated' : 'notActivated'}`}>
                            <img src={payroll} alt="Payroll Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Payroll' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Payroll`)}</p>
                        </div>
                        <div onClick={() => this.handleActiveBox('Others')} className={`demo-module-box override-box ${this.state.activeBox === 'Others' ? 'activated' : 'notActivated'}`}>
                            <img src={others} alt="Others Logo" className={'demo-module-logo'} />
                            <p className={this.state.activeBox === 'Others' ? 'op-0' : 'op-1'}>{t(`MODULE_PAGE.Others`)}</p>
                        </div>
                    </div>
                    <div className={'modules-overview-container'}>
                        <h3 className={'modules-overview-title'}>{t("MODULE_PAGE."+this.state.activeBox)}</h3>
                        <Grid container className={'modules-overview-content'}>
                            <Grid item xs={12} sm={7} className={'modules-descriptions'}>
                                {
                                    this.state.activeContent.map((content, index) =>
                                        <div key={index} className={'modules-description'}>
                                            <div className={'modules-description-number'}>{index + 1}</div>
                                            <div className={'modules-description-text'}>
                                                <h3>{t("MODULE_PAGE."+content.title)}</h3>
                                                <p>{t("MODULE_PAGE."+content.desc)}</p>
                                            </div>
                                        </div>
                                    )
                                }

                            </Grid>
                            <Grid item xs={12} sm={5} className={'modules-thumbnails'}>
                                <div className={"modules-thumbnail"} style={{ backgroundColor: this.state.activeThumbnail.backgroundColor }}>
                                    <img src={this.state.activeThumbnail.images} className={"modules-thumbnail-img"} alt="thumbnail" />
                                    <div className={"modules-thumbnail-desc"}>
                                        <h5>
                                            {t("MODULE_PAGE."+this.state.activeThumbnail.desc)}
                                        </h5>
                                    </div>
                                </div>
                                {/* <div className={"modules-thumbnail-arrow"}>
                                    <img onClick={() => this.handleActiveThumbnailbyArrow('previous')} src={arrow_Left_Module} className={'left'} alt="Previous" />
                                    <img onClick={() => this.handleActiveThumbnailbyArrow('next')} src={arrow_Right_Module} alt="Next" />
                                </div> */}
                                <div className={"modules-thumbnail-imgs"}>
                                    {
                                        this.state.activeThumbnails.map((thumbnail, index) =>
                                            <img key={index} onClick={() => this.handleActiveThumbnail(thumbnail)} className={this.state.activeThumbnail.desc === thumbnail.desc ? 'activeThumbnail' : ''} src={thumbnail.images} alt="thumbnail" />
                                        )
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className={'modules-contact-us'}>
                    <h3>{t(`MODULE_PAGE.Still have questions? Get in`)} <br />
                        {t(`MODULE_PAGE.touch and share your concerns`)}
                    </h3>
                    <div>
                        <a href='/contactus'>
                            <button className={"btn-schedule-demo-action mt-0"}>
                                {t(`MODULE_PAGE.Contact Us`)}
                                <img src={arrowRight} alt="go" className={"arr-demo"} />
                            </button>
                        </a>
                    </div>
                </div>
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </Grid>
        )
    }
}

export default withTranslation()(Modules)