import React from "react";
import './login.css'
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import OButton from '../OButton';
import { Redirect } from "react-router-dom";
import { firebaseAuth } from '../../auth/firebase';


const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%'
        },
    },
}));




export default class Login extends React.Component {
    state = {
        email: "",
        password: "",
        errorMessage: null
    }



    componentDidMount() {
        firebaseAuth.onAuthStateChanged(user => {
            if (this.user)
                this.setState({ redirect: true })
        })
        document.documentElement.scrollTop = 0;
    }

    componentDidUpdate(nextProps) {
        if (nextProps.formType !== this.state.formType) {
            this.setState({
                formType: nextProps.formType,
                success: false
            });
        }
    }

    handleChange = field => event => {
        this.setState({ [field]: event.target.value });
    };

    onLogin = () => {
        firebaseAuth.signInWithEmailAndPassword(this.state.email, this.state.password)
            .then(res => {
                this.setState({ redirect: true })
                window.location.reload()
            })
            .catch(error => {
                this.setState({ errorMessage: "Invalid Email or Password" })
                console.log(error)
            });
    }

    render() {
        if (this.state.redirect) return <Redirect to="/" />
        return (
            <Grid container className={'login-container'}>
                <div className={'login-content'}>
                    <form className="form-login">
                        <Form onSubmit={this.onLogin} error={this.state.errorMessage} onHandle={this.handleChange} />
                        <OButton className={'btn-try'} onClick={this.onLogin} variant="outlined" color="primary">
                            {
                                this.state.formType === "try" ?
                                    "Try For Free"
                                    :
                                    "Login"
                            }
                        </OButton>
                    </form>
                </div>
            </Grid>
        )
    }
}

export function Form(props) {
    const classes = useStyles();

    return (
        <div className={`${classes.root}`} >
            <h3 className={'register-company'}>Login Admin</h3>
            <TextField required id="outlined-basic" onChange={props.onHandle("email")} label="Email Address" type="email" variant="outlined" />
            <TextField required id="outlined-basic" onChange={props.onHandle("password")} type="password" label="Password" variant="outlined" />
            <span className={'error-message'}>{props.error}</span>
        </div>
    )
}