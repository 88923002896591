import React from 'react';
import Grid from '@material-ui/core/Grid';

import enterpriseSgLogo from "../../assets/img/enterprise_sg_logo.png";
import ntuLogo from "../../assets/img/ntu_logo.png";
import masLogo from "../../assets/img/mas_logo.png";


import "./index.css";
import AOS from 'aos';
import { withTranslation } from 'react-i18next';
class CompanyReviewSection extends React.Component {

    componentDidMount() {
        AOS.init({
            duration: 1500,
            mirror: true
        });

    }

    // componentWillReceiveProps() {
    //     AOS.refresh();
    // }

    render() {
        const { t } = this.props;
        return (
            <Grid container className={"companyReviewsContainer"} >
                <Grid data-aos="fade-left" container className={"review-right"}>
                    <Grid className={"flex-centered"}  >
                        <div className={"companyReviewsHeader"}>
                            {t(`HOME_PAGE.Supported By`)}
                        </div>
                    </Grid>
                    <Grid className={"review"} >
                        <div className={"companyReviewContainer"}>
                            <img src={enterpriseSgLogo} alt="Enterprise SG" className={"companyReviewLogo"} />
                        </div>
                    </Grid>
                    <Grid className={"review"} >
                        <div className={"companyReviewContainer"}>
                            <img src={masLogo} alt="Monetary Authority of Singapore" className={"masLogo"} />
                        </div>
                    </Grid>
                    <Grid className={"review"} >
                        <div className={"companyReviewContainer"}>
                            <img src={ntuLogo} alt="Nanyang Technological University" className={"companyReviewLogo2"} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(CompanyReviewSection)