import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import OButton from '../../../components/OButton';
import AvatarEditor from 'react-avatar-editor'
import Slider from '@material-ui/core/Slider';





export default function SimpleDialog(props) {
    const [value, setValue] = React.useState(1.2);

    const handleClose = res => {
        props.onClose()
    }

    const onSaveCrop = () => {
        props.onSaveCrop()
    }

    const handleChange = (event, newValue) => {
        if (newValue >= 1.0) {
            setValue(newValue);
        }
    };



    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.uploadState.dialogOpen}>
            <DialogTitle id="simple-dialog-title">Icon Crop</DialogTitle>
            {
                <AvatarEditor
                    ref={props.setEditorRef}
                    image={props.uploadState.src}
                    width={350}
                    height={300}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={value}
                    rotate={0}
                />
            }
            <div style={{ padding: 15 }}>
                <Slider value={value} onChange={handleChange} aria-labelledby="continuous-slider" />
            </div>
            <div style={{ padding: 15 }} className={"button-container"}>
                <OButton className={'btn-cancel'} onClick={handleClose} variant="outlined" color="secondary">
                    Cancel
                </OButton>
                <OButton className={'btn-try'} onClick={onSaveCrop} variant="outlined" color="primary">
                    Save
                </OButton>
            </div>
        </Dialog>
    );
}