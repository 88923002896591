import React from 'react';
import './detailBlog.css';
import { Grid } from '@material-ui/core';
import { firestore, fireCount } from '../../../auth/firebase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import searchIcon from '../../../assets/img/Search.svg';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Bloggy from '../../../assets/img/Bloggy.jpg'
import breadcrumbIcon from '../../../assets/img/breadcrumb-icon.png'
import Arrow_Right from '../../../assets/img/Arrow_Right-blog.png'
import Arrow_Left from '../../../assets/img/Arrow_Left-blog.png'
import { Link } from 'react-router-dom';
import * as moment from 'moment'
import history from "../../../services/history";
import { makeStyles } from '@material-ui/core/styles';

import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import Badge from '@material-ui/core/Badge';
import ShareIcon from '@material-ui/icons/Share';

const useStyles = makeStyles(theme => ({
    inputRoot: {
        '&[data-focus="true"]': {
            backgroundColor: '#fff !important',
            borderColor: 'transparent !important',
        },
        '&[aria-selected="true"]': {
            backgroundColor: '#fff !important',
            borderColor: 'transparent !important',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        }
    },
    clearIndicator: {
        marginRight: "150px"
    },
}));

export default class DetailedBlog extends React.Component {
    blogRef;
    blogsRef;
    topicsRef;
    unsubscribe
    unsubscribe2
    blogs;
    activeIndex;
    nextBlog;
    previousBlog;
    state = {
        blogs: [],
        blog: "",
        posts: [],
        topics: [],
        likes: [],
        open: false,
        message: ''
    }

    createMarkup = (res) => {
        return { __html: res };
    }

    async componentDidMount() {
        this.getBlog("init");
        this.getLikesFromLocal();
        document.documentElement.scrollTop = 0;
    }

    getLikesFromLocal() {
        var likes = localStorage.getItem('tmpL');
        if (likes)
            likes = JSON.parse(likes);
        this.setState({ likes: likes || [] });
    }

    getBlog = async (type) => {
        this.setState({ blog: "" })
        let paramKey = window.location.pathname.split('/')[2].split(":")[0]
        if (this.previousBlog !== null && type === "previous") {
            paramKey = this.previousBlog.key
            document.documentElement.scrollTop = 0;
        }
        if (this.nextBlog !== null && type === "next") {
            paramKey = this.nextBlog.key
            document.documentElement.scrollTop = 0;
        }
        this.nextBlog = null
        this.previousBlog = null
        this.blogRef = firestore.collection(`blog`)
        this.unsubscribe2 = this.blogRef.doc(paramKey).onSnapshot(doc => {
            if (this.state.blog === "") {
                this.blogs = JSON.parse(window.localStorage.getItem("tmp"))
                this.activeIndex = (this.blogs.filter(x => x.key === paramKey)[0].index)
                if ((this.activeIndex) !== this.blogs.length) {
                    this.nextBlog = this.blogs.filter(x => x.index === (this.activeIndex + 1))[0]
                }
                if (this.activeIndex !== 1) {
                    this.previousBlog = this.blogs.filter(x => x.index === (this.activeIndex - 1))[0]
                }
                var blog = doc.data()
                blog.key = paramKey
                return this.setState({ blog: blog });

            }
        });
    }

    // componentWillUnmount() {
    //     this.unsubscribe2()
    // }

    handleClick = () => {
        this.setState({ blog: "" })
        const paramKey = window.location.pathname.split('/')[3].split(":")[0]
        this.unsubscribe2 = this.blogRef.doc(paramKey).onSnapshot(doc => {
            if (this.state.blog === "") {
                return this.setState({ blog: doc.data() });
            }
        });
    }
    handleSnackbar = (res) => {
        this.setState({ open: true, message: res })
    }
    onSearch = (value) => {
        history.push(`/Blog/${value.key}:${value.title}`)
        this.getBlog("init")
    }

    onShared = (blog) => {
        this.handleSnackbar("Link Copied to Clipboard")
        navigator.clipboard.writeText(`${window.location.origin}/Blog/${blog.key}:${blog.title.replace(/\s+/g, '-')}`)
    }

    onLike = async (blog) => {
        if (this.state.likes.length > 0) {
            const selectedBlog = this.state.likes.filter(x => x.key === blog.key)[0]
            if (selectedBlog) {
                if (selectedBlog.isLike === true)
                    return
            }
        }
        const statsDoc = firestore.doc(`blog/${blog.key}`);
        const countDoc = {};
        countDoc["likes"] = fireCount.increment(1);
        await statsDoc.set(countDoc, { merge: true });
        this.state.likes.push({ key: blog.key, isLike: true })
        localStorage.setItem("tmpL", JSON.stringify(this.state.likes))
        this.getBlog("init")
        this.forceUpdate()
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false, dialogOpen: false })
    };

    render() {
        return (
            <Grid container className={"mb-150"}>
                <Grid container alignItems="center" justify="center">
                    <Search blogs={this.blogs} onSearch={this.onSearch} />
                    <img src={searchIcon} alt="search-icon" className={'search-icon'} />
                </Grid>
                <Grid container className={"py-170 justify-space-between mt-50"} direction="row">
                    <Grid item md={8} sm={8} xs={12} className={'detailed-blog-container'}>
                        <div className={"detailed-blog-breadcrum-container"}>
                            <Link className={"detailed-blog-breadcrum-links"} to={"/Blog"}>Blog</Link>
                            <img src={breadcrumbIcon} width="16" alt="breadcrumb" />
                            <span className={"detailed-blog-active-breadcrum"}>{this.state.blog.title}</span>
                        </div>
                        <div key={this.state.blog.key} className={'detailed-blog'} >
                            <div className={'detailed-blog-content'} >
                                <h2>{this.state.blog.title}</h2>
                                <div className='d-flex justify-space-between align-items-center'>
                                    <Typography className={"blog-card-date"} variant="subtitle2" component="small">
                                        By {this.state.blog.createdBy} {moment(this.state.blog.createdTime).format('MMM DD, YYYY')}
                                    </Typography>
                                    <div>
                                        {this.state.likes?.filter(x => x.key === this.state.blog.key)[0]?.isLike ?
                                            <IconButton aria-label="Liked">
                                                <Badge className='blog-badge' color="primary" badgeContent={this.state.blog.likes}>
                                                    <ThumbUpAltIcon style={{ color: '#5cb8b2' }} />
                                                </Badge>
                                            </IconButton> :
                                            <IconButton onClick={() => this.onLike(this.state.blog)} aria-label="add to Likes">
                                                <Badge className='blog-badge' color="primary" badgeContent={this.state.blog.likes}>
                                                    <ThumbUpAltOutlinedIcon />
                                                </Badge>
                                            </IconButton>
                                        }
                                        <IconButton onClick={() => this.onShared(this.state.blog)} aria-label="Share">
                                            <ShareIcon style={{ color: '#5cb8b2' }} />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className={'detailed-blog-detailed-content mt-30'} dangerouslySetInnerHTML={this.createMarkup(this.state.blog.content)}></div>
                                <div className='mt-40 d-flex justify-content-end'>
                                    {this.state.likes?.filter(x => x.key === this.state.blog.key)[0]?.isLike ?
                                        
                                        <IconButton aria-label="Liked">
                                            <Badge className='blog-badge' color="primary" badgeContent={this.state.blog.likes}>
                                                <ThumbUpAltIcon style={{ color: '#5cb8b2' }} />
                                            </Badge>
                                        </IconButton> :
                                        <IconButton onClick={() => this.onLike(this.state.blog)} aria-label="add to Likes">
                                            <Badge className='blog-badge' color="primary" badgeContent={this.state.blog.likes}>
                                                <ThumbUpAltOutlinedIcon />
                                            </Badge>
                                        </IconButton>
                                    }
                                    <IconButton onClick={() => this.onShared(this.state.blog)} aria-label="Share">
                                        <ShareIcon style={{ color: '#5cb8b2' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={4} xs={12} className={`blog-card-content pr-0 flex-none`}>
                        {this.nextBlog ?
                            <Card className={`box-shadow blog-card mb-80`}>
                                <div>
                                    <div className={"d-flex justify-space-between align-items-flex-start"}>
                                        <Typography variant="h5" component="h2" className={"blog-card-title mb-25"}>
                                            Next Post
                                        </Typography>
                                        <Link to={`/Blog/${this.nextBlog.key}:${this.nextBlog.title}`} onClick={() => this.getBlog("next")}>
                                            <img src={Arrow_Right} width="24" className={"cursor-pointer"} alt="arrow right" />
                                        </Link>
                                    </div>
                                    <CardMedia
                                        className={'blog-media mb-25'}
                                        title={this.nextBlog.title}
                                        image={this.nextBlog.icon === "" ? Bloggy : this.nextBlog.icon}
                                    />
                                    <CardContent className={'p-0'}>
                                        <Typography variant="h5" component="h2" className={"blog-card-title mb-20"}>
                                            {this.nextBlog.title}
                                        </Typography>
                                        <Typography className={"blog-card-date"} variant="subtitle2" component="small">
                                            By {this.nextBlog.createdBy} {moment(this.nextBlog.createdTime).format('MMM DD, YYYY')}
                                        </Typography>
                                        <Typography className={"blog-card-desc"} variant="body2" color="textSecondary" component="p">
                                            {this.nextBlog.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className={"blog-card-actions"}>
                                        <Link to={`/Blog/${this.nextBlog.key}:${this.nextBlog.title}`} onClick={() => this.getBlog("next")} className={"font-base blog-card-view-details"}>View Details</Link>
                                        <div>
                                            {this.state.likes?.filter(x => x.key === this.nextBlog.key)[0]?.isLike ?
                                                <IconButton aria-label="Liked">
                                                    <Badge className='blog-badge' color="primary" badgeContent={this.nextBlog.likes}>
                                                        <ThumbUpAltIcon style={{ color: '#5cb8b2' }} />
                                                    </Badge>
                                                </IconButton> :
                                                <IconButton onClick={() => this.onLike(this.nextBlog)} aria-label="add to Likes">
                                                    <Badge className='blog-badge' color="primary" badgeContent={this.nextBlog.likes}>
                                                        <ThumbUpAltOutlinedIcon />
                                                    </Badge>
                                                </IconButton>
                                            }
                                            <IconButton onClick={() => this.onShared(this.nextBlog)} aria-label="Share">
                                                <ShareIcon style={{ color: '#5cb8b2' }} />
                                            </IconButton>
                                        </div>
                                    </CardActions>
                                </div>
                            </Card>
                            :
                            ""
                        }

                        {this.previousBlog ?
                            <Card className={`box-shadow blog-card`}>
                                <div>
                                    <div className={"d-flex justify-space-between align-items-flex-start"}>
                                        <Typography variant="h5" component="h2" className={"blog-card-title mb-25"}>
                                            Previous Post
                                        </Typography>
                                        <Link to={`/Blog/${this.previousBlog.key}:${this.previousBlog.title}`} onClick={() => this.getBlog("previous")}>
                                            <img src={Arrow_Left} width="24" className={"cursor-pointer"} alt="arrow left" />
                                        </Link>
                                    </div>
                                    <CardMedia
                                        className={'blog-media mb-25'}
                                        image={this.previousBlog.icon === "" ? Bloggy : this.previousBlog.icon}
                                        title={this.previousBlog.title}
                                    />
                                    <CardContent className={'p-0'}>
                                        <Typography variant="h5" component="h2" className={"blog-card-title mb-20"}>
                                            {this.previousBlog.title}
                                        </Typography>
                                        <Typography className={"blog-card-date"} variant="subtitle2" component="small">
                                            By {this.previousBlog.createdBy} {moment(this.previousBlog.createdTime).format('MMM DD, YYYY')}
                                        </Typography>
                                        <Typography className={"blog-card-desc"} variant="body2" color="textSecondary" component="p">
                                            {this.previousBlog.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className={"blog-card-actions"}>
                                        <Link to={`/Blog/${this.previousBlog.key}:${this.previousBlog.title}`} onClick={() => this.getBlog("previous")} className={"font-base blog-card-view-details"}>View Details</Link>
                                        <div>
                                            {this.state.likes?.filter(x => x.key === this.previousBlog.key)[0]?.isLike ?
                                                <IconButton aria-label="Liked">
                                                    <Badge className='blog-badge' color="primary" badgeContent={this.previousBlog.likes}>
                                                        <ThumbUpAltIcon style={{ color: '#5cb8b2' }} />
                                                    </Badge>
                                                </IconButton> :
                                                <IconButton onClick={() => this.onLike(this.previousBlog)} aria-label="add to Likes">
                                                    <Badge className='blog-badge' color="primary" badgeContent={this.previousBlog.likes}>
                                                        <ThumbUpAltOutlinedIcon />
                                                    </Badge>
                                                </IconButton>
                                            }
                                            <IconButton onClick={() => this.onShared(this.previousBlog)} aria-label="Share">
                                                <ShareIcon style={{ color: '#5cb8b2' }} />
                                            </IconButton>
                                        </div>
                                    </CardActions>
                                </div>
                            </Card>
                            :
                            ""
                        }
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    message={this.state.message}
                />
            </Grid>
        )
    }
}

export function Search(props) {
    const classes = useStyles();
    return (
        <Autocomplete
            freeSolo
            id="search"
            classes={classes}
            onChange={(event, newValue) => {
                props.onSearch(newValue)
            }}
            className={"search-blog box-shadow-none"}
            options={props.blogs||[]}
            disableClearable
            getOptionLabel={option => option.title}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        className={"search-blog"}
                        variant="outlined"
                        placeholder="Search blog"
                    />
                );
            }}
        />
    )
}