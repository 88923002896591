import React from 'react';
import './detailArticles.css';
import { Grid } from '@material-ui/core';
import { firestore, } from '../../../auth/firebase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
    Link as LinkRouter,
} from 'react-router-dom';
export default class DetailedArticle extends React.Component {
    articleRef;
    articlesRef;
    topicsRef;
    unsubscribe
    unsubscribe2
    state = {
        article: "",
        posts: [],
        topics:[]
    }
    createMarkup = (res) => {
        return { __html: res };
    }

    componentDidMount() {
        const paramKey = window.location.pathname.split('/')[3].split(":")[0]
        const paramKeyArticles = window.location.pathname.split('/')[2].split(":")[0]
        this.articleRef = firestore.collection(`article`)
        this.categoryRef = firestore.collection(`category`);
        this.unsubscribe3 = this.categoryRef.orderBy("createdTime", "desc").limit(25).onSnapshot(snapshot => {
            const topics = []
            snapshot.forEach(doc => {
                const { title, createdTime, createdBy, description, icon } = doc.data();
                topics.push({
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                });
                this.setState({topics:topics})
            });
        })
        this.unsubscribe = this.articleRef.where("categorizeKey", "==", paramKeyArticles).onSnapshot(snapshot => {
            const articles = [];
            snapshot.forEach(doc => {
                const { title, createdTime, createdBy, description, icon, content, categorizeKey, categorizeType } = doc.data();
                articles.push({
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                    content,
                    categorizeKey,
                    categorizeType
                });
                this.setState({ posts: articles, loading: false })
            });
        })
        this.getArticle(paramKey);
    }

    getArticle = (paramKey) => {
        this.unsubscribe2 = this.articleRef.doc(paramKey).onSnapshot(doc => {
            if (this.state.article === "") {
                return this.setState({ article: doc.data() });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe()
        this.unsubscribe2()
        this.unsubscribe3()

    }

    handleClick = () => {
        this.unsubscribe2()
        this.setState({ article: "" })
        const paramKey = window.location.pathname.split('/')[3].split(":")[0]
        this.unsubscribe2 = this.articleRef.doc(paramKey).onSnapshot(doc => {
            if (this.state.article === "") {
                return this.setState({ article: doc.data() });
            }
        });
    }

    render() {
        return (
            <Grid item className={'article-container'} >
                <div className={'help-side-article'}>
                    <h2>Topics</h2>
                    <List dense={true}>
                        {this.state.topics.map(topic => {
                            const paths = window.location.pathname.split('/').filter((x) => x)
                            this.activeTopics = paths[1].split(":")[0]
                            return (<ListItem key={topic.key}>
                                <LinkRouter onClick={this.handleClick} to={`/HelpCenter/${topic.key}:${topic.title.replace(/\s+/g, '-')}`}>
                                    <ListItemText
                                        primary={this.activeTopics === topic.key ? topic.title : null}
                                        secondary={this.activeTopics !== topic.key ? topic.title : null}
                                    />
                                </LinkRouter>
                            </ListItem>)
                        })
                        }
                    </List>
                </div>
                <div key={this.state.article.key} className={'article'} >
                    <div className={'article-content'} >
                        <h2 style={{ textAlign: 'center' }}>{this.state.article.title}</h2>
                        <br />
                        <div className={'article-detailed-content'} dangerouslySetInnerHTML={this.createMarkup(this.state.article.content)}></div>
                    </div>
                </div>
            </Grid>
        )
    }
}