import React from 'react';
import './index.css';
import logo from '../../assets/img/logo.png';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import validator from "validator";
import contactUsService from '../../services/http/contactus.service';
import * as moment from 'moment';
import { withTranslation } from 'react-i18next';

class FooterSection extends React.Component {

    state = {
        email: "",
        errorField: null,
        success: false,
        formType: null
    }

    getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    scrollToHome = () => {
        if (window.location.pathname !== '' && window.location.pathname !== '/Home')
            return window.location.href = '/Home'
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    scrollToUseCases = () => {
        if (window.location.pathname !== '' && window.location.pathname !== '/Home')
            return window.location.href = '/Home#usecases'
        let el = document.getElementById("usecases");
        window.scrollTo({ top: this.getOffset(el).top - 100, left: 0, behavior: 'smooth' });
    }

    scrollToFeature = () => {
        if (window.location.pathname !== '' && window.location.pathname !== '/Home')
            return window.location.href = '/Home#features'
        let el = document.getElementById("features");
        window.scrollTo({ top: this.getOffset(el).top - 100, left: 0, behavior: 'smooth' });
    }

    handleChange = field => event => {
        this.setState({ [field]: event.target.value });
    };

    onSubmit = () => {
        let timer = window.localStorage.getItem("subcriber")
        if (timer) {
            let parsedTimer = JSON.parse(timer)
            let today = moment()
            let diff = today.diff(parsedTimer.time, 'seconds')
            if (diff >= 50) {
                window.localStorage.removeItem("subscriber")
                this.submit();
            }
        } else {
            this.submit();
        }
    };

    submit() {
        if (!validator.isEmail(this.state.email)) {
            this.setState({ errorField: 'email' });
        } else {
            const param = {
                email: this.state.email
            };
            contactUsService.subscribe(param)
                .then(resp => {
                    let time = moment();
                    window.localStorage.setItem("subcriber", JSON.stringify({ time: time, success: true }));
                    this.props.openFormModal('demo');
                })
                .catch(err => {
                    console.log(err);
                });

        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="footerSection">
                <Grid container className="linksContainer">
                    <Grid item className="linksColumn logo-container">
                        <img src={logo} className={'footerLogo'} alt="octomate" />
                    </Grid>
                    <Grid item className="linksColumn">
                        <div className="homeFeatureLinks">
                            <div className="footerHome" onClick={this.scrollToHome}>{t(`HOME_PAGE.Home`)}</div>
                            <div className="footerHome" onClick={this.scrollToFeature}>{t(`HOME_PAGE.Features`)}</div>
                            <div className="footerHome" onClick={this.scrollToUseCases}>{t(`HOME_PAGE.Use Cases`)}</div>
                            <div className="footerHome"><a href="/Blog">{t(`HOME_PAGE.Blog`)}</a></div>
                        </div>
                    </Grid>
                    <Grid item className="linksColumn">
                        <div className="helpCenterLink">
                            {/* <div className="footerHome">
                                <a href="/helpcenter" > Help Center</a>
                            </div> */}
                            <div className="footerHome">
                                <a href="/privacy" > {t(`HOME_PAGE.Privacy Notice`)}</a>
                            </div>
                            <div className="footerHome">
                                <a href="/term-of-use" > {t(`HOME_PAGE.Terms of Use`)}</a>
                            </div>
                            <div className="footerHome">
                                <a href="/dataprotection" > {t(`HOME_PAGE.Personal Data`)}</a>
                            </div>
                            <div className="footerHome">
                                <a href="/contactus"> {t(`HOME_PAGE.Contact Us`)}</a>
                            </div>
                        </div>
                    </Grid>
                    <Grid item className="linksColumn">
                        <div className="footerDemo">{t(`GENERAL.Subscribe`)}</div>
                        <div className={"footerDemoSub"}>{t(`HOME_PAGE.Get newsletters and stay up-to-date`)}</div>
                        <div className={"btn-get-started-container"}>
                            <Form t={t} error={this.state.errorField} onHandle={this.handleChange} />
                            <button onClick={() => this.onSubmit()} className={"btn-get-started-2"}>
                                {t(`GENERAL.Subscribe`)}
                            </button>
                        </div>
                    </Grid>
                </Grid>
                <div className="copyright mb-80">{t(`HOME_PAGE.© Octomate 2024 All rights reserved`)}</div>
            </div >
        );
    }

}

export function Form(props) {
    return (
        <TextField className={'subcribe-2'} required error={props.error === 'email' ? true : false} helperText={props.error === 'email' ? 'Field is invalid' : ''} onChange={props.onHandle("email")} id="outlined-basic" placeholder={props.t("GENERAL.ENTERYOUREMAIL")} variant="outlined" />
    )

}

export default withTranslation()(FooterSection)
