import React from 'react';
import './term-of-use.css'
import logo from '../../assets/img/octomate-logo-white.svg';
import back from '../../assets/img/Back.svg';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
class TermOfUse extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container className={'privacy'}>
                <Helmet>
                    <title>Octomate - {t(`TERMS_PAGE.Terms of Use`)}</title>
                    <meta name="description" content={t(`TERMS_PAGE.PLEASEREAD`)} />
                </Helmet>
                <img src={logo} className={'privacy-logo'} alt="octomate" />
                <div className={'privacy-content'}>
                    <div className={'privacy-content-scroll'}>
                        <h3 className={'privacy-header'}>{t(`TERMS_PAGE.Terms of Use`)}</h3>
                        <span>{t(`TERMS_PAGE.PLEASEREAD`)} </span>
                        <ol>
                            <li>
                                <h4>{t(`TERMS_PAGE.list1`)}</h4>
                                <p>
                                    {t(`TERMS_PAGE.list1DESC`)}
                                </p>

                                <h4>{t(`TERMS_PAGE.TRADEMARKS`)}</h4>
                                <p>
                                    {t(`TERMS_PAGE.TRADEMARKSDESC`)}
                                </p>
                            </li>
                            <li>
                                <h4>{t(`TERMS_PAGE.list2`)}</h4>
                                <p>
                                    {t(`TERMS_PAGE.list2DESC1`)}
                                </p>
                                <p>
                                    {t(`TERMS_PAGE.list2DESC2`)}
                                </p>
                                <p>
                                    {t(`TERMS_PAGE.list2DESC3`)}
                                </p>
                                
                                <h4>{t(`TERMS_PAGE.LIMITATIONLIABILITY`)}</h4>
                                <p>
                                    {t(`TERMS_PAGE.list2DESC4`)}
                                </p>
                            </li>
                            <li>
                                <h4>{t(`TERMS_PAGE.list3`)}</h4>
                                <p>
                                    {t(`TERMS_PAGE.list3DESC`)}
                                </p>
                            </li>
                            <li>
                                <h4>{t(`TERMS_PAGE.list4`)}</h4>
                                <p>
                                    {t(`TERMS_PAGE.list4DESC`)}
                                </p>
                            </li>
                            <li>
                                <h4>{t(`TERMS_PAGE.list5`)}</h4>
                                <p>
                                    {t(`TERMS_PAGE.list5DESC`)}
                                </p>
                            </li>
                            <li>
                                <h4>{t(`TERMS_PAGE.list6`)}</h4>
                                <p>
                                    {t(`TERMS_PAGE.list6DESC`)}
                                </p>
                            </li>
                        </ol >
                        <p>{t(`TERMS_PAGE.endDESC`)}</p>
                    </div>
                </div>
                <div className={"my-auto"}>
                    <a href="/">
                        <button className={'back-button'}>
                            <img src={back} className={'back-icon'} alt="back icon" />
                            {t(`TERMS_PAGE.Back To Website`)}
                        </button>
                    </a>
                </div>
            </Grid>
        )
    }
}

export default withTranslation()(TermOfUse)