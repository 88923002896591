import React from 'react';
import './App.css'
import { Switch, Route } from 'react-router-dom';
import MainPage from './components/main-page/mainPage';
import Privacy from './components/privacy/privacy';
import Modules from './components/modules/modules';
import PersonalDataProtection from './components/personal-data-protection/pdp'
import ScheduleDemo from './components/schedule-demo/scheduledemo';
import HelpCenter from './sections/HelpCenter/index';
import Blog from './sections/BlogSection/index';
import Guides from './sections/GuidesSection/index';
import CaseStudies from './sections/caseStudiesSection/index';
import FormModal from "./sections/FormModal";
import NavigationBar from "./sections/NavigationBar";
import Login from "./components/login/login";
import logo from './assets/img/loading.gif'
import ContactUs from './components/contact-us/contactUs';
import ContactSuccess from './components/contact-us/contactSuccess';
import jobSeekersSection from './sections/jobSeekersSection';
import employersDirectStaffing from './components/employers-direct-staffing';
import employersManagedServices from './components/employers-managed-services';
import appDownload from './components/app-download';
import redirectJob from './components/redirect-job';
import termOfUse from './components/term-of-use/term-of-use';
import SecurityPrivacy from './sections/SecurityPrivacy';

export default class AppRoutes extends React.Component {
    state = {
        homeY: 0,
        featuresY: 0,
        usecasesY: 0,
        windowY: 0,
        isFormModalOpen: false,
        formType: null,
        isPrivacyModalOpen: false,
        loaded: false
    }

    componentDidMount() {
        this.setState({
            // homeY: this.getOffset(document.getElementById('landing')),
            // featuresY: this.getOffset(document.getElementById('features')),
            // usecasesY: this.getOffset(document.getElementById('usecases')),
            location: window.location.pathname,
            windowY: window.scrollY,
            loaded: true
        });
    }

    getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    closeFormModal = () => {
        this.setState({ isFormModalOpen: false, formType: null });
    }

    openFormModal = (formType) => {
        this.setState({ isFormModalOpen: true, formType });
    }

    closePrivacyModal = () => {
        this.setState({ isPrivacyModalOpen: false });
    }

    openPrivacyModal = () => {
        this.setState({ isPrivacyModalOpen: true });
    }

    clickEventHandler(value) {
    }

    NotFound = () => (
        <div style={{ paddingTop: '100px', textAlign: 'center' }}>
            <h1>404 - Not Found!</h1>
            <a href="/Home">
                Go Home
            </a>
        </div>
    );

    render() {
        return (

            <React.Fragment>
                {this.state.loaded ? (

                    <main >

                        <NavigationBar
                            homeY={this.state.homeY}
                            featuresY={this.state.featuresY}
                            usecasesY={this.state.usecasesY}
                            openFormModal={this.openFormModal}
                        />
                        <Switch>

                            <Route path="/" exact component={MainPage} />
                            <Route path="/Home" component={MainPage} />
                            <Route path="/Features" component={MainPage} />
                            <Route path="/UseCases" component={MainPage} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="/term-of-use" component={termOfUse} />
                            <Route path="/scheduledemo" component={ScheduleDemo} />
                            <Route path="/HelpCenter" component={HelpCenter} />
                            <Route path="/CaseStudies" component={CaseStudies} />
                            <Route path="/SecurityPrivacy" component={SecurityPrivacy} />
                            <Route path="/jobSeekers" component={jobSeekersSection} />
                            <Route path="/employers-direct-staffing" component={employersDirectStaffing} />
                            <Route path="/employers-managed-services" component={employersManagedServices} />
                            <Route path="/dataprotection" component={PersonalDataProtection} />
                            <Route path="/Blog" component={Blog} />
                            <Route path="/Guides" component={Guides} />
                            <Route path="/Modules" component={Modules} />
                            <Route path="/ContactUs" component={ContactUs} />
                            <Route path="/Success" component={ContactSuccess} />
                            <Route path="/Admin" component={Login} />
                            <Route path="/app-download" component={appDownload} />
                            <Route path="/jobs/:id" component={redirectJob} />
                            <Route path='/privacy-policy' component={() => {
                                window.location.href = 'https://octomate.us/privacy-policy.html';
                                return null;
                            }} />
                            <Route component={this.NotFound} />
                        </Switch>
                        <FormModal
                            isFormModalOpen={this.state.isFormModalOpen}
                            closeFormModal={this.closeFormModal}
                            formType={this.state.formType}
                        />

                    </main>
                ) : (
                    <div style={{ background: '#f7f7f7' }}>
                        <img src={logo} className={'loadingGif'} alt="loading..." />
                    </div>
                )}
            </React.Fragment>
        );
    }
}
export function Main() {
    // var y = window.scrollY
}