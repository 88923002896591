import React from 'react';
import '../../App.css';
import LandingSection from "../../sections/LandingSection";
import FeaturesSection from "../../sections/FeaturesSection";
import OurClients from "../../sections/OurClients";
import DemoSection from "../../sections/DemoSection";
import UserReviewSection from "../../sections/UserReviewSection";
import CompanyReviewSection from "../../sections/CompanyReviewSection";
import FormModal from "../../sections/FormModal";
import FooterSection from '../../sections/FooterSection';
import CallToActionSection from '../../sections/CallToActionSection';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
// import MapSection from '../../sections/MapSection';

class MainPage extends React.Component {

  state = {

    homeY: 0,
    featuresY: 0,
    usecasesY: 0,
    scrollY: 0,
    isFormModalOpen: false,
    formType: null,
    isPrivacyModalOpen: false,
    loading: true
  }

  getOffset = (el) => {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        homeY: this.getOffset(document.getElementById('landing')),
        featuresY: this.getOffset(document.getElementById('features')),
        usecasesY: this.getOffset(document.getElementById('usecases')).top,
        // loading: false
      });
    }, 1000);
  }

  componentWillUnmount() {
    window.localStorage.removeItem("tmp")
  }

  closeFormModal = () => {
    this.setState({ isFormModalOpen: false, formType: null });
  }

  openFormModal = (formType) => {
    this.setState({ isFormModalOpen: true, formType });
  }

  closePrivacyModal = () => {
    this.setState({ isPrivacyModalOpen: false });
  }

  openPrivacyModal = () => {
    this.setState({ isPrivacyModalOpen: true });
  }


  render() {
    const { t } = this.props;
    return (
      <div className="App" >
        <Helmet>
          <title>Octomate - {t(`HOME_PAGE.TITLE`)}</title>
          <meta name="description" content={t(`HOME_PAGE.CONTENT`)} />
        </Helmet>
        <div id="landing" className="bgone">
          <LandingSection openFormModal={this.openFormModal} />
          <OurClients />
          <FeaturesSection />
        </div>
        {/* <VideoSection /> */}
        <DemoSection />
        <div className="bgsecond">
          <UserReviewSection />
        </div>
        <div className="bgthird">
          <CompanyReviewSection />
          <CallToActionSection openFormModal={this.openFormModal} />
        </div>
        {/* <div className='map-container'>
          <MapSection/>
        </div> */}
        <div>
          <FooterSection openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
        </div>

        {
          <FormModal
            isFormModalOpen={this.state.isFormModalOpen}
            closeFormModal={this.closeFormModal}
            formType={this.state.formType}
          />
        }
      </div>
    );
  }
}

export default withTranslation()(MainPage)