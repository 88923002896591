import React from 'react';
import './index.css'
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import OButton from '../../components/OButton';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import Badge from '@material-ui/core/Badge';
import ShareIcon from '@material-ui/icons/Share';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import EditorToolbar, { modules, formats } from "../../shared/EditorToolbar";
import ReactQuill from 'react-quill';
import { FaPlus } from "react-icons/fa";
import * as moment from 'moment'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { firebaseAuth, firestore, fireCount, firebaseStorage } from '../../auth/firebase';
import SimpleDialog from "./components/change-icon-dialog"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Link, Route, Switch } from 'react-router-dom';
import Bloggy from '../../assets/img/Bloggy.jpg'
import searchIcon from '../../assets/img/Search.svg'
import Dropdown from '../../assets/img/Dropdown.png'
import arrowRight from '../../assets/img/arrow-right.png'
import DetailedBlog from './components/detailBlog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import FooterSection from '../../sections/FooterSection';
import history from "../../services/history";
import { withTranslation } from 'react-i18next';



const useStyles = makeStyles(theme => ({
    inputRoot: {
        '&[data-focus="true"]': {
            backgroundColor: '#fff !important',
            borderColor: 'transparent !important',
        },
        '&[aria-selected="true"]': {
            backgroundColor: '#fff !important',
            borderColor: 'transparent !important',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important"
        }
    },
    clearIndicator: {
        marginRight: "150px"
    },
}));

class Blog extends React.Component {
    blogRef = firestore.collection(`blog`);
    counterBlogsRef = firestore.collection(`counters`)
    unsubscribe;
    interval
    state = {
        deleteDialogOpen: false,
        user: null,
        errorMessage: "",
        message: "",
        open: false,
        dialogOpen: false,
        blogs: [],
        allBlogs: [],
        limitedBlog: [],
        loading: true,
        isViewAll: false,
        currentPage: 0,
        previousPage: 0,
        pageSize: [],
        likes: [],
        mode: ''
    }

    blog = {
        title: "",
        createdTime: "",
        createdBy: "",
        description: "",
        icon: "",
        content: "",
        key: '',
        index: '',
        likes: 0
    };
    setEditorRef = (editor) => this.editor = editor

    componentDidMount() {
        firebaseAuth.onAuthStateChanged(user => {
            this.setState({ user: user })
        })
        this.getBlog(0);
        document.documentElement.scrollTop = 0;
        this.interval = setInterval(() => this.getLikesFromLocal(), 1000);
    }

    getLikesFromLocal() {
        var likes = localStorage.getItem('tmpL');
        if (likes)
            likes = JSON.parse(likes);
        this.setState({ likes: likes || [] });
    }

    getBlog(i) {
        this.unsubscribe = this.blogRef.orderBy("createdTime", "desc").onSnapshot(snapshot => {
            if (snapshot.docs.length === 0) {
                this.setState({ loading: false });
            }
            i = 0;
            const blogs = [];
            this.setState({ lastVisible: snapshot.docs[snapshot.docs.length - 1], firstVisible: snapshot.docs[0] });
            snapshot.forEach((doc) => {
                i += 1;
                const { title, createdTime, createdBy, description, icon, content, likes } = doc.data();
                blogs.push({
                    index: i,
                    key: doc.id,
                    title,
                    createdTime,
                    createdBy,
                    description,
                    icon,
                    content,
                    likes
                });
                this.setState({ tmpBlogs: blogs, allBlogs: blogs, loading: false });
            });
            window.localStorage.setItem("tmp", JSON.stringify(this.state.allBlogs));
            let limitedBlog = this.state.allBlogs.slice(0, 3);
            let blogs2 = this.state.allBlogs.slice(0, 9);
            let calculatePage = Math.ceil(this.state.allBlogs.length / 9);
            this.setState({ limitedBlog: limitedBlog, blogs: blogs2, pageSize: Array(calculatePage).fill(0, 0, calculatePage) });
        });
        return i;
    }

    componentWillUnmount() {
        this.unsubscribe()
        clearInterval(this.interval);
    }

    changeCreateMode = res => {
        const { createMode } = this.state
        document.documentElement.scrollTop = 250;
        if (res.desc === 'edit') {
            this.setState({ mode: res.desc, createMode: !createMode, avatar: "", avatarURL: "" })
            this.blog = res.blog
        } else {
            this.blog = {
                title: "",
                createdTime: "",
                createdBy: "",
                description: "",
                icon: "",
                content: ""
            }
            this.setState({ mode: '', createMode: !createMode, avatar: "", avatarURL: "" })
        }
    }

    blogDelete = (res) => {
        var deleteAll = false
        if (this.state.blogs.length === 1) {
            deleteAll = true
        }
        this.blogRef.doc(res).delete().then(res => {
            if (deleteAll) {
                this.setState({ blogs: [] })
            }
            this.handleClick("Blog successfully deleted! ...")
            this.handleDialogClose()
        }).catch(err => {
            if (err) {
                this.handleClick(err)
                console.log(err)
            }
        })
    }

    onEdit = (res) => {
        this.changeCreateMode({ blog: res, desc: 'edit' })
    }

    handleClick = res => {
        this.setState({ open: true, message: res })
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false, dialogOpen: false })
    };

    handleDialogClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ deleteDialogOpen: false })
    };

    handleDialogOpen = (res) => {
        this.setState({ deleteDialogOpen: true, deletedKey: res })
    }

    handleChangeUsername = event => {
        this.setState({ username: event.target.value })
    };
    handleUploadStart = e => {
        const file = e.currentTarget.files[0]
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, avatar: file.name, dialogOpen: true })
            });
            reader.readAsDataURL(file);
        }
    };

    saveCrop = () => {
        if (this.editor) {
            this.editor.getImageScaledToCanvas().toBlob((blob) => {
                const imageKey = moment().unix()
                const uploadTask = firebaseStorage.ref(`/images/${imageKey}`).put(blob)
                uploadTask.on('state_changed',
                    (snapShot) => {

                    }, (err) => {
                        console.log(err)
                    }, () => {
                        firebaseStorage.ref('images').child(imageKey.toString()).getDownloadURL()
                            .then(url => {
                                this.setState({ avatarURL: url, dialogOpen: false })
                            })
                    })
            }, "image/png")
        }
    }

    updateCounters = (res) => {
        var total = 0
        if (res === 'add') {
            this.setState({ totalDoc: (this.state.totalDoc + 1) })
            total = this.state.totalDoc
            total += 1
        }
        else {
            this.setState({ totalDoc: (this.state.totalDoc - 1) })
            total = this.state.totalDoc
            total -= 1
        }
        if (total > 0) {
            total = Math.ceil((total / 5))
        }
        this.setState({ total: total })
    }

    errMatcher = () => {
        if (this.blog.title === "") {
            return this.setState({ errorMessage: "Please fill the title" })
        } else if (this.blog.description === "") {
            return this.setState({ errorMessage: "Please fill the description" })
        } else {
            return this.setState({ errorMessage: null })
        }
    }

    onSearch = (value) => {
        history.push(`/Blog/${value.key}:${value.title}`)
    }

    handleViewAll = () => {
        let isViewAll = !this.state.isViewAll
        this.setState({ isViewAll: isViewAll })
    }

    handlePage = (index) => {
        let nextBlogs = this.state.allBlogs.slice((index * 9), ((index + 1) * 9))
        this.setState({ currentPage: index, blogs: nextBlogs })

    }

    handleNextPage = () => {
        if ((this.state.currentPage + 1) !== this.state.pageSize.length) {
            let nextPage = this.state.currentPage + 1
            let nextBlogs = this.state.allBlogs.slice((nextPage * 9), ((nextPage + 1) * 9))
            this.setState({ currentPage: nextPage, blogs: nextBlogs })
        }
    }

    handlePreviousPage = () => {
        if (this.state.currentPage !== 0) {
            let nextPage = this.state.currentPage - 1
            let nextBlogs = this.state.allBlogs.slice((nextPage * 9), ((nextPage + 1) * 9))
            this.setState({ currentPage: nextPage, blogs: nextBlogs })
        }
    }

    onShared = (blog) => {
        this.handleClick("Link Copied to Clipboard")
        navigator.clipboard.writeText(`${window.location.origin}/Blog/${blog.key}:${blog.title.replace(/\s+/g, '-')}`)
    }

    onLike = async (blog) => {
        if (this.state.likes.length > 0) {
            const selectedBlog = this.state.likes.filter(x => x.key === blog.key)[0]
            if (selectedBlog) {
                if (selectedBlog.isLike === true)
                    return
            }
        }
        const statsDoc = firestore.doc(`blog/${blog.key}`);
        const countDoc = {};
        countDoc["likes"] = fireCount.increment(1);
        await statsDoc.set(countDoc, { merge: true });
        this.state.likes.push({ key: blog.key, isLike: true })
        localStorage.setItem("tmpL", JSON.stringify(this.state.likes))
        this.forceUpdate()
    }


    render() {
        const { t } = this.props;
        return (
            <Grid container className={"blog-container"}>
                <Helmet>
                    <title>Octomate - {t(`BLOG_PAGE.Blogs`)}</title>
                    <meta name="description" content={t(`BLOG_PAGE.Octomate Blogs`)} />
                </Helmet>
                <Grid container className={'blog-text'} alignItems="center" justify="center">
                    <h1>{t(`BLOG_PAGE.Blog`)}</h1>
                </Grid>
                <Switch>
                    <Route exact path="/Blog">
                        <Grid container alignItems="center" justify="center">
                            <Search t={t} blogs={this.state.blogs} onSearch={this.onSearch} />
                            <img src={searchIcon} alt="search-icon" className={'search-icon-blog'} />
                        </Grid>
                        {
                            this.state.blogs.length > 0 && !this.state.createMode ? <div className={"blog-first-contents"}>
                                <div className={"blog-first-content"}>
                                    <div className={"blog-first-text"}>
                                        <h4>{this.state.blogs[0].title}</h4>
                                        <span className='blog-first-text-span'>{t(`BLOG_PAGE.By`)} {this.state.blogs[0].createdBy} {moment(this.state.blogs[0].createdTime).format('MMM DD, YYYY')}</span>
                                        <p>{this.state.blogs[0].description}</p>
                                        <div className='d-flex justify-space-between align-items-center'>
                                            <a className={'font-base blog-first-view-details'} href={`/Blog/${this.state.blogs[0].key}:${this.state.blogs[0].title.replace(/\s+/g, '-')}`}>{t(`BLOG_PAGE.View Details`)}</a>
                                            <div>
                                                {this.state.likes?.filter(x => x.key === this.state.blogs[0].key)[0]?.isLike ?
                                                    <IconButton aria-label="add to Likes">
                                                        <Badge className='blog-badge' color="primary" badgeContent={this.state.blogs[0].likes}>
                                                            <ThumbUpAltIcon style={{ color: '#5cb8b2' }} />
                                                        </Badge>
                                                    </IconButton>
                                                    :
                                                    <IconButton onClick={() => this.onLike(this.state.blogs[0])} aria-label="add to Likes">
                                                        <Badge className='blog-badge' color="primary" badgeContent={this.state.blogs[0].likes}>
                                                            <ThumbUpAltOutlinedIcon />
                                                        </Badge>
                                                    </IconButton>
                                                }
                                                <IconButton onClick={() => this.onShared(this.state.blogs[0])} aria-label="Share">
                                                    <ShareIcon style={{ color: '#5cb8b2' }} />
                                                </IconButton>
                                            </div>
                                        </div>

                                    </div>
                                    <img className={"blog-first-image"} src={this.state.blogs[0].icon === '' ? Bloggy : this.state.blogs[0].icon} alt="blog-img" />
                                </div>
                                <div className={'blog-first-sidebars'}>
                                    <div className={"blog-first-sidebar"}>
                                        <List component="div"
                                            subheader={
                                                <ListSubheader component="div" id="nested-list-subheader">
                                                    {t(`BLOG_PAGE.Categories`)}
                                                </ListSubheader>
                                            }
                                        >
                                            {this.state.blogs.length > 0 ?
                                                (
                                                    this.state.isViewAll ?
                                                        this.state.blogs.map((blog, index) =>
                                                            <ListItemLink key={index} disableRipple href={`/Blog/${blog.key}:${blog.title.replace(/\s+/g, '-')}`}>
                                                                <ListItemText primary={`${index + 1}. ${blog.title}`} />
                                                            </ListItemLink >
                                                        )
                                                        :
                                                        this.state.limitedBlog.map((blog, index) =>
                                                            <ListItemLink key={index} disableRipple href={`/Blog/${blog.key}:${blog.title.replace(/\s+/g, '-')}`}>
                                                                <ListItemText primary={`${index + 1}. ${blog.title}`} />
                                                            </ListItemLink >
                                                        )

                                                ) : <div>{t(`BLOG_PAGE.No blog at the moment`)}</div>}
                                            {
                                                this.state.blogs.length > 3 ?
                                                    <ListItemLink onClick={() => this.handleViewAll()} disableRipple href="#ViewAll" className={"mt-23"}>
                                                        <ListItemText className={'font-base flex-none'} primary={this.state.isViewAll ? 'Hide' : 'View All'} />
                                                        <img className={`blog-dropdown-icon ${this.state.isViewAll ? 'rotatez-180' : ''}`} src={Dropdown} alt="dropdown-icon" />
                                                    </ListItemLink > : ""
                                            }
                                        </List>
                                    </div>
                                    {
                                        !this.state.isViewAll ?
                                            <div className={"blog-contactus"}>
                                                <a href="/contactus">
                                                    <button className={"blog-btn-contactus"}>
                                                        <span>{t(`BLOG_PAGE.Contact Us`)} </span>
                                                        <img src={arrowRight} alt="arrow-icon" />
                                                    </button>
                                                </a>
                                            </div> : null
                                    }
                                </div>
                            </div> : ""
                        }
                        {this.state.user && !this.state.createMode ?
                            <AddMore t={t} onClick={this.changeCreateMode} /> : null
                        }
                        {
                            !this.state.createMode ?
                                <Grid container className={"blog-card-contents"} spacing={0}>
                                    {this.state.blogs.length > 0 ? this.state.blogs.map((blog, index) =>
                                        <Grid key={index} item lg={"auto"} md={6} sm={6} xs={12} className={`blog-card-content ${index === 2 || index === 5 || index === 8 ? 'mr-0' : 'mr-51'}`}>
                                            <Card className={`box-shadow blog-card`}>
                                                <div>
                                                    <CardMedia
                                                        className={'blog-media'}
                                                        image={blog.icon === "" ? Bloggy : blog.icon}
                                                        title={blog.title}
                                                    />
                                                    <CardContent className={'p-custom p-0'}>
                                                        <Typography variant="h5" component="h2" className={"blog-card-title mb-16"}>
                                                            {blog.title}
                                                        </Typography>
                                                        <Typography className={"blog-card-date"} variant="subtitle2" component="small">
                                                            {t(`BLOG_PAGE.By`)} {blog.createdBy}, {moment(blog.createdTime).format('MMM DD, YYYY')}
                                                        </Typography>
                                                        <Typography className={"blog-card-desc"} variant="body2" color="textSecondary" component="p">
                                                            {blog.description}
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions className={"blog-card-actions"}>
                                                        <Link to={`/Blog/${blog.key}:${blog.title.replace(/\s+/g, '-')}`} className={"font-base blog-card-view-details"}>{t(`BLOG_PAGE.View Details`)}</Link>
                                                        <div>
                                                            {this.state.likes?.filter(x => x.key === blog.key)[0]?.isLike ?
                                                                <IconButton aria-label="Liked">
                                                                    <Badge className='blog-badge' color="primary" badgeContent={blog.likes}>
                                                                        <ThumbUpAltIcon style={{ color: '#5cb8b2' }} />
                                                                    </Badge>
                                                                </IconButton> :
                                                                <IconButton onClick={() => this.onLike(blog)} aria-label="add to Likes">
                                                                    <Badge className='blog-badge' color="primary" badgeContent={blog.likes}>
                                                                        <ThumbUpAltOutlinedIcon />
                                                                    </Badge>
                                                                </IconButton>
                                                            }
                                                            <IconButton onClick={() => this.onShared(blog)} aria-label="Share">
                                                                <ShareIcon style={{ color: '#5cb8b2' }} />
                                                            </IconButton>
                                                            {this.state.user ?
                                                                <span>
                                                                    <IconButton onClick={() => this.onEdit(blog)} color="primary" aria-label="edit">
                                                                        <CreateIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => this.handleDialogOpen(blog.key)} color="secondary" aria-label="delete">
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </span> : null}
                                                        </div>
                                                    </CardActions>
                                                </div>

                                            </Card>
                                        </Grid>
                                    ) : (this.state.loading ? null : <div className={'no-blogs'}><h3 style={{ color: 'gray' }}>{t(`BLOG_PAGE.No blog at the moment, please check back again later, thank you!`)} </h3></div>)}
                                </Grid> :
                                <CardCreation
                                    t={t}
                                    uploadState={this.state}
                                    user={this.state.user}
                                    avatarURL={this.state.avatarURL}
                                    errorMessage={this.state.errorMessage}
                                    errMatcher={this.errMatcher}
                                    blogs={this.blog}
                                    onCancel={this.changeCreateMode}
                                    snackbarOpen={this.handleClick}
                                    handleChangeUsername={this.handleChangeUsername}
                                    handleUploadStart={this.handleUploadStart}
                                    updateCounters={this.updateCounters}
                                />
                        }
                        {!this.state.createMode && this.state.blogs.length > 0 ?
                            <Grid container justify="center" alignItems="center" direction={"row"} className={"mb-150 blog-pagination"}>
                                <Grid item onClick={() => this.handlePreviousPage()} >
                                    <button className={"blog-previous-btn mr-60"}>{t(`BLOG_PAGE.Previous`)}</button>
                                </Grid>
                                {this.state.pageSize.map((z, index) =>
                                    <Grid onClick={() => this.handlePage(index)} key={index} item className={"d-flex blog-number-btn"}>
                                        <div className={`${this.state.currentPage === index ? 'blog-number-active' : ''}`}>{index + 1}</div>
                                    </Grid>
                                )}
                                <Grid item onClick={() => this.handleNextPage()} >
                                    <button className={"blog-next-btn ml-40"}>{t(`BLOG_PAGE.Next`)}</button>
                                </Grid>
                            </Grid>
                            : null}


                        <SimpleDialog
                            uploadState={this.state}
                            onSaveCrop={this.saveCrop}
                            open={this.state.dialogOpen}
                            onClose={this.handleClose}
                            setEditorRef={this.setEditorRef} />
                        <Dialog
                            open={this.state.deleteDialogOpen}
                            onClose={this.dialogClose}
                        >
                            <DialogTitle>
                                Delete Blog
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete this blog?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={() => this.handleDialogClose()} color="primary">
                                    No
                                </Button>
                                <Button onClick={() => this.blogDelete(this.state.deletedKey)} color="primary">
                                    Yes, I'm sure
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Route>

                    <Route path={`/Blog/:blogId`} render={props =>
                        <DetailedBlog {...props} />
                    } />
                </Switch>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    message={this.state.message}
                />
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </Grid >
        )
    }
}

export function AddMore(props) {
    const onAddmore = () => {
        props.onClick({ desc: "add" })
    }
    return (
        <div className={'blog-addmore-container'}>
            <OButton
                onClick={onAddmore}
                variant="contained"
                color="secondary"
                startIcon={<FaPlus />}
            >  {props.t(`BLOG_PAGE.Add Blogs`)}
            </OButton>
        </div>
    )
}

export function CardCreation(props) {
    const blogsRef = firestore.collection(`blog`);
    const onSubmit = async () => {
        props.blogs.createdTime = moment().valueOf()
        props.errMatcher()
        if (props.avatarURL !== "" && props.avatarURL !== null) {
            props.blogs.icon = props.avatarURL
        }
        if (props.uploadState.mode === 'edit') {
            debugger
            blogsRef.doc(props.blogs.key).update(props.blogs)
                .then(res => {
                    props.snackbarOpen("Blog Successfully Updated!....")
                    onCancel()
                })
                .catch(err => {
                    if (err) {
                        props.snackbarOpen(err)
                        console.log(err)
                    }
                });
        } else {
            if (props.blogs.title !== "" && props.blogs.description !== "") {
                props.blogs.likes = 0
                blogsRef.add(props.blogs)
                    .then(async res => {
                        debugger
                        const statsDoc = firestore.doc(`counters/blogs`);
                        const countDoc = {};
                        countDoc["count"] = fireCount.increment(1);
                        await statsDoc.set(countDoc, { merge: true });
                        props.updateCounters('add')
                        props.snackbarOpen("Blog Successfully Created!....")
                        onCancel()
                    })
                    .catch(err => {
                        if (err) {
                            props.snackbarOpen(err)
                            console.log(err)

                        }
                    })
            }
        }
    }

    const onCancel = () => {
        props.onCancel({ desc: "cancel" })
    }

    const handleChange = res => {
        if (res.field === "description") {
            props.blogs.description = res.value
        } else if (res.field === "Title") {
            props.blogs.title = res.value
        } else if (res.field === "author") {
            props.blogs.createdBy = res.value
        } else {
            props.blogs.content = res.value
        }
    }

    const handleChangeUsername = res => {
        props.handleChangeUsername(res)
    }
    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }


    return (
        <Grid item className={"help-content"}>
            <div className={'login-content'}>
                <form className="form-create-post">
                    <Form
                        t={props.t}
                        uploadState={props.uploadState}
                        error={props.errorMessage}
                        blogs={props.blogs}
                        onSubmit={onSubmit}
                        onHandle={handleChange}
                        handleChangeUsername={handleChangeUsername}
                        handleUploadStart={handleUploadStart}
                    />
                    <div className={"button-container"}>
                        <OButton className={'btn-cancel'} onClick={onCancel} variant="outlined" color="secondary">
                            {props.t(`BLOG_PAGE.Cancel`)}
                        </OButton>
                        <OButton className={'btn-try'} onClick={onSubmit} variant="outlined" color="primary">
                            {props.uploadState.mode === 'edit' ? 'Update' : 'Create'}
                        </OButton>
                    </div>

                </form>
            </div>

        </Grid>
    )
}

export function Search(props) {
    const classes = useStyles();
    return (
        <Autocomplete
            freeSolo
            id="search"
            classes={classes}
            onChange={(event, newValue) => {
                props.onSearch(newValue)
            }}
            className={"search-blog box-shadow-none"}
            options={props.blogs}
            disableClearable
            getOptionLabel={option => option.title}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        className={"search-blog"}
                        variant="outlined"
                        placeholder={props.t("BLOG_PAGE.Search blog")}
                    />
                );
            }}
        />
    )
}

export function Form(props) {
    const classes = useStyles();
    const onHandled = field => event => {
        if (field !== "content") {
            const value = event.currentTarget.value
            props.onHandle({ field, value })
        } else {
            props.blogs.content = event
        }
    }

    const handleChangeUsername = res => {
        props.handleChangeUsername(res)
    }
    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    return (
        <div className={`${classes.root}`} >
            <h3 className={'register-company'}>{props.uploadState.mode === 'edit' ? 'Blog' : 'Create Blog'}</h3>
            <Upload
                t={props.t}
                uploadState={props.uploadState}
                handleChangeUsername={handleChangeUsername}
                handleUploadStart={handleUploadStart}
            />
            <TextField style={{ width: '100%' }} required id="outlined-basic-title" onChange={onHandled("Title")} defaultValue={props.blogs.title} name="title" label="Title" type="text" variant="outlined" /><br></br><br></br>
            <TextField inputProps={{ maxLength: 200 }} style={{ width: '100%' }} required id="multiline-basic" onChange={onHandled("description")} defaultValue={props.blogs.description} multiline rowsMax={4} name="description" label="Description" type="text" variant="outlined" /><br></br><br></br>
            <TextField style={{ width: '100%' }} required id="outlined-basic-author" onChange={onHandled("author")} defaultValue={props.blogs.createdBy} name="author" label="Author" type="text" variant="outlined" /><br></br><br></br>
            <div>
                <EditorToolbar />
                <ReactQuill
                    theme="snow"
                    value={props.blogs.content}
                    onChange={onHandled("content")}
                    modules={modules}
                    formats={formats}
                />
                <small>{props.t(`BLOG_PAGE.Note! :Please only use image tool to upload images, image that exceed more than 10mb will not be uploaded`)}</small>
            </div>
            <span className={'error-message'}>{props.error}</span>
        </div>
    )
}

export function Upload(props) {

    const handleUploadStart = res => {
        props.handleUploadStart(res)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {props.uploadState.isUploading && <p>{props.t(`BLOG_PAGE.Progress`)}: {props.uploadState.progress}</p>}
            {props.uploadState.avatarURL && <img alt="icon" src={props.uploadState.avatarURL} />}
            <br />
            <CustomUploadButton
                accept="image/*"
                onChange={handleUploadStart}
                className={'icon-select'}
            >
                {props.t(`BLOG_PAGE.Select your Icon`)}
            </CustomUploadButton>
        </div >
    );

}

export function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default withTranslation()(Blog)